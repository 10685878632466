/*
 *################################################################################
 *Module Name                   	: Sample.tsx                  				 #
 *Purpose of the Module         	: To Show elements sample pages link		 #
 *Description of the Module    	    : To Show elements sample pages link  		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/09/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */

import React from "react";
//import ui frameworks components
import {Container,Grid, Item } from "semantic-ui-react";

//Logo import
import Widgtes from '../../assets/images/logos/Widgets.png';
import Components from '../../assets/images/logos/Components.png';
import Modules from '../../assets/images/logos/Modules.jpeg';

const Sample =()=>{
    return(
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign={"center"}>
                            <h2>Samples with code examples codes</h2>
                            <p>Components, Widgets and Common Modules.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4} divided>
                        <Grid.Column width={1}>

                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign={"center"}>
                                    <h4>Component Samples</h4>
                                    <p>Click on the header to navigate to the relevant page</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{padding:'10px'}}>
                                <Item.Group>
                                    <Item>
                                        <Item.Image size='tiny' src={Components} />
                                        <Item.Content>
                                            <Item.Header as='a' href={"/sampleButtons"}>Buttons</Item.Header>
                                            <Item.Meta>Button Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>

                                    <Item>
                                        <Item.Image size='tiny' src={Components} />
                                        <Item.Content>
                                            <Item.Header as='a' href={'/sampleOptions'}>CheckBoxes</Item.Header>
                                            <Item.Meta>CheckBox Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>

                                    <Item>
                                        <Item.Image size='tiny' src={Components} />
                                        <Item.Content>
                                            <Item.Header as='a' href={'/sampleDropDowns'}>DropDowns</Item.Header>
                                            <Item.Meta>DropDown Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>

                                    <Item>
                                        <Item.Image size='tiny' src={Components} />
                                        <Item.Content>
                                            <Item.Header as='a' href={'/sampleModals'}>Modals</Item.Header>
                                            <Item.Meta>Modal Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>

                                    <Item>
                                        <Item.Image size='tiny' src={Components} />
                                        <Item.Content>
                                            <Item.Header as='a' href={'/sampleTextBoxes'}>TextBoxes</Item.Header>
                                            <Item.Meta>TexBox Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Image size='tiny' src={Components} />
                                        <Item.Content>
                                            <Item.Header as='a' href={'/sampleToasts'}>Toasts</Item.Header>
                                            <Item.Meta>Toast Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign={"center"}>
                                    <h4>Widgets Samples</h4>
                                    <p>Click on the header to navigate to the relevant page</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{padding:'10px'}}>
                                <Item.Group>
                                    <Item>
                                        <Item.Image size='tiny' src={Widgtes} />
                                        <Item.Content>
                                            <Item.Header as='a' href={"/sampleNameWithText"}>Name With Text</Item.Header>
                                            <Item.Meta>NameWithText Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Image size='tiny' src={Widgtes} />
                                        <Item.Content>
                                            <Item.Header as='a' href={"/sampleNameWithDropDown"}>Name With Dropdown</Item.Header>
                                            <Item.Meta>NameWithDropDown Elements and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign={"center"}>
                                    <h4>Common Modules Samples</h4>
                                    <p>Click on the header to navigate to the relevant page</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{padding:'10px'}}>
                                <Item.Group>
                                    <Item>
                                        <Item.Image size='tiny' src={Modules} />
                                        <Item.Content>
                                            <Item.Header as='a' href={"/sampleSupportModule"}>SupportModules</Item.Header>
                                            <Item.Meta>Support Modules and how to use it</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default Sample