import {getSessionCookie} from "../../../framework/common/SessionHandler";
import React, {useEffect} from "react";
import Header from "../../../framework/layouts/Header";
import WelcomePage from "../../admin/welcome/WelcomePage";
import Footer from "../../../framework/layouts/Footer";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";
import AdminNotification from "../../admin/welcome/AdminNotification";
import AdminMessage from "../../admin/welcome/AdminMessage";
import GeneralMsg from "../../admin/welcome/GeneralMessage";

const NotFound = () => {

    return (
        <>
            <Header>
              <Container  fluid={true} style={{height: '80vh', marginTop: '14px', marginBottom: '20px'}}>
                  <InnerTitleBar
                      title={"Not found"}
                      usreName={""}
                      userRole={""}
                      lastLogin={""}
                      status={""}
                  />
                  <Grid id={'thank-page'}  style={{marginBottom: '40px', marginTop: '50px'}}>
                      <Grid.Row>
                          <Grid.Column width={2}>

                          </Grid.Column>
                          <Grid.Column width={12}>

                          </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={3}>
                          <Grid.Column >

                          </Grid.Column>
                          <Grid.Column >
                            <div style={{fontSize:'20rem', color:'gray'}}>404</div>
                          </Grid.Column>
                          <Grid.Column >

                          </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={3}>
                          <Grid.Column width={2}>

                          </Grid.Column>
                          <Grid.Column width={12}>
                          </Grid.Column>
                          <Grid.Column width={2}>

                          </Grid.Column>
                      </Grid.Row>
                  </Grid>
              </Container>
            </Header>
            <Footer/>
        </>

    );
}

export default NotFound;