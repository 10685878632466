import React, {useEffect, useState} from "react";
import {Grid, List, Menu, Segment} from "semantic-ui-react";
import TabPane from "../../../framework/widgets/Tabs/TabPane";
import TabWithIcon from "../../../framework/widgets/Tabs/TabWithIcon";
import {Imenu} from "../../../framework/types/TabInterface";
import {Label} from "fomantic-ui-react";
import MSGPanel from "../../../framework/widgets/Tabs/MSGPanel";
import {IMSGPanel, IMSGPanelLine} from "../../../framework/types/IMSGPanel";
import {
    IAdminNotifications,
    IGeneralNotifications,
    ISystemNotifications
} from "../../../models/apiResponce/Notification/GetNotifications";
import {
    DeleteSystemNotifications,
    GetAdminNotifications,
    GetGeneralNotifications,
    GetSystemNotifications
} from "./DashboardController";
import {getSessionCookie} from "../../../framework/common/SessionHandler";

const MessageCenter = () => {
    const panes = [
        {
            menuItem: (
                <Menu.Item key='messages'  style={{border:'solid', borderWidth:'0.01rem', borderColor:'#F2F2F2', /*position:'unset'*/}}>
                    Register Notification
                </Menu.Item>
            ),
            render: () => <TabPane attached={false} body={() => <RegisterMsg/>}/>,
        },
        {
            menuItem: (
                <Menu.Item key='messages' style={{border:'solid', borderWidth:'0.01rem', borderColor:'#F2F2F2', /*position:'unset'*/}}>
                    Admin Notification
                </Menu.Item>
            ),
            render: () => <TabPane attached={false} body={() => <AdminMsg/>}/>,
        },
        {
            menuItem: (
                <Menu.Item key='messages' style={{border:'solid', borderWidth:'0.01rem', borderColor:'#F2F2F2', /*position:'unset'*/}} >
                    General Notification
                </Menu.Item>
            ),
            render: () => <TabPane attached={false} body={() => <GeneralMsg/>}/>,
        },
    ]
    const menu: Imenu = {
        attached: false,
        inverted: false,
        pointing: true,
        tabular: false,
        style:{
            display: "flex",
            justifyContent: "center",
            border:'none',
            boxShadow:'none'
        },
        className: "wrapped mobile-width"
    }
    return (
        <React.Fragment>
            <Grid style={{marginTop: '00px', textAlign: 'left'}}>
                <Grid.Row>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <Segment>
                            <h3 style={{textAlign:'center'}} className='color-foreground'>Message Center</h3>
                            <TabWithIcon panes={panes} menu={menu}/>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )
}

export default MessageCenter

const RegisterMsg = () => {
    const session = getSessionCookie();
    const [lines,SetLines] = useState<IMSGPanelLine[]>([])
    const DeleteNotification = (id:number) => {
        const deleteMsg = DeleteSystemNotifications(session.auditorId,id)
        Promise.resolve(deleteMsg).then((value)=>{
            if(value == true){
                loadMsg();
            }
        })
    }

    useEffect(()=>{
        loadMsg();
    },[])

    const loadMsg = () => {
        const SystemNotify = GetSystemNotifications(session.auditorId)
        Promise.resolve(SystemNotify).then((value)=>{
            if(value){
                //eslint-disable-next-line
                let ArrNotify:IMSGPanelLine[] = []
                value.map((r)=>{
                    ArrNotify.push(SetValue(r))
                })
                SetLines(ArrNotify)
            }
        })
    }
    const SetValue = (msg:ISystemNotifications) =>{
        return {
            Title:msg.createDate,
            Description:msg.description,
            Delete:()=>{DeleteNotification(msg.id)}
        }
    }
    return (
        <MSGPanel
            Lines={lines}
            Icon='registered'
        />
    )
}

const AdminMsg = () => {
    const session = getSessionCookie();
    const [lines,SetLines] = useState<IMSGPanelLine[]>([])
    useEffect(()=>{
        const SystemNotify = GetAdminNotifications(session.auditorId)
        Promise.resolve(SystemNotify).then((value)=>{
            if(value){
                //eslint-disable-next-line
                let ArrNotify:IMSGPanelLine[] = []
                value.map((r)=>{
                    ArrNotify.push(SetValue(r))
                })
                SetLines(ArrNotify)
            }
        })
    },[])
    const SetValue = (msg:IAdminNotifications) =>{
        return {
            Title:msg.createDate,
            Description:msg.logDescription,
        }
    }

    return (
        <MSGPanel
            Lines={lines}
            Icon='adn'
        />
    )
}

const GeneralMsg = () => {
    const [lines,SetLines] = useState<IMSGPanelLine[]>([])
    useEffect(()=>{
        const SystemNotify = GetGeneralNotifications()
        Promise.resolve(SystemNotify).then((value)=>{
            if(value){
                //eslint-disable-next-line
                let ArrNotify:IMSGPanelLine[] = []
                value.map((r)=>{
                    ArrNotify.push(SetValue(r))
                })
                SetLines(ArrNotify)
            }
        })
    },[])
    const SetValue = (msg:IGeneralNotifications) =>{
        return {
            Title:msg.lastupdate,
            Description:msg.notification
        }
    }
    return (
        <MSGPanel
            Lines={lines}
            Icon='newspaper'
        />
    )
}