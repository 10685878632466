import React from "react";
import Position from "../../types/Position";
import {Grid} from "semantic-ui-react";
import DropDownProps from "../../types/DropDownProps";
import Dropdowns from "../../components/DropDowns";

interface NameWithDropDownProps extends DropDownProps{
    labelText: string;
    labelPosition: Position.Top | Position.Left;
    required?: boolean;
}
const NameWithDropDown = (props:NameWithDropDownProps) => {
    if(props.labelPosition == Position.Top){
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign={"left"}>
                        <span style={{display:'inline-flex'}}>{props.labelText}<p style={{color:'red'}}>{props.required ? '*':''}</p></span>
                        <Dropdowns
                            id={props.id}
                            options={props.options}
                            placeholder={props.placeholder}
                            fluid={true}
                            selection={props.selection}
                            clearable={props.clearable}
                            multiple={props.multiple}
                            search={props.search}
                            value={props.value}
                            onChange={props.onChange}
                            defaultValue={props.defaultValue}
                            disabled={props.disabled}
                            onLostFocus={props.onLostFocus}
                            allowAdditions={props.allowAdditions}
                            onAddItem={props.onAddItem}
                            onerror={props.onerror}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }else{
        return (
            <Grid>
                <Grid.Row columns={2} verticalAlign={'middle'}>
                    <Grid.Column width={4} tablet={8} mobile={16} widescreen={4} computer={4} largeScreen={4} className={'dropdown-left-label'}>
                        <span style={{display:'inline-flex', justifyContent:'start'}}>{props.labelText}<p style={{color:'red'}}>{props.required ? '*':''}</p></span>
                    </Grid.Column>
                    <Grid.Column width={12} computer={12} tablet={8} mobile={16} widescreen={12} largeScreen={12}>
                        <Dropdowns
                            id={props.id}
                            options={props.options}
                            placeholder={props.placeholder}
                            fluid={props.fluid}
                            selection={props.selection}
                            clearable={props.clearable}
                            multiple={props.multiple}
                            search={props.search}
                            value={props.value}
                            onChange={props.onChange}
                            onLostFocus={props.onLostFocus}
                            allowAdditions={props.allowAdditions}
                            onAddItem={props.onAddItem}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

}

export default NameWithDropDown