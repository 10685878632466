import React from "react";

const CarouselItem = ({children,width}:any) => {
    return (
        <div className='carousel-item' style={{width:width}}>
            {children}
        </div>
    );
}

export default CarouselItem