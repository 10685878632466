const userRoleSelector = (roleId: string) => {
    switch (roleId) {
        case "1":
            return "Admin";
        case "2":
            return "Assessor";
        case "3":
            return "Auditor";
        case "4":
            return "SuperAdmin";
        case "5":
            return "AssessorChair";
    }
}

export default userRoleSelector