/*
 *################################################################################
 *Module Name                   	: APICall.ts                				 #
 *Purpose of the Module         	: To perform an Api call         			 #
 *Description of the Module    	    : To perform and fetch/post data to endpoint #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/23/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */


//Import ApiCallProps
import ApiCallProps from "../types/apiCallProps";



const ApiCall= async(props:ApiCallProps)=>{
//eslint-disable-next-line
    let  data:any={};
    const requestOptions:RequestInit  = {
        method: props.method,
        credentials: props.credentials ? props.credentials :"same-origin",
        //mode: "no-cors",
        body: props.data,
        redirect: 'follow',
        headers: props.headers,
    };

    //console.log("requestOptions",props.data);

    await fetch(props.url, requestOptions)
        .then(response => response.json())
        .then(result => {
            data = result;
        })
        .catch(error => {console.log('error', error); data = error;});
    return data;
}





export default ApiCall;