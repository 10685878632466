import React, {useEffect} from "react";
import TextBoxes from "./index";
import DefaultTextBoxProps from "../../types/DefaultTextBoxProps";
import {telephoneValidator} from "../../validators/textFeildValidators";

const TelephoneInputBox = (props:DefaultTextBoxProps)=>{
    const [phone, setPhone] = React.useState<string>('');
    useEffect(()=>{
        //console.log('EmailInputBox',props.onerror);
        setPhone(props.onerror? props.onerror : '');
    },[props.onerror]);
    const validator = ()=>{
        const enterValue = document.getElementById(props.id) as HTMLInputElement;
        if(enterValue?.value.length > 0 && !telephoneValidator(enterValue?.value)){
            setPhone('Please enter a valid phone number');
        }else{
            setPhone('');
        }
    }

    useEffect(() => {
        const element = document.getElementById(props.id) as HTMLInputElement;
        if(element){
            if(phone.length > 0){
                element.style.borderColor = '#CD123D'
            }else{
                element.style.borderColor = '#e0e0e0'
            }
        }
    }, [phone])

    return(
        <>
            <TextBoxes
                id={props.id}
                type={'phone'}
                placeholder={props.placeholder}
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                onChange={props.onChange}
                onClick={props.onClick}
                onLostFocus={()=>{
                    validator();
                    props.onLostFocus;
                }}
                onKeydown={props.onKeydown}
                value={props.value}
                fluid={props.fluid}
            />
            <span className='email-validation'>{phone}</span>
        </>

    )
}

export default TelephoneInputBox