import React, {useState} from "react";
import NameWithText from "../../framework/widgets/NameWithText";
import {Container, Grid} from "semantic-ui-react";
import Position from "../../framework/types/Position";
import TextBoxes from "../../framework/components/TextBoxes";
import {calculateStorage, FileUploadValidator, jwtValidator, padTo2Digits} from "../../framework/common/SupportMobules";
import FileUpload from "../../framework/components/FileUpload";
import FileProps from "../../framework/types/FileProps";
import {documentValidation, imageValidation} from "../../framework/validators/fileValidator";
import {ToastContainer} from "react-toastify";


const SampleSupportModule =() => {
    const [number, setNumber] = useState('')
    const [numberVal, setNumberVal] = useState('')
    const [token, setToken] = useState('')
    const [tokenValid, setTokenValid] = useState('')
    const [file, setFile] = useState<FileProps>({pictureAsFile: {} as File, picturePreview: ''})
    return (
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>
                        <ToastContainer
                            hideProgressBar={true}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>Support Modules with Sample codes</h3>
                            <p>before use this functions of the module need to import the relevant function from the
                                module to the file.</p>
                            <code>{"import NameWithText from \"../../framework/widgets/NameWithText\";"}</code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <TextBoxes
                                id={'txtName'}
                                type={'number'}
                                placeholder={'Enter name ...'}
                                value={numberVal}
                                onChange={(e: any) => {
                                    setNumberVal(e.target.value)
                                    setNumber(padTo2Digits(e.target.value))
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is Two Digit Number - {number}</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"setNumber(padTo2Digits(e.target.value))"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <TextBoxes
                                id={'txtName'}
                                type={'text'}
                                placeholder={'Enter name ...'}
                                value={token}
                                onChange={async(e: any) => {
                                    setToken(e.target.value)
                                    setTokenValid(await jwtValidator(token) ? 'Valid' : 'Invalid')
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This token is - {tokenValid}</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"setTokenValid(await jwtValidator(token) ? 'Valid' : 'Invalid')"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <FileUpload
                                onChange={(e: any) => {
                                    if (documentValidation(e)){
                                        setFile(FileUploadValidator(e));
                                    }
                                }}
                                color={'blue'} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>File name - {file.pictureAsFile.name} </p>
                            <p>File size - {calculateStorage(file.pictureAsFile.size)} </p>
                            <p>File type - {file.pictureAsFile.type} </p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"setFile(FileUploadValidator(e))"}
                            </code>
                            <br/>
                            <code>
                                {"calculateStorage(file.pictureAsFile.size)"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleSupportModule