/*
 *################################################################################
 *Module Name                   	: SessionContext.ts            				 #
 *Purpose of the Module         	: To Hold UserSession          			     #
 *Description of the Module    	    : This element  act like button       		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/04/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
import React from "react";
import {getSessionCookie} from "../../framework/common/SessionHandler";

export const SessionContext = React.createContext(getSessionCookie());