/*
*#################################################################################
*Module Name                   	    : NameWithRadioButton.tsx      				 #
*Purpose of the Module         	    : To Show CheckBox element with text		 #
*Description of the Module    	    : This element  act like CheckBox     		 #
*Initial Author               	    : T A C Priyashad                            #
*Date                          	    : 11/30/2022                   		         #
*Copyright © 2022 ITworx Consulting.                                   	    	 #
*#################################################################################
*/
import React from "react";


import CheckBoxProps from "../../types/CheckBoxProps";
import CheckBox from "../../components/CheckBox";

/**
 *
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * value = Is the value of the Checkbox
 *
 * name = group of the checkboxes widely use for radio buttons.
 *
 * label = If need to show text along with the checkbox. eg;- label={'Accept newsletters'}
 *
 * onChange = If you need to trigger event according to checkbox value change.
 *
 * onClick = If you need to trigger event according to mouse click on the element.
 *
 * onMouseDown = If you need to trigger event according to mouse button down.
 *
 * onMouseUp = If you need to trigger event according to mouse button up.
 */
const NameWithRadioButton = (props:CheckBoxProps) => {
    return (
        <CheckBox
             id={props.id}
             name={props.name}
             type={'radio'}
             value={props.value}
             onChange={props.onChange}
             onClick={props.onClick}
             onMouseDown={props.onMouseDown}
             onMouseUp={props.onMouseUp}
             label={props.label}
             disabled={props.disabled}
             style={props.style}
             className={props.className}
        />
    );
}

export default NameWithRadioButton