import React from "react";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";
import {Container, Grid, Icon} from "semantic-ui-react";

const WelcomePage = ({session}: any) => {

    const userRoleSelector = (roleId: string) => {
        switch (roleId) {
            case "1":
                return "Admin";
            case "2":
                return "Assessor";
            case "3":
                return "Auditor";
            case "4":
                return "SuperAdmin";
            case "5":
                return "AssessorChair";
        }
    }
    return (
        <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Welcome to the Register !"}
                usreName={''}
                userRole={""}
                lastLogin={''}
                status={''}
            />
            <Grid id={'thank-page'}  style={{marginBottom: '40px', marginTop: '-100px'}}>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <h1></h1>
                        <p style={{fontSize: '18px'}}>Congratulations, your application to become a recognised road
                            safety auditor has been successfully received and will be reviewed for assessment.</p>
                        <p style={{fontSize: '18px'}}>To be eligible for auditor accreditation, ensure that you meet the
                            eligibility criteria for the accreditation level that you are applying for. At the minimum,
                            your accreditation level will be based on the number and stage of audits you have
                            participated in, and the courses you have attended.</p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <ul>
                            <p style={{fontSize: '22px'}}><Icon disabled name='user circle' /> <a href='/profile'>Review your profile </a></p>
                            <p style={{fontSize: '22px'}}><Icon disabled name='question circle' /> <a href='/faqs'>Need more info? </a></p>
                        </ul>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <h1></h1>
                        <p style={{fontSize: '18px'}}>Your profile will not be publicly visible on the Register until your application has been reviewed and assessed by our panel of assessors. Once your application has met the required accreditation criteria, you will be granted an auditor accreditation level and your profile will be publicly visible on the Register. This process can take 1 – 4 weeks (depending on the information you provided).</p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default WelcomePage;

