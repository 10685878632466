import React, {useEffect, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import Position from "../../../framework/types/Position";
import NameWithDate from "../../../framework/widgets/NameWithText/NameWithDate";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import NameWithRadioButton from "../../../framework/widgets/NameWithCheckBox/NameWithRadioButton";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import NameWithMuliLine from "../../../framework/widgets/NameWithText/NameWithMuliLine";
import NameWithEmail from "../../../framework/widgets/NameWithText/NameWithEmail";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import {lstAuditParticipantsReq, RoadSafetyAuditProps} from "../../../pages/auditor/profile/DataModal";
import ControllButton from "../../../framework/widgets/ActionButtons/ControllButton";
import PromiseToast from "../../../framework/components/Toast/PromiseToast";
import {
    AddMetoAudit,
    AddNewAudittoDB,
    fetchAuditorsByName,
    fetchAudits,
    fetchSingleAudit
} from "../../../pages/auditor/profile/ProfileController";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import {Oval} from "react-loader-spinner";
import TableHeader from "../../../framework/types/TableHeader";
import moment from "moment/moment";
import {
    ValidationRequestProps,
    ValidationResponseProps,
    ValidationRuleProps
} from "../../../framework/types/ValidationProps";
import Validations from "../../../framework/common/Validations";
import ValidationRuleType from "../../../framework/types/ValidationRuleType";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import tags from "../../../models/common/tag";
import {toast} from "react-toastify";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";


interface Props {
    //eslint-disable-next-line
    states: any;
    //eslint-disable-next-line
    auditStages: any;
    //eslint-disable-next-line
    toggleModel: any;
    //eslint-disable-next-line
    auditId: any;
    //eslint-disable-next-line
    isEdit: any;
    //eslint-disable-next-line
    auditorId: any;
    //eslint-disable-next-line
    setAuditor: any;
}

const AddNewAuditView = ({states, auditStages, toggleModel, auditId, isEdit, auditorId}: Props) => {
    const [loading, setLoading] = useState(false);

    const [state, setState] = React.useState<RoadSafetyAuditProps>()
    const [rbtVal, setRbtVal] = useState('no')
    const [chkTfNSW, setChkTfNSW] = useState(false)
    const [addNewAudit, setAddNewAudit] = React.useState<boolean>(true);
    const [intLoad, setIntLoad] = useState(true);
    const [stateList, setStateList] = useState<DropDownOptions[]>([]);
    const [stages, setStages] = useState<DropDownOptions[]>([]);
    const [isEditCourse, setIsEditCourse] = React.useState<boolean>(false);

    const [auditDateError, setAuditDateError] = React.useState('')
    //eslint-disable-next-line
    const [auditTypeError, setAuditTypeError] = React.useState('')
    const [auditLocationError, setAuditLocationError] = React.useState('')
    const [titleError, setTitleError] = React.useState('')
    const [projectDescError, setProjectDescError] = React.useState('')
    const [purposeOfAuditError, setPurposeOfAuditError] = React.useState('')
    const [clientCompanyError, setClientCompanyError] = React.useState('')
    const [clientContactPersonError, setClientContactPersonError] = React.useState('')
    const [clientContactNumberError, setClientContactNumberError] = React.useState('')
    const [clientMobileNumberError, setClientMobileNumberError] = React.useState('')
    const [clientContactEmailError, setClientContactEmailError] = React.useState('')
    const [isAddMember, setIsAddmember] = React.useState(false)
    const [txtAddMember, setTxtAddMember] = React.useState('');


    useEffect(() => {
        setState({...state, auditorId: auditorId})
        if (auditId > 0) {
            //setAddNewAudit(false);
            setLoading(true);
            Promise.resolve(fetchSingleAudit(auditId)).then((res) => {
                setState({...res, finalSignoffDate: moment(res.finalSignoffDate).format("DD-MM-YYYY")});
                setRbtVal(res.isTfNSW ? 'yes' : 'no');
                setIntLoad(false)
                setLoading(false);
                setIsEditCourse(true)
                if (res?.lstAuditParticipantsReq != undefined && res?.lstAuditParticipantsReq?.length > 0) {
                    const objAddAuditors: object[] = []//rsaAddAuditorDet
                    res?.lstAuditParticipantsReq.map((r) => {
                        const row = {
                            auditorId: r.auditorId,
                            name: r.auditorFullName,
                            status: r.auditorId == 0 ? 'Unregistered Auditor' : 'Auditor',
                            isTeamLeader: r.isLeadAuditor,
                            isConfirmedByAuditor: r.isConfirmed
                        }
                        objAddAuditors.push(assignAddAuditortoAuditRaw(row, true));
                    })
                    setRSAAddAuditorDet(objAddAuditors);
                }
            });

        }
    }, [auditId]);

    useEffect(() => {
        Promise.resolve(states).then((value) => {
            setStateList(value)
        })
    }, [states])

    useEffect(() => {
        Promise.resolve(auditStages).then((value) => {
            setStages(value)
            setIsEditCourse(!isEdit)
        })
    }, [auditStages])

    const rsaAuditorHead: TableHeader[] = [
        {
            text: "Audit ID"
        },
        {
            text: "Name"
        },
        {
            text: "Location"
        },
        {
            text: "Is TfNSW"
        },
        {
            text: "Confirmed"
        },
        {
            text: " "
        }
    ];
    const rsaAddAuditorHead: TableHeader[] = [
        {
            text: "Auditor ID"
        },
        {
            text: "Name"
        },
        {
            text: "Auditor's Status"
        },
        {
            text: "Lead Auditor"
        },
        {
            text: "Confirmed"
        },
        {
            text: " "
        }
    ];
    const rsaAddAuditorListHead: TableHeader[] = [
        {
            text: "Auditor ID"
        },
        {
            text: "First Name"
        },
        {
            text: "Last Name"
        },
        {
            text: " "
        }
    ];
    //eslint-disable-next-line
    const rsaAuditDetRow: object[] = [
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "No Audit Found",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        }
    ];
    const session = getSessionCookie();

    const makeMeLeader = (id: string, name: string, isLeader: boolean) => {
        //eslint-disable-next-line
        const data = rsaAddAuditorDet
        const allData: object[] = []
        data.forEach(x => {
            if (Array.isArray(x)) {
                if (x[0].text == id && x[1].text == name) {
                    x[3].text = !x[3].text
                } else {
                    x[3].text = false
                }
                allData.push(x)
            }
        })
        setRSAAddAuditorDet(allData)

        const allStateData: lstAuditParticipantsReq[] = []
        const dataState = state?.lstAuditParticipantsReq ? state?.lstAuditParticipantsReq : []
        dataState.forEach((x) => {
            if (x.auditorId == parseInt(id) && x.auditorFullName == name) {
                x.isLeadAuditor = !x.isLeadAuditor
            } else {
                x.isLeadAuditor = false
            }
            allStateData.push(x)
        })
        setState({...state, lstAuditParticipantsReq: allStateData})
    }
    const assignAddAuditortoAuditRaw = (raw: any, edit: boolean) => {
        return [
            {
                tag: "text",
                text: raw.auditorId,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.name,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.status,
                icon: "",
                onclick: null
            },
            !edit ? {
                tag: "checkbox",
                text: raw.isTeamLeader,
                icon: "",
                onclick: () => {
                    makeMeLeader(raw.auditorId, raw.name, raw.isTeamLeader)
                }
            } : {
                tag: tags.icon,
                text: "",
                icon: raw.isTeamLeader == true ? "check" : "times",
                onclick: null
            },
            {
                tag: tags.icon,
                text: "",
                icon: raw.isConfirmedByAuditor == true ? "check" : "times",
                onclick: null
            }
        ]
    }
    const [rsaAuditDet, setRSADet] = useState<object[]>([])
    const [rsaAuditorDet, setRSAAuditorDet] = useState<object[]>([])
    const [rsaAddAuditorDet, setRSAAddAuditorDet] = useState<object[]>([assignAddAuditortoAuditRaw({
        auditorId: session.auditorId,
        name: session.userDisplayName,
        status: 'Auditor',
        isTeamLeader: true,
        isConfirmedByAuditor: false
    }, false)])
    //eslint-disable-next-line
    let failedResult: ValidationResponseProps[] = []


    const Validating = () => {
        setAuditDateError('')
        setAuditLocationError('')
        setTitleError('')
        setProjectDescError('')
        setPurposeOfAuditError('')
        setClientCompanyError('')
        setClientContactPersonError('')
        setClientContactNumberError('')
        setClientMobileNumberError('')
        setClientContactEmailError('')
        setAuditTypeError('')
        let result = true;
        let ValidateProps: ValidationRequestProps = {} as ValidationRequestProps;
        //course section
        if (state != undefined) {
            //Audit Date Validation
            if (state.finalSignoffDate != undefined) {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const date1 = moment(state.finalSignoffDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                rule = [{
                    Rule: `${new Date(date1) < new Date(Date.now())}`,
                    Type: ValidationRuleType.CUSTOM,
                }]
                ValidateProps = {
                    value: state.finalSignoffDate,
                    fieldType: 'date',
                    fieldName: 'Final Signoff Date',
                    required: true,
                    rules: rule,
                    fieldId: 'finalSignoffDate'
                } as ValidationRequestProps
                const finalSignoffDateVal: ValidationResponseProps = Validations(ValidateProps)
                if (!finalSignoffDateVal.Valid) {
                    result = false;
                    failedResult.push(finalSignoffDateVal)
                    setAuditDateError(finalSignoffDateVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Final Signoff Date is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setAuditDateError(res.Msg)
            }

            //Audit Location Validation
            if (state.locationOfAudit != undefined) {

                ValidateProps = {
                    value: state.locationOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Location',
                    required: true,
                    fieldId: 'locationOfAudit'
                } as ValidationRequestProps
                const locationOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!locationOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(locationOfAuditVal)
                    setAuditLocationError(locationOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Location is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setAuditLocationError(res.Msg)
            }

            //Audit Title Validation
            if (state.titleOfAudit != undefined) {

                ValidateProps = {
                    value: state.titleOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Title',
                    required: true,
                    fieldId: 'titleOfAudit'
                } as ValidationRequestProps
                const titleOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!titleOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(titleOfAuditVal)
                    setTitleError(titleOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Title is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setTitleError(res.Msg)
            }

            //Audit Desc Validation
            if (state.projectDescription != undefined) {

                ValidateProps = {
                    value: state.projectDescription,
                    fieldType: 'text',
                    fieldName: 'Audit Description',
                    required: true,
                    fieldId: 'projectDescription'
                } as ValidationRequestProps
                const projectDescriptionVal: ValidationResponseProps = Validations(ValidateProps)
                if (!projectDescriptionVal.Valid) {
                    result = false;
                    failedResult.push(projectDescriptionVal)
                    setProjectDescError(projectDescriptionVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Description is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setProjectDescError(res.Msg)
            }

            //Audit Purpose Validation
            if (state.purposeOfAudit != undefined) {

                ValidateProps = {
                    value: state.purposeOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Purpose',
                    required: true,
                    fieldId: 'purposeOfAudit'
                } as ValidationRequestProps
                const purposeOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!purposeOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(purposeOfAuditVal)
                    setPurposeOfAuditError(purposeOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Purpose is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setPurposeOfAuditError(res.Msg)
            }

            //Client Company Validation
            if (state.clientCompany != undefined) {

                ValidateProps = {
                    value: state.clientCompany,
                    fieldType: 'text',
                    fieldName: 'Client Company',
                    required: true,
                    fieldId: 'clientCompany'
                } as ValidationRequestProps
                const clientCompanyVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientCompanyVal.Valid) {
                    result = false;
                    failedResult.push(clientCompanyVal)
                    setClientCompanyError(clientCompanyVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Company is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientCompanyError(res.Msg)
            }

            //Client Contact Person Validation
            if (state.clientContactPerson != undefined) {

                ValidateProps = {
                    value: state.clientContactPerson,
                    fieldType: 'text',
                    fieldName: 'Client Contact Person',
                    required: true,
                    fieldId: 'clientContactPerson'
                } as ValidationRequestProps
                const clientContactPersonVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactPersonVal.Valid) {
                    result = false;
                    failedResult.push(clientContactPersonVal)
                    setClientContactPersonError(clientContactPersonVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Person is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactPersonError(res.Msg)
            }

            //Client Contact Tel. Validation
            if (state.clientContactNumber != undefined && state.clientContactNumber != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const regx = "^\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}$"
                rule = [{
                    Rule: regx,
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientContactNumber,
                    fieldType: 'phone',
                    fieldName: 'Client Contact Telephone',
                    required: true,
                    rules: rule,
                    fieldId: 'clientContactNumber'
                } as ValidationRequestProps
                const clientContactTelVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactTelVal.Valid) {
                    result = false;
                    failedResult.push(clientContactTelVal)
                    setClientContactNumberError(clientContactTelVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Telephone is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactNumberError(res.Msg)
            }

            //Client Contact Mobile Validation
            if (state.clientMobileNumber != undefined && state.clientMobileNumber != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const regx = "^\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}$"
                rule = [{
                    Rule: regx,
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientMobileNumber,
                    fieldType: 'phone',
                    fieldName: 'Client Mobile No',
                    required: true,
                    rules: rule,
                    fieldId: 'clientMobileNumber'
                } as ValidationRequestProps
                const clientContactMobileVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactMobileVal.Valid) {
                    result = false;
                    failedResult.push(clientContactMobileVal)
                    setClientMobileNumberError(clientContactMobileVal.Msg)
                }
            }

            //Client Contact Email Validation
            if (state.clientContactEmail != undefined && state.clientContactEmail != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                rule = [{
                    Rule: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientContactEmail,
                    fieldType: 'email',
                    fieldName: 'Client Contact Email',
                    required: true,
                    rules: rule,
                    fieldId: 'clientContactEmail'
                } as ValidationRequestProps
                const clientContactEmailVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactEmailVal.Valid) {
                    result = false;
                    failedResult.push(clientContactEmailVal)
                    setClientContactEmailError(clientContactEmailVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Email is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactEmailError(res.Msg)
            }

        } else {
            const res: ValidationResponseProps = {
                Valid: false,
                Msg: 'Please fill required fields in course details!'
            } as ValidationResponseProps
            failedResult.push(res)
            result = false;
            setAuditDateError('Course Date is Required !');
            setClientContactEmailError('Course is Required !');
        }

        return result
    }
    const Save = () => {
        if (!Validating()) {
            console.log(failedResult)
            //setErrorPop(true)
            return;
        }
        if (rbtVal === 'yes' && chkTfNSW == false) {
            ErrorToast("Please confirm is TfNSW !")
            return;
        }

        if (state !== undefined && addNewAudit) {
            const saveResult = AddNewAudittoDB(state)
            //PromiseToast('Pending...', 'Save Success !', 'Error', saveResult)
            toast.dismiss()
            const id = toast.loading('Pending..');
            Promise.resolve(saveResult).then((value) => {
                //console.log('Add Audit Save',saveResult)

                if (value.response != undefined && value.response.data.error) {
                    toast.dismiss(id)
                    //toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});

                } else {
                    toast.update(id, {render: "Successfully Added !", type: "success", isLoading: false});
                    setTimeout(() => {
                        toast.dismiss(id)
                    }, 1500)
                    toggleModel('save')
                }

            })
        } else if (state !== undefined) {
            toast.dismiss()
            toggleModel()
        }
    }

    //eslint-disable-next-line
    const assignRaw = (raw: any) => {
        return [
            {
                tag: "popup",
                text: raw.auditID,
                icon: "",
                onclick: raw.auditID,
                href: `/audits?id=${raw.auditID}`
            },
            {
                tag: "link",
                text: raw.name,
                icon: "",
                onclick: null,
                href: `/audits?id=${raw.auditID}`,
                width: "250px"
            },
            {
                tag: "text",
                text: raw.location,
                icon: "",
                onclick: null,
                width: "250px"
            },
            {
                tag: "text",
                text: raw.tfnsw,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.isPubliclyVisible,
                icon: "",
                onclick: null
            },
            session.status != 4 ? {
                tag: "button",
                text: "Add Me",
                icon: "",
                onclick: () => {
                    toast.dismiss()
                    const id = toast.loading('Pending..');
                    const addMe = AddMetoAudit(auditorId, raw.auditID)
                    Promise.resolve(addMe).then((value) => {
                        if (value.response != undefined && value.response.data.error) {
                            //toast.dismiss(id)
                            toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});
                            setTimeout(() => {
                                toast.dismiss(id)
                            }, 2500)
                        } else {
                            toast.update(id, {render: "Successfully Added !", type: "success", isLoading: false});
                            setTimeout(() => {
                                toast.dismiss(id)
                            }, 1500)
                            toggleModel('Addme')
                        }

                    })
                }
            } : ''
        ]
    }

    const assignAddAuditorRaw = (raw: any) => {
        return [
            {
                tag: "text",
                text: raw.auditorId,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.firstName,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.lastName,
                icon: "",
                onclick: null
            },
            {
                tag: "button",
                text: "Select",
                icon: "",
                onclick: () => {
                    const lastName  = raw.lastName? raw.lastName : "";
                    AddAuditorToAudit(raw.auditorId, `${raw.firstName} ${lastName}`, "Auditor")
                }
            }
        ]
    }


    const fetchAuditorsList = (auditorName: string) => {
        if (auditorName.length > 4) {
            const lstAuditors = fetchAuditorsByName(auditorName)
            Promise.resolve(lstAuditors).then((row) => {
                const objAuditors: object[] = [];
                if (row) {
                    console.log(row)
                    row.map((r: any) => {
                        objAuditors.push(assignAddAuditorRaw(r))
                        //[...rsaAuditDet, assignRaw(r)])
                    })
                    setRSAAuditorDet(objAuditors)
                } else {
                    setRSAAuditorDet([])
                }
            })
        }
    }

    const AddAuditorToAudit = (auditorId: number, auditorName: string, auditorStatus: string) => {
        const row = {
            auditorId: auditorId,
            name: auditorName,
            status: auditorStatus,
            isTeamLeader: false,
            isConfirmedByAuditor: false
        }

        //eslint-disable-next-line
        let objlstAuditParticipantsReq = state?.lstAuditParticipantsReq ? state?.lstAuditParticipantsReq : [{
            auditorId: state?.auditorId ? state.auditorId : session.auditorId,
            auditorFullName: session.userDisplayName,
            isLeadAuditor: true,
            isConfirmed: false
        }]
        const auditorAudit: lstAuditParticipantsReq = {
            auditorId: auditorId > 0 ? auditorId : 999999999,
            auditorFullName: auditorName,
            isLeadAuditor: false,
            isConfirmed: false
        }

        objlstAuditParticipantsReq.push(auditorAudit)
        setState({...state, lstAuditParticipantsReq: objlstAuditParticipantsReq})
        const objAddAuditors: object[] = rsaAddAuditorDet
        objAddAuditors.push(assignAddAuditortoAuditRaw(row, false));
        setRSAAddAuditorDet(objAddAuditors);
        setTxtAddMember('')
        setIsAddmember(!isAddMember)
        setRSAAuditorDet([])
    }

    const fetchAuditsFromDb = () => {
        //console.log("fetching audits" , state)
        if (state && state.finalSignoffDate && state.finalSignoffDate.length > 0 && state.stageOfAudit && state.stageOfAudit.length > 0 && state.stateAuditPerformed && state.stateAuditPerformed.length > 0) {
            setLoading(true)
            const audits = fetchAudits(moment(state.finalSignoffDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), parseInt(state.stageOfAudit), parseInt(state.stateAuditPerformed), auditorId)
            Promise.resolve(audits).then((value) => {
                console.log(value)
                setLoading(false)
                const objAudits: object[] = rsaAuditDet;
                if (value.length > 0) {
                    setAddNewAudit(false)
                    //eslint-disable-next-line
                    value.map((r: any) => {
                        objAudits.push(assignRaw(r))
                        //setRSADet(objAudits) //[...rsaAuditDet, assignRaw(r)])
                    })
                    console.log('objAudit', objAudits)
                    setRSADet(objAudits)
                } else if (session.status == 4) {
                    setRSADet([rsaAuditDetRow])
                    setAddNewAudit(false)
                    setIntLoad(false)
                } else {
                    setRSADet([])
                    setAddNewAudit(true)
                    setIntLoad(false)
                }
            })
        }

    }
    useEffect(() => {
        //eslint-disable-next-line
        //debugger;
        console.log(state?.finalSignoffDate)
        if (state?.auditId == undefined) {
            fetchAuditsFromDb()
        }

    }, [state?.finalSignoffDate, state?.stageOfAudit, state?.stateAuditPerformed])


    return (
        <React.Fragment>
            <Container style={{maxWidth:'100%'}}>
                <Grid>
                {loading ? (<>
                    <Grid.Row columns={3}>
                        <Grid.Column></Grid.Column>
                        <Grid.Column>
                            <Oval
                                height={80}
                                width={80}
                                color="#1559A3"
                                wrapperStyle={{margin: 'auto', display: 'flex', justifyContent: 'center'}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#464646"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                </>) : addNewAudit ?
                    (intLoad ? (null) : (<>
                        <Grid.Row columns={2}>
                            <Grid.Column width={8} mobile={16} computer={8} widescreen={8} tablet={8} largeScreen={8}>
                                <NameWithMuliLine
                                    labelText={'Project Description'}
                                    labelPosition={Position.Top}
                                    id={'txtProDesc'}
                                    type={'text'}
                                    required={true}
                                    placeholder={'Enter max 3000 characters'}
                                    value={state?.projectDescription}
                                    onError={projectDescError}
                                    //eslint-disable-next-line
                                    onChange={(e: any) => {
                                        e.preventDefault();
                                        setState({...state, projectDescription: e.target.value})
                                    }}
                                    disabled={isEditCourse}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} mobile={16} computer={8} widescreen={8} tablet={8} largeScreen={8}>
                                <NameWithMuliLine
                                    labelText={'Project of Scope'}
                                    labelPosition={Position.Top}
                                    id={'txtPurpose'}
                                    type={'text'}
                                    required={true}
                                    placeholder={'Enter max 3000 characters'}
                                    value={state?.purposeOfAudit}
                                    onError={purposeOfAuditError}
                                    //eslint-disable-next-line
                                    onChange={(e: any) => {
                                        e.preventDefault();
                                        setState({...state, purposeOfAudit: e.target.value})
                                    }}
                                    disabled={isEditCourse}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {<Grid.Row>
                            <Grid.Column width={16}>
                                <RSATable
                                    head={rsaAddAuditorHead}
                                    body={rsaAddAuditorDet}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                        <Grid.Row columns={2}>
                            <Grid.Column width={12}>
                                <>
                                    {isAddMember ? (<NameWithDefault
                                        labelText={'Auditor Name'}
                                        fluid={true}
                                        labelPosition={Position.Left}
                                        id={'txtAuditorName'}
                                        type={'text'}
                                        required={false}
                                        placeholder={'Enter Auditor Name'}
                                        value={txtAddMember}
                                        //onError={clientContactPersonError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setTxtAddMember(e.target.value)
                                            fetchAuditorsList(e.target.value)
                                        }}
                                        // disabled={isEditCourse}
                                    />) : null}
                                </>
                            </Grid.Column>
                            <Grid.Column width={4} className='button-flex'>
                                {rsaAuditorDet.length > 0 ? null : !isEditCourse ? (
                                    <OutLineButton id={'btnAddTeam'} className={"background-transaparent"}
                                                   style={{width: '150px'}} onClick={() => {
                                        isAddMember ? AddAuditorToAudit(0, txtAddMember, "Auditor") : null;
                                        setIsAddmember(!isAddMember)
                                    }} color={'blue'} text={isAddMember ? 'Add' : 'Add Member'}/>) : null}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {rsaAuditorDet.length > 0 ? (<Grid.Column width={16}>
                                <RSATable
                                    head={rsaAddAuditorListHead}
                                    body={rsaAuditorDet}
                                />
                            </Grid.Column>) : null}
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column width={11}></Grid.Column>
                            <Grid.Column width={5}>
                                {!isEditCourse && session.status != 4 ? (<ControllButton
                                    cancelOnclick={toggleModel}
                                    saveOnclick={Save}
                                />) : null}
                            </Grid.Column>
                        </Grid.Row>
                    </>)) : (
                        <>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <p style={{fontSize: '16px', fontWeight: 'bold'}}>Matching audit(s) found in the
                                        Register, you could add yourself using “Add me”:</p>
                                    <hr/>
                                    <RSATable
                                        head={rsaAuditorHead}
                                        body={rsaAuditDet}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <hr/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={13}>
                                    <p style={{fontSize: '16px', fontWeight: 'bold'}}>Otherwise, please continue adding
                                        the details of the new audit using “Add New Audit”.</p>
                                </Grid.Column>
                                <Grid.Column width={3} className='button-flex'>
                                    {session.status != 4 ? (<OutLineButton id={'btnAddNew'} style={{width: '100px'}}
                                                                           className={"background-transaparent"}
                                                                           onClick={() => {
                                                                               setAddNewAudit(true);
                                                                               setRSADet([]);
                                                                               setIntLoad(false)
                                                                           }} color={'blue'} text={'Add New'}/>) : null}
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                <Grid.Row>

                </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default AddNewAuditView;