import React, {useEffect, useState} from "react";
import {Container, Grid, Icon, SemanticICONS} from "semantic-ui-react";
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import Position from "../../../framework/types/Position";
import NameWithDate from "../../../framework/widgets/NameWithText/NameWithDate";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import NameWithEmail from "../../../framework/widgets/NameWithText/NameWithEmail";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import IconCircleOutlineButton from "../../../framework/components/Buttons/IconCircleOutlineButton";
import {RoadSafetyCoursesProps} from "./DataModal";
import ControllButton from "../../../framework/widgets/ActionButtons/ControllButton";
import {
    AddNewCoursetoDB,
    fetchCoursesWithProviders,
    fetchSingleCourse,
    fetchSingleCourseUsingMID
} from "./ProfileController";
import tag from "../../../models/common/tag";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import FileUploadingToServer from "../../../common/FileUploadingController";
import FileUplaodReq from "../../../models/common/fileUplaodReq";
import moment from "moment";
import {
    ValidationRequestProps,
    ValidationResponseProps,
    ValidationRuleProps
} from "../../../framework/types/ValidationProps";
import Validations from "../../../framework/common/Validations";
import ValidationRuleType from "../../../framework/types/ValidationRuleType";
import {toast} from "react-toastify";
import Modal from "../../../framework/components/Modals";
import FileUploadPop from "../../../framework/components/FileUpload/FileUploadPop";
import {fetchFiles} from "../../../framework/components/FileUpload/FileUploadController";
import Courses from "../../../models/apiResponce/Common/Courses";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


interface Props {
    provider: any;
    course: any;
    toggleModel: any;
    courseId: number;
    courseMId: number;
    setRSACourseDet?: any;
    rsaCourseDet?: any;
    setCourseId?: any;
    onEditClick?: any;
    providerEmail: any;
    certificateNumber: any;
    isEdit: boolean;
    auditorId: any;
    setAuditor?: any;
}

const AddNewCourse = ({
                          provider,
                          course,
                          toggleModel,
                          courseId,
                          courseMId,
                          providerEmail,
                          certificateNumber,
                          isEdit,
                          auditorId
                      }: Props) => {
    const [providers, setProviders] = React.useState<DropDownOptions[]>([]);
    const [courses, setCourses] = React.useState<DropDownOptions[]>([]);
    const [uploadFile, setUploadFile] = React.useState<any>();

    const [errorCourseDD, setErrorCourseDD] = React.useState('')
    const [courseNameError, setCourseNameError] = React.useState('')
    const [courseDateError, setCourseDateError] = React.useState('')
    const [courseLocationError, setCourseLocationError] = React.useState('')
    const [errorCoursePDD, setErrorCoursePDD] = React.useState('')
    const [errorCoursePtxt, setErrorCoursePtxt] = React.useState('')
    const [errorCoursePemail, setErrorCoursePemail] = React.useState('')

    const [state, setState] = React.useState<RoadSafetyCoursesProps>({courseId: 0})
    const [ddcourseId, setddCourseId] = React.useState('');
    const [addNewCourse, setAddNewCourse] = React.useState<boolean>(false);
    const [isEditCourse, setIsEditCourse] = React.useState<boolean>(false);
    const [isEnable, setEnable] = React.useState(true)
    const [FileIcon, setFileIcon] = useState<IconProp>('paperclip')
    const session = getSessionCookie();
    const encid = null //new URLSearchParams(location.search).get('get')

    const [open, setOpen] = useState(false);
    //eslint-disable-next-line
    let failedResult: ValidationResponseProps[] = []

    const assignSavedCourse = (courseId: any) => {

        return ([{
            tag: tag.text,
            text: state?.courseDate,
            icon: "",
            onclick: null
        },
            {
                tag: tag.text,
                text: state?.courseProvider,
                icon: "",
                onclick: null
            },
            {
                tag: tag.text,
                text: state?.courseName,
                icon: "",
                onclick: null
            },
            {
                tag: tag.text,
                text: "",
                icon: "",
                onclick: null
            },
            {
                tag: tag.icon,
                text: "",
                icon: "attach",
                onclick: () => {
                    alert("")
                }
            },
            {
                tag: tag.button,
                text: "Delete",
                icon: "",
                onclick: () => {
                    alert("")
                }
            }]);
    }

    //region Validation
    const Validating = () => {
        let result = true;
        let ValidateProps: ValidationRequestProps = {} as ValidationRequestProps;
        //course section
        if (state != undefined) {
            //Course Name Validation
            if (state.courseId != undefined && addNewCourse == false) {
                ValidateProps = {
                    value: state.courseId.toString(),
                    fieldType: 'dropdown',
                    fieldName: 'Course',
                    required: true,
                    fieldId: 'txtCourse'
                } as ValidationRequestProps
                const txtCourseVal: ValidationResponseProps = Validations(ValidateProps)
                if (!txtCourseVal.Valid) {
                    result = false;
                    failedResult.push(txtCourseVal)
                    setErrorCourseDD(txtCourseVal.Msg)
                }
            } else if (addNewCourse == false) {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Course is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                setErrorCourseDD(res.Msg)
                result = false;
            }

            //Course Name Validation
            if (state.courseName != undefined && addNewCourse == true) {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                rule = [{
                    Rule: '30',
                    Type: ValidationRuleType.MAXLENTH,
                }]
                ValidateProps = {
                    value: state.courseName,
                    fieldType: 'text',
                    fieldName: 'Course Name',
                    required: true,
                    rules: rule,
                    fieldId: 'courseName'
                } as ValidationRequestProps
                const courseNameVal: ValidationResponseProps = Validations(ValidateProps)
                if (!courseNameVal.Valid) {
                    result = false;
                    failedResult.push(courseNameVal)
                    setCourseNameError(courseNameVal.Msg)
                }
            } else if (addNewCourse == true) {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Course Name is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                setCourseNameError(res.Msg)
                result = false;
            }

            //Course Provider Validation
            if (state.courseProvider != undefined && addNewCourse == false) {

                ValidateProps = {
                    value: state.courseProvider,
                    fieldType: 'dropdown',
                    fieldName: 'Course Provider',
                    required: true,
                    fieldId: 'courseProvider'
                } as ValidationRequestProps
                const courseProviderVal: ValidationResponseProps = Validations(ValidateProps)
                if (!courseProviderVal.Valid) {
                    result = false;
                    failedResult.push(courseProviderVal)
                    setErrorCoursePDD(courseProviderVal.Msg)
                }
            } else if (addNewCourse == false) {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Course Provider is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setErrorCoursePDD(res.Msg)
            }

            //Course Provider Validation
            if (state.courseProviderName != undefined && addNewCourse == true) {

                ValidateProps = {
                    value: state.courseProviderName,
                    fieldType: 'text',
                    fieldName: 'Course Provider',
                    required: true,
                    fieldId: 'courseProvider'
                } as ValidationRequestProps
                const courseProviderVal: ValidationResponseProps = Validations(ValidateProps)
                if (!courseProviderVal.Valid) {
                    result = false;
                    failedResult.push(courseProviderVal)
                    setErrorCoursePtxt(courseProviderVal.Msg)
                }
            } else if (addNewCourse == true) {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Course Provider is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setErrorCoursePtxt(res.Msg)
            }

            //Course Date Validation
            if (state.courseDate != undefined) {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const date1 = moment(state.courseDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                rule = [{
                    Rule: `${new Date(date1) < new Date(Date.now())}`,
                    Type: ValidationRuleType.CUSTOM,
                }]
                ValidateProps = {
                    value: state.courseDate,
                    fieldType: 'date',
                    fieldName: 'Course Date',
                    required: true,
                    rules: rule,
                    fieldId: 'courseDate'
                } as ValidationRequestProps
                const courseProviderVal: ValidationResponseProps = Validations(ValidateProps)
                if (!courseProviderVal.Valid) {
                    result = false;
                    failedResult.push(courseProviderVal)
                    setCourseDateError(courseProviderVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Course Date is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setCourseDateError(res.Msg)
            }

            //Course Location Validation
            if (state.location != undefined) {

                ValidateProps = {
                    value: state.location,
                    fieldType: 'date',
                    fieldName: 'Course Location',
                    required: true,
                    fieldId: 'courseLocation'
                } as ValidationRequestProps
                const courseProviderVal: ValidationResponseProps = Validations(ValidateProps)
                if (!courseProviderVal.Valid) {
                    result = false;
                    failedResult.push(courseProviderVal)
                    setCourseLocationError(courseProviderVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Course Location is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setCourseLocationError(res.Msg)
            }

            //Course Provider Email Validation
            if (state.providerEmail != undefined && state.providerEmail != '' ) {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                rule = [{
                    Rule: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.providerEmail,
                    fieldType: 'email',
                    fieldName: 'Provider Email',
                    required: true,
                    rules: rule,
                    fieldId: 'providerEmail'
                } as ValidationRequestProps
                const courseProviderVal: ValidationResponseProps = Validations(ValidateProps)
                if (!courseProviderVal.Valid) {
                    result = false;
                    failedResult.push(courseProviderVal)
                    setErrorCoursePemail(courseProviderVal.Msg)
                }
            }else if(addNewCourse == true){
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Course Provider Email is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setErrorCoursePemail(res.Msg)
            }

        } else {
            const res: ValidationResponseProps = {
                Valid: false,
                Msg: 'Please fill required fields in course details!'
            } as ValidationResponseProps
            failedResult.push(res)
            result = false;
            setCourseDateError('Course Date is Required !');
            setErrorCoursePDD('Course Provider is Required !');
            setErrorCoursePtxt('Course Provider is Required !');
            setErrorCourseDD('Course is Required !')
            setCourseNameError('Course is Required !');
        }

        return result
    }
    //endregion
    const Save = () => {
        if (!Validating()) {
            console.log(failedResult)
            //setErrorPop(true)
            return;
        }
        if (state !== undefined) {
            const saveResult = AddNewCoursetoDB(state)
            toast.dismiss()
            const id = toast.loading('Pending..');
            //PromiseToast('Pending...', 'Save Success !', 'Error', saveResult)
            Promise.resolve(saveResult).then((r) => {
                //console.log(r);
                //fileUplaod(uploadFile,r.courseRes.courseId)
                const course = r.auditorCourseRess;
                if (r.response != undefined && r.response.data.error) {
                    toast.update(id, {render: r.response.data.error.detail, type: "error", isLoading: false});
                } else {
                    toast.update(id, {render: "Successfully Added !", type: "success", isLoading: false});
                    setTimeout(()=>{toast.dismiss(id)},1500)
                    toggleModel('save')
                }
                //toggleModel('save')
            })
        } else {
            alert("Please fill the form")
        }
    }
    const fileUplaod = (file: any, courseID: any) => {
        const fileData: FileUplaodReq = {
            file: file,
            auditorID: auditorId,
            type: "CERT",
            refID: courseID.toString(),
            userID: session?.userId.toString(),
            email: session?.userName.toString()
        };
        const result = FileUploadingToServer(fileData)
    }

    useEffect(() => {
        Promise.resolve(provider).then((value) => {
            setProviders(value)
            if (courseId > 0) {
                let provid = ''
                for (let i = 0; i < value.length; i++) {
                    //console.log(state)
                    //console.log(value[i].text)
                    if (value[i].value == state?.courseProviderName) {
                        provid = value[i].text
                    }
                }
            }

        })
    }, [provider])

    useEffect(() => {
        Promise.resolve(course).then((value) => {
            setCourses(value)
            setTimeout(() => {
                if (courseId > 0) {
                    //setAddNewCourse(false);
                    setddCourseId(courseId.toString());
                }
            }, 1000)
        })
    }, [course])

    useEffect(() => {
        if (courseMId > 0) {
            //setLoading(true);
            Promise.resolve(fetchSingleCourseUsingMID(courseMId)).then((res) => {
                //console.log( providers)
                //eslint-disable-next-line
                //debugger
                if (res.courseTentativeClassification == undefined || res.courseTentativeClassification.length == 0 || res.courseTentativeClassification == 'Pre-requisite') {
                    if (courseId > 0) {
                        setddCourseId(courseId.toString());
                        setEnable(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : false)
                        setIsEditCourse(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : false);
                    } else {
                        setAddNewCourse(true);
                        setIsEditCourse(true);
                        setEnable(true)
                    }

                    // console.log(provid)

                    setState({
                        ...res,
                        courseId: res.courseId,
                        id: res.id,
                        isVerified: res.isVerified,
                        courseName: res.courseName,
                        courseProviderName: res.courseProviderName,
                        providerEmail: providerEmail,
                        certificateNumber: certificateNumber,
                        courseDate: moment(res.courseDate).format('DD/MM/YYYY'),
                        auditorId: auditorId,
                        location: res.location
                    });

                } else {
                    setAddNewCourse(false);
                    setEnable(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : false)
                    setIsEditCourse(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : false);
                    setState({
                        ...res,
                        providerEmail: providerEmail,
                        certificateNumber: certificateNumber,
                        courseDate: moment(res.courseDate).format('DD/MM/YYYY'),
                        auditorId: auditorId
                    });
                }

            });
            //setState({...state, providerEmail:providerEmail, certificateNumber:certificateNumber})
            const getfile = fetchFiles(session.userName, 'CERT')
            Promise.resolve(getfile).then((value) => {
                if (value.length > 0) {
                    //eslint-disable-next-line
                    if(value.some(x => x.refId2 == courseMId)){
                        setFileIcon('file')
                    }

                }
            })
        } else {
            setEnable(true)
            setIsEditCourse(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : isEdit);
        }
        setState({...state, auditorId: auditorId})

    }, []);


    const ddCourseOnChange = (e: any, element: any) => {
        if (element.value === -100) {
            setAddNewCourse(true)
            setState({...state, courseId: 0, courseDate: '', auditorId: auditorId})
            setEnable(true)
        } else {
            setAddNewCourse(false)
            setState({...state, courseId: element.value})
            setddCourseId(element.value)
            Promise.resolve(fetchSingleCourse(element.value)).then((res) => {
                //setState({...res, courseDate: moment(res.courseDate).format('DD/MM/YYYY'), auditorId: auditorId});
                setState({...res, courseDate: '',location:'', auditorId: auditorId});
                setEnable(true)
            });
        }
    }

    const ddProviderChage = async(e: any, element: any) => {
        const courses = await fetchCoursesWithProviders();
        const filterCourse = courses.filter((x)=> x.courseProviderId == element.value)
        const coursesArray: DropDownOptions[] = [];
        filterCourse.map((item: Courses) => {
            coursesArray.push({key: item.courseId, value: item.courseId.toString(), text: item.courseName})
        })
        setCourses(coursesArray)
    }

    const closeAddNewCourse = () => {
        setState({courseId: 0})
        setAddNewCourse(false)
    }

    return (
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            {!addNewCourse ? (
                                <NameWithDropDown
                                    selection={true}
                                    clearable={true}
                                    search={true}
                                    labelText={'Course'}
                                    labelPosition={Position.Top}
                                    id={'ddCourse'}
                                    placeholder={'Select Course'}
                                    options={courses}
                                    onChange={ddCourseOnChange}
                                    value={ddcourseId}
                                    disabled={!isEditCourse}
                                    onerror={errorCourseDD}
                                    required={true}
                                />
                            ) : (
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column width={14}>
                                            <NameWithDefault
                                                labelText={'Course'}
                                                fluid={true}
                                                labelPosition={Position.Top}
                                                id={'txtCourse'}
                                                type={'text'}
                                                required={true}
                                                placeholder={'Enter Course Name'}
                                                value={state?.courseName}
                                                onError={courseNameError}
                                                onChange={(e: any) => {
                                                    e.preventDefault();
                                                    setState({...state, courseName: e.target.value})
                                                }}
                                                disabled={!isEditCourse}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={2} style={{paddingTop: '20px'}}>
                                            <IconCircleOutlineButton id={'btnCloseAddNew'} onClick={closeAddNewCourse}
                                                                     color={'grey'} icon={"close"}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>)}
                        </Grid.Column>
                        <Grid.Column>
                            {!addNewCourse ? (<NameWithDropDown
                                    selection={true}
                                    clearable={true}
                                    search={true}
                                    labelText={'Course Provider'}
                                    labelPosition={Position.Top}
                                    id={'ddCourseProvider'}
                                    placeholder={'Select Provider'}
                                    options={providers}
                                    value={state?.courseProvider}
                                    onChange={(e: any, data: any) => {
                                        e.preventDefault();
                                        ddProviderChage(e, data);
                                        setState({...state, courseProvider: data.value})
                                    }}
                                    disabled={!isEnable}
                                    required={true}
                                    onerror={errorCoursePDD}
                                />) :
                                (<NameWithDefault
                                    labelText={'Course Provider'}
                                    fluid={true}
                                    labelPosition={Position.Top}
                                    id={'txtCourseProvider'}
                                    type={'text'}
                                    required={true}
                                    placeholder={'Enter Course Provider'}
                                    value={state?.courseProviderName}
                                    onError={errorCoursePtxt}
                                    onChange={(e: any) => {
                                        e.preventDefault();
                                        setState({...state, courseProviderName: e.target.value})
                                    }}
                                    disabled={!isEditCourse}
                                />)}

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <NameWithDate
                                labelText={'Date'}
                                fluid={true}
                                labelPosition={Position.Top}
                                id={'txtDate'}
                                type={'date'}
                                required={true}
                                value={state?.courseDate}//moment(state?.courseDate).format('DD/MM/YYYY')}
                                onError={courseDateError}
                                onChange={(e: any, data: any) => {/*e.preventDefault();*/

                                    if(data.value.toString().length > 7 && moment(data.value,'DD/MM/YYYY').isAfter()){
                                        setCourseDateError('Course Date Invalid !')
                                        setState({...state, courseDate: ''})
                                        return;
                                    }else {
                                        setCourseDateError('')
                                        setState({...state, courseDate: data.value})
                                    }
                                }}
                                onLostFocus={(e: any, data: any) => {/*e.preventDefault();*/
                                    if(moment(data.value,'DD/MM/YYYY').isAfter()){
                                        setCourseDateError('Course Date Invalid !')
                                        setState({...state, courseDate: ''})
                                        return;
                                    }else {
                                        setCourseDateError('')
                                        setState({...state, courseDate: data.value})
                                    }
                                }}
                                disabled={!isEnable}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <NameWithDefault
                                labelText={'Location'}
                                fluid={true}
                                labelPosition={Position.Top}
                                id={'txtLocation'}
                                type={'text'}
                                required={true}
                                placeholder={'Enter Location'}
                                value={state?.location}
                                onError={courseLocationError}
                                onChange={(e: any) => {
                                    e.preventDefault();
                                    setState({...state, location: e.target.value})
                                }}
                                disabled={!isEnable}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <NameWithEmail
                                labelText={'Course Provider Email'}
                                fluid={true}
                                labelPosition={Position.Top}
                                id={'txtEmail'}
                                type={'email'}
                                required={addNewCourse}
                                placeholder={'Enter Course Provider Email'}
                                value={state?.providerEmail}
                                onChange={(e: any) => {
                                    e.preventDefault();
                                    setState({...state, providerEmail: e.target.value})
                                }}
                                disabled={!isEditCourse}
                                onError={errorCoursePemail}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <NameWithDefault
                                labelText={'Certificate Number'}
                                fluid={true}
                                labelPosition={Position.Top}
                                id={'txtCertificateNumber'}
                                type={'text'}
                                required={false}
                                placeholder={'Enter Certificate Number'}
                                value={state?.certificateNumber}
                                onChange={(e: any) => {
                                    e.preventDefault();
                                    setState({...state, certificateNumber: e.target.value})
                                }}
                                disabled={!isEditCourse}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {courseMId > 0 ? (<Grid.Row columns={2}>
                        <Grid.Column width={4}>
                            <span style={{display: 'inline-flex'}}><b>Support Documents</b></span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            { /*isEditCourse ? (<FileUpload
                                onChange={(e: any) => {
                                    if (resumeValidation(e)) {
                                        setUploadFile(e.target)
                                        console.log(e.target.files[0])
                                        console.log(e.target.value)
                                    }
                                }}
                                color={'blue'}/>): null */}
                            <FontAwesomeIcon id={'btnClick'} icon={FileIcon} size={"xl"} onClick={() => {
                                setOpen(true)
                            }} style={{cursor: 'pointer'}} />
                            {/*<Icon id={'btnClick'} className={FileIcon} />*/}
                        </Grid.Column>
                    </Grid.Row>) : null}
                    <Grid.Row columns={2}>
                        <Grid.Column width={12} largeScreen={12} widescreen={12} tablet={11} computer={11} mobile={10}></Grid.Column>
                        <Grid.Column width={4} largeScreen={4} widescreen={4} tablet={5} computer={5} mobile={4}>
                            {isEditCourse ? (<ControllButton
                                cancelOnclick={toggleModel}
                                saveOnclick={Save}
                            />) : null}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Modal open={open ? open : false} modalAction={false} setOpen={setOpen} title={'Course Certificates'}
                   ModalBody={() => (
                       <FileUploadPop
                           fileType={'CERT'}
                           auditorEmail={session.userName}
                           mappingId={courseMId}
                           message={'Please note you can only have one course certificate for each course and uploading a new file will replace the existing file'}
                           auditorId={auditorId != null ? auditorId : session.auditorId}
                           editable={isEditCourse}
                       />)}/>
        </React.Fragment>
    );
};

export default AddNewCourse;