import React, {useEffect, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import Modal from "../../../framework/components/Modals";
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import Position from "../../../framework/types/Position";
import NameWithDate from "../../../framework/widgets/NameWithText/NameWithDate";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import NameWithRadioButton from "../../../framework/widgets/NameWithCheckBox/NameWithRadioButton";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import NameWithMuliLine from "../../../framework/widgets/NameWithText/NameWithMuliLine";
import NameWithEmail from "../../../framework/widgets/NameWithText/NameWithEmail";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import {lstAuditParticipantsReq, RoadSafetyAuditProps} from "./DataModal";
import ControllButton from "../../../framework/widgets/ActionButtons/ControllButton";
import PromiseToast from "../../../framework/components/Toast/PromiseToast";
import {
    AddAuditLogs,
    AddMetoAudit,
    AddNewAudittoDB,
    DeleteAuditAdminApi,
    ResendEmailTOClientAndAuditorApi,
    fetchAllActiveAudits,
    fetchAuditorsByName,
    fetchAudits,
    fetchSingleAudit
} from "./ProfileController";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import {Oval} from "react-loader-spinner";
import TableHeader from "../../../framework/types/TableHeader";
import moment from "moment/moment";
import {
    ValidationRequestProps,
    ValidationResponseProps,
    ValidationRuleProps
} from "../../../framework/types/ValidationProps";
import Validations from "../../../framework/common/Validations";
import ValidationRuleType from "../../../framework/types/ValidationRuleType";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import {toast} from "react-toastify";
import ErrorToast from "../../../framework/components/Toast/ErrorToast";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import {PaginationProps} from "antd";
import AuditAdminLog from "../../audits/AuditAdminLog";
import SuccessToast from "../../../framework/components/Toast/SuccessToast";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../framework/components/Buttons/DefaultButton";
import waringImage from "../../../assets/images/icons/warning.png";
import { IAuditLogs } from "../../../models/apiResponce/Notification/GetNotifications";

interface Props {
    //eslint-disable-next-line
    states: any;
    //eslint-disable-next-line
    auditStages: any;
    //eslint-disable-next-line
    toggleModel: any;
    //eslint-disable-next-line
    auditId: any;
    //eslint-disable-next-line
    isEdit: any;
    //eslint-disable-next-line
    auditorId: any;
    //eslint-disable-next-line
    setAuditor: any;
}

const AddNewAudit = ({states, auditStages, toggleModel, auditId, isEdit, auditorId}: Props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const initialState: RoadSafetyAuditProps = {
        // Initialize other properties as needed
        lstAuditParticipantsReq: [] // Ensure this is an empty array initially
      };

    const [state, setState] = React.useState<RoadSafetyAuditProps>(initialState);
    const [rbtVal, setRbtVal] = useState('no')
    const [chkTfNSW, setChkTfNSW] = useState(false)
    const [addNewAudit, setAddNewAudit] = React.useState<boolean>(true);
    const [intLoad, setIntLoad] = useState(true);
    const [stateList, setStateList] = useState<DropDownOptions[]>([]);
    const [stages, setStages] = useState<DropDownOptions[]>([]);
    const [isEditCourse, setIsEditCourse] = React.useState<boolean>(false);
    const [status, SetStatus] = React.useState('')
    const [temporaryLogs, setTemporaryLogs] = useState<IAuditLogs[]>([]);
    const [statusOption] = useState<DropDownOptions[]>(
        [
            {
                text:"Pending to be verified",
                value:'1',
                key:1
            },
            {
                text:"Pending in review process",
                value:'2',
                key:2
            },
            {
                text:"Verified",
                value:'3',
                key:3
            },
            {
                text:"Not recognised",
                value:'5',
                key:5
            }
            ])

    const [auditDateError, setAuditDateError] = React.useState('')
    //eslint-disable-next-line
    const [deleteError, setDleteError] = React.useState('')
    const [auditLocationError, setAuditLocationError] = React.useState('')
    const [titleError, setTitleError] = React.useState('')
    const [projectDescError, setProjectDescError] = React.useState('')
    const [purposeOfAuditError, setPurposeOfAuditError] = React.useState('')
    const [clientCompanyError, setClientCompanyError] = React.useState('')
    const [clientContactPersonError, setClientContactPersonError] = React.useState('')
    const [clientContactNumberError, setClientContactNumberError] = React.useState('')
    const [clientMobileNumberError, setClientMobileNumberError] = React.useState('')
    const [clientContactEmailError, setClientContactEmailError] = React.useState('')
    const [isAddMember, setIsAddmember] = React.useState(false)
    const [txtAddMember, setTxtAddMember] = React.useState('');
    const [open2, setOpen2] = useState(false);
    const [delAuditId, setDelAuditId] = useState('');
    const [addMemberError, setAddMemberError] = React.useState('');
    const [open3, setOpen3] = useState(false);
    const [delAuditAuditorId, setDelAuditAuditorId] = useState('');
    const [delAuditAuditorName, setDelAuditAuditorName] = useState('');

    const [pages, setPages] = useState(1)
    const [type, setType] = useState('active')
    const [current, setCurrent] = useState(1);
    const [showDelete, setShowDelete] = useState(false);

    const pathName = window.location.pathname;
    const encid = new URLSearchParams(location.search).get('get')
    let DataRow:object[] = []
    let AuditData:RoadSafetyAuditProps = {} as RoadSafetyAuditProps

    useEffect(() => {
        if (auditId > 0) {
            console.log('encid', isEdit)
          setLoading(true);

          // Fetch the audit details
          const fetchAuditDetails = async () => {
            try {
              const res = await fetchSingleAudit(auditId);

              const formattedState = {
                ...res,
                finalSignoffDate: moment(res.finalSignoffDate).format("DD-MM-YYYY")
              };

              setState(formattedState);
              AuditData = formattedState; // Ensure that AuditData is not causing re-renders
              setRbtVal(res.isTfNSW ? 'yes' : 'no');
              setChkTfNSW(res.isTfNSW ? true : false)
              setIsEditCourse(true); // Remove this from the dependency array if it's causing re-renders

              // Show delete button based on user role
              setShowDelete(session.userRoleId == 1 || session.userRoleId == 4);

              if (res?.lstAuditParticipantsReq && res.lstAuditParticipantsReq.length > 0) {
                console.log(res.lstAuditParticipantsReq);
                const objAddAuditors: object[] = []//rsaAddAuditorDet

                res.lstAuditParticipantsReq.forEach((r) => {
                  const row = {
                    auditorId: r.auditorId,
                    name: r.auditorFullName,
                    status: r.auditorId === 0 ? 'Unregistered Auditor' : 'Auditor',
                    isTeamLeader: r.isLeadAuditor,
                    isConfirmedByAuditor: r.isConfirmed
                  };
                  objAddAuditors.push(assignAddAuditortoAuditRaw(row, true));
                });

                DataRow = objAddAuditors;
                setRSAAddAuditorDet(objAddAuditors);
                console.log("RSA Audit Details: ", objAddAuditors);
              }
            } catch (error) {
              console.error('Error fetching audit details:', error);
            } finally {
              setLoading(false);
              setIntLoad(false);
            }
          };

          fetchAuditDetails();
        }
        // auditId is the only dependency here, as it's the ID we need to load the audit details
      }, [auditId]);


    useEffect(() => {
        Promise.resolve(states).then((value) => {
            setStateList(value)
        })
    }, [states])

    useEffect(() => {
        Promise.resolve(auditStages).then((value) => {
            setStages(value)
            setIsEditCourse(!isEdit)
        })
    }, [auditStages, isEditCourse])


    const rsaAuditorHead: TableHeader[] = [
        {
            text: ""
        },
        {
            text: "Audit ID"
        },
        {
            text: "Name"
        },
        {
            text: "Location"
        },
        {
            text: "Is TfNSW"
        },
        {
            text: "Audit Status"
        },
        {
            text: " "
        }
    ];

    const rsaAddAuditorHead: TableHeader[] = [
        {
            text: "Auditor ID"
        },
        {
            text: "Name"
        },
        {
            text: "Auditor's Status"
        },
        {
            text: "Lead Auditor"
        },
        {
            text: "Confirmed"
        },
        {
            text: "Delete", // New header for delete option
        }
    ];

    const rsaAddAuditorListHead: TableHeader[] = [
        {
            text: "Auditor ID"
        },
        {
            text: "First Name"
        },
        {
            text: "Last Name"
        },
        {
            text: " "
        }
    ];

    //eslint-disable-next-line
    const rsaAuditDetRow: object[] = [
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "No Audit Found",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        }
    ];

    const session = getSessionCookie();

    const makeMeLeader = (id: string, name: string, isLeader: boolean) => {
        setRSAAddAuditorDet(prevData => prevData.map(item => {
            if (Array.isArray(item)) {
                // Create a shallow copy of the item array
                const newItem = [...item];

                if (newItem[0] && newItem[1] && newItem[0].text === id && newItem[1].text === name) {
                    // Update the isTeamLeader property
                    newItem[3] = { ...newItem[3], text: true };
                } else if (newItem[3]) {
                    // Ensure others are not marked as leader
                    newItem[3] = { ...newItem[3], text: false };
                }

                return newItem;
            }
            return item;
        }));

        setState(prevState => {
            const updatedParticipants = prevState?.lstAuditParticipantsReq?.map(participant => {
                return participant.auditorId === parseInt(id) && participant.auditorFullName === name
                    ? { ...participant, isLeadAuditor: !participant.isLeadAuditor }
                    : { ...participant, isLeadAuditor: false };
            });

            return { ...prevState, lstAuditParticipantsReq: updatedParticipants };
        });
    };

    const makeAuditorConfirm = (id: string, name: string, isConfirm: boolean) => {
        setRSAAddAuditorDet(prevData => prevData.map(item => {
            if (Array.isArray(item)) {
                // Create a shallow copy of the item array
                const newItem = [...item];

                if (newItem[0] && newItem[1] && newItem[0].text === id && newItem[1].text === name) {
                    // Toggle the isConfirmedByAuditor property
                    newItem[4] = { ...newItem[4], text: !newItem[4].text };
                }

                return newItem;
            }
            return item;
        }));
    };

    const assignAddAuditortoAuditRaw = (raw: any, edit: boolean) => {
        return [
            raw.auditorId === 0
            ? {
                  tag: "text",
                  text: raw.auditorId,
                  icon: "",
                  onclick: null,
              }
            : {
                  tag: "link",
                  text: raw.auditorId,
                  icon: "",
                  onclick: raw.auditorId,
                  href: `/profile?get=${btoa(raw.auditorId)}`,
              },
        raw.auditorId === 0
            ? {
                  tag: "text",
                  text: raw.name,
                  icon: "",
                  onclick: null,
              }
            : {
                  tag: "link",
                  text: raw.name,
                  icon: "",
                  onclick: raw.auditorId,
                  href: `/profile?get=${btoa(raw.auditorId)}`,
              },
            {
                tag: "text",
                text: raw.status,
                icon: "",
                onclick: null
            },
            encid == null && isEdit ? {
                tag: "checkbox",
                text: raw.isTeamLeader,
                icon: "",
                onclick: () => {
                    makeMeLeader(raw.auditorId, raw.name, raw.isTeamLeader)
                }
            } : {
                tag: 'icon-fa',
                text: 'center',
                icon: raw.isTeamLeader == true ? "check" : "minus",
                onclick: null
            },
            encid == null && isEdit && (session.userRoleId == 1 || session.userRoleId == 4) ? {
                tag: 'checkbox',
                text: raw.isConfirmedByAuditor,
                icon: raw.isConfirmedByAuditor == true ? "check" : "minus",
                onclick: () => {
                    makeAuditorConfirm(raw.auditorId, raw.name, raw.isConfirmedByAuditor)
                }
            }:{
                tag: 'icon-fa',
                text: "center",
                icon: raw.isConfirmedByAuditor == true ? "check" : "minus",
                onclick: null
            },
            encid == null && isEdit ?
            {
                tag: "icon-fa",
                text: "center",
                icon: "delete",
                onclick: () => {
                    promptDeleteAuditor(raw.auditorId, raw.name)
                }
            }:{
                tag: "icon-fa",
                text: "center",
                icon: "delete",
                onclick: null
            }
        ]
    }
    const [rsaAuditDet, setRSADet] = useState<object[]>([])
    const [rsaAuditorDet, setRSAAuditorDet] = useState<object[]>([])

    const promptDeleteAuditor = (auditorId: any, auditorName: any) => {
        setDelAuditAuditorId(auditorId)
        setDelAuditAuditorName(auditorName)
        setOpen3(true)
    };

    const deleteAuditor = (auditorId: any, auditorName: any) => {
        // Update lstAuditParticipantsReq in state
        if (state && state.lstAuditParticipantsReq) {
            const updatedParticipants = state.lstAuditParticipantsReq.filter(participant =>
                participant.auditorFullName !== auditorName
            );
            // Update the state immutably
            setState(prevState => ({
                ...prevState,
                lstAuditParticipantsReq: updatedParticipants
            }));
        }

        // Update rsaAddAuditorDet state
        setRSAAddAuditorDet(prevData => prevData.filter(item => {
            if (Array.isArray(item)) {
                // Check if the item matches the condition to delete
                return !(item[0]?.text === auditorId && item[1]?.text === auditorName);
            }
            return true; // Keep other items in the array
        }));
        setDleteError('');
        setOpen3(false)
    };

    useEffect(() => {
        console.log(state?.lstAuditParticipantsReq);
    }, [state?.lstAuditParticipantsReq]);

    // Use useEffect to log the state after it has been updated
    useEffect(() => {
        console.log('dddddddddddddddddddddddddddddd')
        console.log(isEdit)
        console.log(isEditCourse)
        console.log(addNewAudit)
        console.log(state);
    }, [state]);

    const initializeRSAAddAuditorDet = () => {
        if (session.userRoleId === 2 || session.userRoleId === 3 || session.userRoleId === 5) {
            return [
                assignAddAuditortoAuditRaw({
                    auditorId: session.auditorId,
                    name: session.userDisplayName,
                    status: 'Auditor',
                    isTeamLeader: true,
                    isConfirmedByAuditor: false
                }, false)
            ];
        }
        return [];
    };

    const [rsaAddAuditorDet, setRSAAddAuditorDet] = useState<object[]>(initializeRSAAddAuditorDet);


    //eslint-disable-next-line
    let failedResult: ValidationResponseProps[] = []


    const Validating = () => {
        setAuditDateError('')
        setAuditLocationError('')
        setTitleError('')
        setProjectDescError('')
        setPurposeOfAuditError('')
        setClientCompanyError('')
        setClientContactPersonError('')
        setClientContactNumberError('')
        setClientMobileNumberError('')
        setClientContactEmailError('')
        setAddMemberError('')
        let result = true;
        let ValidateProps: ValidationRequestProps = {} as ValidationRequestProps;
        //course section
        if (state != undefined) {
            //Audit Date Validation
            if (state.finalSignoffDate != undefined) {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                // const date1 = moment(state.finalSignoffDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                // rule = [{
                //     Rule: `${new Date(date1) < new Date(Date.now())}`,
                //     Type: ValidationRuleType.CUSTOM,
                // }]


                // const fsod = moment(state.finalSignoffDate, 'DD/MM/YYYY').startOf('day');
                // const thisMoment = moment();
                const fsod = moment(state.finalSignoffDate, 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD');
                const currentDate = moment().startOf('day').format('YYYY-MM-DD');

                console.log("Validating fsod:  ", fsod);
                console.log("Validating currentDate:  ", currentDate);
                rule = [{
                    Rule: `${fsod <= currentDate}`,
                    Type: ValidationRuleType.CUSTOM,
                }]

                ValidateProps = {
                    value: state.finalSignoffDate,
                    fieldType: 'date',
                    fieldName: 'Final Signoff Date',
                    required: true,
                    rules: rule,
                    fieldId: 'finalSignoffDate'
                } as ValidationRequestProps
                const finalSignoffDateVal: ValidationResponseProps = Validations(ValidateProps)
                if (!finalSignoffDateVal.Valid) {
                    result = false;
                    failedResult.push(finalSignoffDateVal)
                    setAuditDateError(finalSignoffDateVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Final Signoff Date is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setAuditDateError(res.Msg)
            }

            //Audit Location Validation
            if (state.locationOfAudit != undefined) {

                ValidateProps = {
                    value: state.locationOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Location',
                    required: true,
                    fieldId: 'locationOfAudit'
                } as ValidationRequestProps
                const locationOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!locationOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(locationOfAuditVal)
                    setAuditLocationError(locationOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Location is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setAuditLocationError(res.Msg)
            }

            //Audit Title Validation
            if (state.titleOfAudit != undefined) {

                ValidateProps = {
                    value: state.titleOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Title',
                    required: true,
                    fieldId: 'titleOfAudit'
                } as ValidationRequestProps
                const titleOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!titleOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(titleOfAuditVal)
                    setTitleError(titleOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Title is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setTitleError(res.Msg)
            }

            //Audit Desc Validation
            if (state.projectDescription != undefined) {

                ValidateProps = {
                    value: state.projectDescription,
                    fieldType: 'text',
                    fieldName: 'Audit Description',
                    required: true,
                    fieldId: 'projectDescription'
                } as ValidationRequestProps
                const projectDescriptionVal: ValidationResponseProps = Validations(ValidateProps)
                if (!projectDescriptionVal.Valid) {
                    result = false;
                    failedResult.push(projectDescriptionVal)
                    setProjectDescError(projectDescriptionVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Description is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setProjectDescError(res.Msg)
            }

            //Audit Purpose Validation
            if (state.purposeOfAudit != undefined) {

                ValidateProps = {
                    value: state.purposeOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Purpose',
                    required: true,
                    fieldId: 'purposeOfAudit'
                } as ValidationRequestProps
                const purposeOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!purposeOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(purposeOfAuditVal)
                    setPurposeOfAuditError(purposeOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Purpose is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setPurposeOfAuditError(res.Msg)
            }

            //Client Company Validation
            if (state.clientCompany != undefined) {

                ValidateProps = {
                    value: state.clientCompany,
                    fieldType: 'text',
                    fieldName: 'Client Company',
                    required: true,
                    fieldId: 'clientCompany'
                } as ValidationRequestProps
                const clientCompanyVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientCompanyVal.Valid) {
                    result = false;
                    failedResult.push(clientCompanyVal)
                    setClientCompanyError(clientCompanyVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Company is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientCompanyError(res.Msg)
            }

            //Client Contact Person Validation
            if (state.clientContactPerson != undefined) {

                ValidateProps = {
                    value: state.clientContactPerson,
                    fieldType: 'text',
                    fieldName: 'Client Contact Person',
                    required: true,
                    fieldId: 'clientContactPerson'
                } as ValidationRequestProps
                const clientContactPersonVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactPersonVal.Valid) {
                    result = false;
                    failedResult.push(clientContactPersonVal)
                    setClientContactPersonError(clientContactPersonVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Person is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactPersonError(res.Msg)
            }

            //Client Contact Tel. Validation
            if (state.clientContactNumber != undefined && state.clientContactNumber != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const regx = "^[^a-zA-Z]+$"
                rule = [{
                    Rule: regx,
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientContactNumber,
                    fieldType: 'phone',
                    fieldName: 'Client Contact Telephone',
                    required: true,
                    rules: rule,
                    fieldId: 'clientContactNumber'
                } as ValidationRequestProps
                const clientContactTelVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactTelVal.Valid) {
                    result = false;
                    failedResult.push(clientContactTelVal)
                    setClientContactNumberError(clientContactTelVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Telephone is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactNumberError(res.Msg)
            }

            //Client Contact Mobile Validation
            if (state.clientMobileNumber != undefined && state.clientMobileNumber != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const regx = "^[^a-zA-Z]+$"
                rule = [{
                    Rule: regx,
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientMobileNumber,
                    fieldType: 'phone',
                    fieldName: 'Client Mobile No',
                    required: true,
                    rules: rule,
                    fieldId: 'clientMobileNumber'
                } as ValidationRequestProps
                const clientContactMobileVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactMobileVal.Valid) {
                    result = false;
                    failedResult.push(clientContactMobileVal)
                    setClientMobileNumberError(clientContactMobileVal.Msg)
                }
            }

            //Client Contact Email Validation
            if (state.clientContactEmail != undefined && state.clientContactEmail != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                rule = [{
                    Rule: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$',
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientContactEmail,
                    fieldType: 'email',
                    fieldName: 'Client Contact Email',
                    required: true,
                    rules: rule,
                    fieldId: 'clientContactEmail'
                } as ValidationRequestProps
                const clientContactEmailVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactEmailVal.Valid) {
                    result = false;
                    failedResult.push(clientContactEmailVal)
                    setClientContactEmailError(clientContactEmailVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Email is Required'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactEmailError(res.Msg)
            }

        } else {
            const res: ValidationResponseProps = {
                Valid: false,
                Msg: 'Please fill required fields in course details!'
            } as ValidationResponseProps
            failedResult.push(res)
            result = false;
            setAuditDateError('Course Date is Required !');
            setClientContactEmailError('Course is Required');
        }

        return result
    }

    const Save = () => {
        //eslint-disable-next-line
        let allData: lstAuditParticipantsReq[] = []
        if (rsaAddAuditorDet.length > 0) {
            const dataState = rsaAddAuditorDet
            dataState.forEach(x => {
                if (Array.isArray(x)) {
                    const y: lstAuditParticipantsReq = {
                        auditorFullName: x[1].text,
                        auditorId: x[0].text > 0 ? x[0].text : 999999999,
                        isConfirmed: false,
                        isLeadAuditor: x[3].text,
                    }
                    allData.push(y)
                }
            })
            setState({...state, lstAuditParticipantsReq: allData})
        }
        if (!Validating()) {
            console.log(failedResult)
            //setErrorPop(true)
            return;
        }
        if (rbtVal === 'yes' && chkTfNSW == false) {
            ErrorToast("Please confirm is TfNSW !")
            return;
        }

        if (state !== undefined && addNewAudit) {
            const saveResult = AddNewAudittoDB({...state, lstAuditParticipantsReq: allData})
            //PromiseToast('Pending...', 'Save Success !', 'Error', saveResult)
            toast.dismiss()
            const id = toast.loading('Pending..');
            Promise.resolve(saveResult).then((value) => {
                //console.log('Add Audit Save',saveResult)

                if (value.response != undefined && value.response.data.error) {
                    //toast.dismiss(id)
                    toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});
                    setTimeout(() => {
                        toast.dismiss(id)
                    }, 2500)

                } else {
                    toast.update(id, {render: "Successfully Added !", type: "success", isLoading: false});
                    setTimeout(() => {
                        toast.dismiss(id)
                    }, 1500)
                    toggleModel('save')
                }

            })
        } else if (state !== undefined) {
            toast.dismiss()
            toggleModel()
        }
    }

    const SaveAdmin = () => {
        //eslint-disable-next-line
        let allData: lstAuditParticipantsReq[] = []
        let toastMsg = ''
        if (rsaAddAuditorDet.length > 0) {
            const dataState = rsaAddAuditorDet
            dataState.forEach(x => {
                if (Array.isArray(x)) {
                    const y: lstAuditParticipantsReq = {
                        auditorFullName: x[1].text,
                        auditorId: x[0].text > 0 ? x[0].text : 999999999,
                        isConfirmed: x[4].text == 'center' ? x[4].icon == 'check' ? true : false : x[4].text,
                        isLeadAuditor: x[3].text == 'center' ? x[3].icon == 'check' ? true : false : x[3].text,
                        auditId:auditId
                    }
                    allData.push(y)
                }
            })
            if(state?.auditId){
                setState({...state , lstAuditParticipantsReq: allData})
                toastMsg = 'Successfully Updated !'
            }else{
                setState({...AuditData , lstAuditParticipantsReq: allData})
                toastMsg = 'Successfully Added !'
            }

        }
        if (!Validating()) {
            console.log(failedResult)
            //setErrorPop(true)
            return;
        }
        if (rbtVal === 'yes' && chkTfNSW == false) {
            ErrorToast("Please confirm is TfNSW !")
            return;
        }

        if (state !== undefined && addNewAudit) {
            const saveResult = AddNewAudittoDB({...state, lstAuditParticipantsReq: allData})
            //PromiseToast('Pending...', 'Save Success !', 'Error', saveResult)
            toast.dismiss()
            const id = toast.loading('Pending..');
            Promise.resolve(saveResult).then((value) => {

                if (value.response != undefined && value.response.data.error) {
                    //toast.dismiss(id)
                    toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});
                    setTimeout(() => {
                        toast.dismiss(id)
                    }, 2500)

                } else {
                    temporaryLogs.forEach(element => {
                        const getAssesmentHistry = AddAuditLogs(value.auditRes.auditId, element.description)
                        Promise.resolve(getAssesmentHistry).then((r) => {
                            // toast.update(id, {render: toastMsg, type: "success", isLoading: false});
                            // setTimeout(() => {
                            //     toast.dismiss(id)
                            // }, 1500)
                            toggleModel('save')
                        })
                    });
                    toast.update(id, {render: toastMsg, type: "success", isLoading: false});
                        setTimeout(() => {
                            toast.dismiss(id)
                            location.reload()
                        }, 1500)
                    toggleModel('save')
                }

            })
        } else if (state !== undefined) {
            toast.dismiss()
            toggleModel()
        }
    }


    const navigate = useNavigate();

    const promptDeleteAudit = (auditId: any) => {
        console.log('delete audit', auditId)
        if (rsaAddAuditorDet.length > 0) {
            setDleteError('Please unlink all auditors from this audit before proceeding');
            return;
        } else {
            setDelAuditId(auditId);
            setOpen2(true); // Open the confirmation modal
        }

    };

    const DeleteAuditAdmin = async () => {
        try {
            // Delete
            const result = await DeleteAuditAdminApi(delAuditId);
            if (result.auditRes) {
                SuccessToast('Audit deleted successfully');

                // Set a timeout before navigating
                setTimeout(() => {
                    // Redirect to the "/search/auditors?status=99&name=" path after the delay
                    navigate('/search/auditors?status=99&name=');
                }, 2000); // 2000 milliseconds delay (2 seconds)
            }
        } catch (error) {
            ErrorToast('Error deleting audit');
        } finally {
            setOpen2(false); // Close the modal
            setDelAuditId(''); // Reset the delete audit ID
        }
    };

    const ResendEmailTOClientAndAuditor = async () => {
        try{
            const result = await ResendEmailTOClientAndAuditorApi(state?.auditId, state?.auditorId);
            console.log(state?.lstAuditParticipantsReq)
            if (result) {
                SuccessToast('Emaiil resends successfully');
            }
        } catch (error) {
            ErrorToast('Error sending email');
        }
    }

    //eslint-disable-next-line
    const assignRaw = (raw: any) => {
        const auditorAudit = raw.isAuditorAdded
        const objDataRow = [
            {
                tag: "popup-icon",
                text: raw.auditID,
                icon: "view",
                onclick: raw.auditID,
                href: `/audits?id=${raw.auditID}`
            },
            {
                tag: "text",
                text: raw.auditID,
                icon: "",
                onclick: raw.auditID,
            },
            {
                tag: "text",
                text: raw.name,
                icon: "",
                onclick: null,
                width: "250px"
            },
            {
                tag: "text",
                text: raw.location,
                icon: "",
                onclick: null,
                width: ""
            },
            {
                tag: "text",
                text: raw.tfnsw,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.isPubliclyVisible,
                icon: "",
                onclick: null
            },
            session.status != 4 ? !auditorAudit ? {
                tag: "button",
                text: "Add Me",
                icon: "",
                width: '100px',
                onclick: () => {
                    toast.dismiss()
                    const id = toast.loading('Pending..');
                    const addMe = AddMetoAudit(auditorId, raw.auditID)
                    Promise.resolve(addMe).then((value) => {
                        if (value.response != undefined && value.response.data.error) {
                            //toast.dismiss(id)
                            toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});
                            setTimeout(() => {
                                toast.dismiss(id)
                            }, 2500)
                        } else {
                            toast.update(id, {render: "Successfully Added !", type: "success", isLoading: false});
                            setTimeout(() => {
                                toast.dismiss(id)
                            }, 1500)
                            toggleModel('Addme')
                        }

                    })
                }
            } : {
                tag: "text",
                text: "",
                icon: "",
                onclick: null
            } : ''
        ]
        return objDataRow
    }


    const assignAddAuditorRaw = (raw: any) => {
        return [
            {
                tag: "text",
                text: raw.auditorId,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.firstName,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.lastName,
                icon: "",
                onclick: null
            },
            {
                tag: "button",
                text: "Select",
                icon: "",
                onclick: () => {
                    AddAuditorToAudit(raw.auditorId, `${raw.firstName} ${raw.lastName}`, "Auditor")
                }
            }
        ]
    }

    const fetchAuditorsList = (auditorName: string) => {
        if (auditorName.length > 4) {
            const lstAuditors = fetchAuditorsByName(auditorName)
            Promise.resolve(lstAuditors).then((row) => {
                const objAuditors: object[] = [];
                if (row) {
                    console.log(row)
                    row.map((r: any) => {
                        objAuditors.push(assignAddAuditorRaw(r))
                        //[...rsaAuditDet, assignRaw(r)])
                    })
                    setRSAAuditorDet(objAuditors)
                } else {
                    setRSAAuditorDet([])
                }
            })
        }
    }

    const AddAuditorToAudit = (auditorId: number, auditorName: string, auditorStatus: string) => {
        const row = {
            auditorId: auditorId,
            name: auditorName,
            status: auditorStatus,
            isTeamLeader: false,
            isConfirmedByAuditor: false
        }

        //eslint-disable-next-line
        let objlstAuditParticipantsReq = state?.lstAuditParticipantsReq ? state?.lstAuditParticipantsReq : [{
            auditorId: state?.auditorId ? state.auditorId : session.auditorId,
            auditorFullName: session.userDisplayName,
            isLeadAuditor: true,
            isConfirmed: false
        }]
        const auditorAudit: lstAuditParticipantsReq = {
            auditorId: auditorId > 0 ? auditorId : 999999999,
            auditorFullName: auditorName,
            isLeadAuditor: false,
            isConfirmed: false
        }

        objlstAuditParticipantsReq.push(auditorAudit)
        setState({...state, lstAuditParticipantsReq: objlstAuditParticipantsReq})
        const objAddAuditors: object[] = rsaAddAuditorDet

        objAddAuditors.push(assignAddAuditortoAuditRaw(row, false));
        setRSAAddAuditorDet(objAddAuditors);
        setTxtAddMember('')
        setIsAddmember(!isAddMember)
        setRSAAuditorDet([])
        setAddMemberError('')
    }

    const fetchAllAuditsFromDb = (typeCall: string, pageNumber: number) => {
        //console.log("fetching audits" , state)
        setRSADet([])
        setLoading(true)
        const audits = fetchAllActiveAudits(typeCall, pageNumber)
        Promise.resolve(audits).then((value) => {
            //console.log('All',value)
            setLoading(false)
            const objAudits: object[] = [];
            if (value.auditsArray.length > 0) {
                setAddNewAudit(false)
                //eslint-disable-next-line
                value.auditsArray.map((r: any) => {
                    objAudits.push(assignRaw(r))
                    //setRSADet(objAudits) //[...rsaAuditDet, assignRaw(r)])
                })
                setPages(value.objTotalPage)
                setRSADet(objAudits)
            } else if (session.status == 4) {
                setRSADet([rsaAuditDetRow])
                setAddNewAudit(false)
                setIntLoad(false)
            } else {
                setRSADet([])
                setAddNewAudit(true)
                setIntLoad(false)
            }
        })

    }

    const onChange: PaginationProps['onChange'] = (page) => {
        setRSADet([])
        fetchAllAuditsFromDb(type, page - 1);
        setCurrent(page);
    };

    const fetchAuditsFromDb = () => {
        //console.log("fetching audits" , state)
        if (state && state.finalSignoffDate && state.finalSignoffDate.length > 0 && state.stageOfAudit && state.stageOfAudit.length > 0 && state.stateAuditPerformed && state.stateAuditPerformed.length > 0) {
            setLoading(true)
            const audits = fetchAudits(moment(state.finalSignoffDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), parseInt(state.stageOfAudit), parseInt(state.stateAuditPerformed), auditorId)
            Promise.resolve(audits).then((value) => {
                // console.log(value)
                setLoading(false)
                const objAudits: object[] = []//rsaAuditDet;
                if (value.length > 0) {
                    setAddNewAudit(false)
                    //eslint-disable-next-line
                    value.map((r: any) => {
                        objAudits.push(assignRaw(r))
                        //setRSADet(objAudits) //[...rsaAuditDet, assignRaw(r)])
                    })
                    setPages(0)
                    setRSADet(objAudits)
                } else if (session.status == 4) {
                    setRSADet([rsaAuditDetRow])
                    setAddNewAudit(false)
                    setIntLoad(false)
                } else {
                    setRSADet([])
                    setAddNewAudit(true)
                    setIntLoad(false)
                }
            })
        }

    }

    useEffect(() => {
        //eslint-disable-next-line
        //debugger;
        console.log(state?.finalSignoffDate)
        if (state?.auditId == undefined) {
            fetchAuditsFromDb()
        }

    }, [state?.finalSignoffDate, state?.stageOfAudit, state?.stateAuditPerformed])

    const toggleModelPop = (e: any) => {
        //eslint-disable-next-line
        //debugger;
        setOpen(!open)
    }


    return (
        <React.Fragment>
            <Container>
                <Grid>
                    {(showDelete) && (session.userRoleId == 1 || session.userRoleId == 4) ? (<Grid.Row columns={2}>
                        <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                     largeScreen={8}>
                            <NameWithDefault
                                labelText={'Audit ID'}
                                fluid={true}
                                labelPosition={Position.Top}
                                id={'txtAuditID'}
                                type={'text'}
                                required={false}
                                placeholder={'Audit ID'}
                                value={auditId}
                                disabled={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                     largeScreen={8}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Status'}
                                labelPosition={Position.Top}
                                id={'ddAuditStates'}
                                placeholder={'Enter state of Audit'}
                                options={statusOption}
                                required={false}
                                value={state?.auditStatusId?.toString()}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, auditStatusId: parseInt(data.value)});
                                }}
                                //onLostFocus={fetchAuditsFromDb}
                                disabled={isEditCourse}

                            />
                        </Grid.Column>
                    </Grid.Row>):null}
                    <Grid.Row columns={2}>
                        <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8} largeScreen={8}>
                            <NameWithDate
                                labelText={'Final Sign off Date'}
                                fluid={true}
                                labelPosition={Position.Top}
                                id={'txtDate'} type={'date'}
                                required={true}
                                placeholder={'Enter Date'}
                                value={state?.finalSignoffDate}//moment(state?.finalSignoffDate).format('DD/MM/YYYY') }
                                onError={auditDateError}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    //e.preventDefault();

                                    // console.log("Date changed: ", data);
                                    // console.log("MomentJs Convertion: ", moment(data.value, 'DD/MM/YYYY'));
                                    // console.log("MomentJs Convertion isAfter: ", moment(data.value, 'DD/MM/YYYY').isAfter());
                                    // console.log("MomentJs Convertion Test isAfter: ", moment('2023-12-13').isAfter('2023-12-14', 'day'));

                                    const fsod = moment(data.value, 'DD/MM/YYYY').startOf('day');
                                    const thisMoment = moment();

                                    // console.log("fsod", fsod);
                                    // console.log("thisMoment", thisMoment);

                                    if (data.value.toString().length > 7 && fsod.isAfter(thisMoment.startOf('day'))) {
                                        setAuditDateError('Invalid date format or date is in the future');
                                        setState({...state, finalSignoffDate: ''});
                                        return;
                                    } else {
                                        setAuditDateError('');
                                        setState({...state, finalSignoffDate: data.value});
                                    }


                                    // if (data.value.toString().length > 7 && moment(data.value, 'DD/MM/YYYY').isAfter()) {
                                    //     setAuditDateError('Final Sign off Date Invalid !')
                                    //     setState({...state, finalSignoffDate: ''})
                                    //     return;
                                    // } else {
                                    //     setAuditDateError('')
                                    //     setState({...state, finalSignoffDate: data.value})
                                    // }

                                }}
                                //onLostFocus={fetchAuditsFromDb}
                                disabled={isEditCourse}
                            />
                        </Grid.Column>
                        <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8} largeScreen={8}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Stage of Audit'}
                                labelPosition={Position.Top}
                                id={'ddStageAudit'}
                                placeholder={'Enter Stage of Audit'}
                                options={stages}
                                required={true}
                                value={state?.stageOfAudit}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, stageOfAudit: data.value});
                                }}
                                //onLostFocus={fetchAuditsFromDb}
                                disabled={isEditCourse}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8} largeScreen={8}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'State Audit Performed in'}
                                labelPosition={Position.Top}
                                id={'ddStateAudit'}
                                placeholder={'Select State of Audit'}
                                options={stateList}
                                required={true}
                                value={state?.stateAuditPerformed}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, stateAuditPerformed: data.value});
                                }}
                                disabled={isEditCourse}
                                //onLostFocus={fetchAuditsFromDb}
                            />
                        </Grid.Column>
                        <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8} largeScreen={8}>
                        </Grid.Column>
                    </Grid.Row>
                    {loading ? (<>
                        <Grid.Row columns={3}>
                            <Grid.Column></Grid.Column>
                            <Grid.Column>
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#1559A3"
                                    wrapperStyle={{margin: 'auto', display: 'flex', justifyContent: 'center'}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#464646"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </Grid.Column>
                            <Grid.Column></Grid.Column>
                        </Grid.Row>
                    </>) : addNewAudit ?
                        (intLoad ? (null) : (<>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithDefault
                                        labelText={'Title of Audit'}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtEmail'}
                                        type={'text'}
                                        required={true}
                                        placeholder={'Enter Title of Audit'}
                                        value={state?.titleOfAudit}
                                        onError={titleError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, titleOfAudit: e.target.value})
                                            setTitleError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithDefault
                                        labelText={'Location of Audit'}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtCertificateNumber'}
                                        type={'text'}
                                        required={true}
                                        placeholder={'Enter Location of Audit'}
                                        value={state?.locationOfAudit}
                                        onError={auditLocationError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, locationOfAudit: e.target.value})
                                            setAuditLocationError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={16}>
                                    <span style={{display: 'inline-flex'}}><i>Please provide detailed information for the title and location as this is use for verification.</i></span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={16}>
                                    <span style={{display: 'inline-flex'}}>Is this audit for Transport for NSW project ?</span>
                                </Grid.Column>
                                <Grid.Column width={16}>&nbsp;</Grid.Column>
                                <Grid.Column width={2} mobile={4}>
                                    <NameWithRadioButton
                                        id={'rbYes'}
                                        label={'Yes'}
                                        name={'rbAudit'}
                                        value={rbtVal === 'yes'}
                                        onChange={() => {
                                            setRbtVal('yes')
                                            setState({...state, isTfNSW: true})
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                                <Grid.Column width={2} mobile={4}>
                                    <NameWithRadioButton
                                        id={'rbNo'}
                                        label={'No'}
                                        name={'rbAudit'}
                                        value={rbtVal === 'no'}
                                        onChange={() => {
                                            setRbtVal('no')
                                            setState({...state, isTfNSW: false})
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {rbtVal === 'no' ? null : (<>
                                <Grid.Row columns={1}>
                                    <Grid.Column width={16}>
                                    <span style={{display: 'inline-flex'}}><i>Please refer to the <a
                                        href="#">Guidelines</a> and confirm below</i></span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column width={16}>
                                        {
                                            !isEditCourse && session.status != 4 ? (
                                                <NameWithCheckBox
                                                    id={'cbConfirm'}
                                                    label={'I confirm that this is an Audit for TfNSW project'}
                                                    disabled={isEditCourse}
                                                    value={chkTfNSW}
                                                    onChange={() => {
                                                        setChkTfNSW(!chkTfNSW)
                                                    }}
                                                />
                                            ):(
                                                <NameWithCheckBox
                                                    id={'cbConfirm'}
                                                    label={'I confirm that this is an Audit for TfNSW project'}
                                                    disabled={isEditCourse}
                                                    value={rbtVal === 'yes' ? true : false}
                                                    onChange={() => {
                                                        setChkTfNSW(!chkTfNSW)
                                                    }}
                                                />
                                            )
                                        }
                                    </Grid.Column>
                                </Grid.Row></>)}
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithMuliLine
                                        labelText={'Project Description'}
                                        labelPosition={Position.Top}
                                        id={'txtProDesc'}
                                        type={'text'}
                                        required={true}
                                        placeholder={'Enter max 3000 characters'}
                                        value={state?.projectDescription}
                                        onError={projectDescError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, projectDescription: e.target.value})
                                            setProjectDescError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithMuliLine
                                        labelText={'Project of Scope'}
                                        labelPosition={Position.Top}
                                        id={'txtPurpose'}
                                        type={'text'}
                                        required={true}
                                        placeholder={'Enter max 3000 characters'}
                                        value={state?.purposeOfAudit}
                                        onError={purposeOfAuditError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, purposeOfAudit: e.target.value})
                                            setPurposeOfAuditError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithDefault
                                        labelText={"Client Company/Organization"}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtCCO'}
                                        type={'text'}
                                        required={true}
                                        placeholder={'Enter Company/Organization'}
                                        value={state?.clientCompany}
                                        onError={clientCompanyError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, clientCompany: e.target.value})
                                            setClientCompanyError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                    <span style={{display: 'inline-flex'}}><i>The client is the company/organisation who originated or paid for the road safety audit</i></span>
                                </Grid.Column>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithDefault
                                        labelText={'Client Contact Person'}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtCCP'}
                                        type={'text'}
                                        required={true}
                                        placeholder={'Enter Contact Person Name'}
                                        value={state?.clientContactPerson}
                                        onError={clientContactPersonError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, clientContactPerson: e.target.value})
                                            setClientContactPersonError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithDefault
                                        labelText={"Client's Telephone Number"}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtCTel'}
                                        type={'text'}
                                        required={true}
                                        placeholder={"Enter Client's Telephone Number"}
                                        value={state?.clientContactNumber}
                                        onError={clientContactNumberError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, clientContactNumber: e.target.value})
                                            setClientContactNumberError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithDefault
                                        labelText={"Client's Mobile Number"}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtCMob'}
                                        type={'text'}
                                        required={false}
                                        placeholder={"Enter Client's Mobile Number"}
                                        value={state?.clientMobileNumber}
                                        onError={clientMobileNumberError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, clientMobileNumber: e.target.value})
                                            setClientMobileNumberError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>
                                    <NameWithEmail
                                        labelText={"Client's Email"}
                                        fluid={true}
                                        labelPosition={Position.Top}
                                        id={'txtCEmail'}
                                        type={'email'}
                                        required={true}
                                        placeholder={"Enter Client's Email"}
                                        value={state?.clientContactEmail}
                                        onError={clientContactEmailError}
                                        //eslint-disable-next-line
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setState({...state, clientContactEmail: e.target.value})
                                            setClientContactEmailError('')
                                        }}
                                        disabled={isEditCourse}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8} widescreen={8} mobile={16} computer={8} tablet={8}
                                             largeScreen={8}>

                                </Grid.Column>
                            </Grid.Row>
                            {<Grid.Row>
                                <Grid.Column width={16}>
                                    <div style={{overflow: 'auto'}}>
                                        <RSATable
                                            head={rsaAddAuditorHead}
                                            body={rsaAddAuditorDet}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>}
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    <>
                                        {isAddMember ? (<NameWithDefault
                                            labelText={'Auditor Name'}
                                            fluid={true}
                                            labelPosition={Position.Left}
                                            id={'txtAuditorName'}
                                            type={'text'}
                                            required={false}
                                            placeholder={'Enter Auditor Name'}
                                            value={txtAddMember}
                                            //onError={clientContactPersonError}
                                            //eslint-disable-next-line
                                            onChange={(e: any) => {
                                                e.preventDefault();
                                                setTxtAddMember(e.target.value);
                                                fetchAuditorsList(e.target.value);
                                                if (addMemberError) setAddMemberError(''); // Clear the error message if it exists
                                            }}
                                            // disabled={isEditCourse}
                                        />) : null}
                                        {addMemberError && <span style={{color: 'red', float: 'right'}}>{addMemberError}</span>}
                                    </>
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4} className='button-flex'>
                                    {rsaAuditorDet.length > 0 ? null : !isEditCourse ? (
                                        <OutLineButton id={'btnAddTeam'} className={"background-transaparent"}
                                        style={{width: '150px', height:'36px'}} onClick={() => {
                                        if (isAddMember && !txtAddMember.trim()) {
                                            // If txtAddMember is empty or contains only whitespace, set an error message
                                            setAddMemberError('Auditor Name is required');
                                            return; // Stop the function from proceeding further
                                        }

                                        const isDuplicate = state?.lstAuditParticipantsReq?.some((participant) =>
                                        participant.auditorFullName.trim() === txtAddMember.trim());

                                        if (isAddMember && isDuplicate) {
                                            // Show validation message for duplicate auditor name
                                            setAddMemberError('Auditor with the same name already exists in the list')
                                            return; // Exit the function without adding the duplicate record
                                        }
                                        // If txtAddMember has a valid value, proceed as before
                                        isAddMember ? AddAuditorToAudit(0, txtAddMember, "Auditor") : null;
                                        setIsAddmember(!isAddMember);
                                        setAddMemberError(''); // Clear any existing error messages
                                    }} color={'blue'} text={isAddMember ? 'Add' : 'Add Member'}/>) : null}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={12} mobile={16} computer={12}>
                                    { addMemberError != '' ? (
                                        <span style={{color:'red'}}>{addMemberError}</span>
                                    ):null}
                                </Grid.Column>
                                <Grid.Column width={4} mobile={16} computer={4}></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                {rsaAuditorDet.length > 0 ? (<Grid.Column width={16}>
                                    <div style={{overflow: 'auto'}}>
                                        <RSATable
                                            head={rsaAddAuditorListHead}
                                            body={rsaAuditorDet}
                                        />
                                    </div>
                                </Grid.Column>) : null}
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {state?.addedDate ? (<p>{`Date Added    - ${state?.addedDate}`}</p>) : null}
                                    {state?.modifyDate ? (<p>{`Date Modified -${state?.modifyDate}`}</p>) : null}
                                    {isEditCourse && !(session.userRoleId == 1 || session.userRoleId == 4) ? (<p style={{color:'#CD123D'}}><em>Should you require any changes to be made to the audit, please contact <a href = {`mailto:register@roadsafetyregister.com.au?subject=Request%20for%20the%20details%20change%20in%20Audit%20ID%20${state?.auditId}&body=Dear%20Admin%2C%0A%0AI%20need%20to%20change%20below%20details%20for%20the%20audit%20id%20${state?.auditId}%20%3A%0A%0A%0A`}>register@roadsafetyregister.com.au</a></em></p>) : null}
                                </Grid.Column>
                            </Grid.Row>
                            {!isEditCourse && (session.userRoleId == 1 || session.userRoleId == 4)? (<Grid.Row columns={2}>
                                <Grid.Column></Grid.Column>
                                <Grid.Column style={{display:'flex', justifyContent:'end'}}>
                                    <OutLineButton id={'btnSearch'}
                                                   className={"background-transaparent"}
                                                   style={{width: '350px'}} onClick={ResendEmailTOClientAndAuditor} color={'blue'} text={'Resend email to client contact and auditors'}/>
                                </Grid.Column>
                            </Grid.Row>) : null }
                            <Grid.Row>
                                <Grid.Column>
                                    <hr/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {!isEditCourse && (session.userRoleId == 1 || session.userRoleId == 4) ? (
                                    <AuditAdminLog
                                    temporaryLogs={temporaryLogs}
                                    setTemporaryLogs={setTemporaryLogs}
                                    />) : null }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={12} largeScreen={12} widescreen={12} tablet={11} computer={11}
                                             mobile={10}>
                                     {(!isEditCourse && showDelete) ? (
                                        <div className='button-flex' style={{float:'left'}}>
                                            <OutLineButton id={'btnDelete'} style={{width:'100px'}}
                                            onClick={() => promptDeleteAudit(state?.auditId)} color={'blue'} className={"background-transaparent"} text={'Delete'}/>
                                        </div>
                                     ) : null}
                                </Grid.Column>
                                <Grid.Column width={4} largeScreen={4} widescreen={4} tablet={5} computer={5}
                                             mobile={4}>
                                    {(!isEditCourse && session.status != 4) ? (<ControllButton
                                        cancelOnclick={toggleModel}
                                        saveOnclick={(session.userRoleId == 1 || session.userRoleId == 4) ? SaveAdmin:Save}
                                    />) : null}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={12} largeScreen={12} widescreen={12} tablet={11} computer={11} >
                                    { deleteError != '' ? (
                                        <span style={{color:'red'}}>{deleteError}</span>
                                    ):null}
                                </Grid.Column>
                            </Grid.Row>
                        </>)) : (
                            <>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <p style={{fontSize: '16px', fontWeight: 'bold'}}>Matching audit(s) found in the
                                            Register, you could add yourself using “Add me”:</p>
                                        <hr/>
                                        {pathName == '/audits' && !(state && state.finalSignoffDate && state.finalSignoffDate.length > 0 && state.stageOfAudit && state.stageOfAudit.length > 0 && state.stateAuditPerformed && state.stateAuditPerformed.length > 0) ? (
                                            <Grid.Row columns={1}>
                                                <Grid.Column width={16} className='button-flex'
                                                             style={{display: 'flex', justifyContent: 'end'}}>
                                                    <OutLineButton id={'btnSearch'}
                                                                   className={"background-transaparent"}
                                                                   style={{width: '150px'}} onClick={() => {
                                                        setType('verify')
                                                        setCurrent(0);
                                                        fetchAllAuditsFromDb('verify', 0);

                                                    }} color={'blue'} text={'All Verified Audits'}/>
                                                </Grid.Column>
                                            </Grid.Row>) : null}
                                        <div style={{overflow: 'auto'}}>
                                            <RSATable
                                                head={rsaAuditorHead}
                                                body={rsaAuditDet}
                                                pageCount={pages}
                                                onChange={onChange}
                                                current={current}
                                            />
                                        </div>

                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <hr/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={13} largeScreen={13} widescreen={13} tablet={12} computer={13}
                                                 mobile={16}>
                                        <p style={{fontSize: '16px', fontWeight: 'bold'}}>Otherwise, please continue
                                            adding the details of the new audit using “Add New Audit”.</p>
                                    </Grid.Column>
                                    <Grid.Column width={3} largeScreen={3} widescreen={3} tablet={4} computer={3}
                                                 mobile={16} className='button-flex'>
                                        {session.status != 4 ? (
                                            <OutLineButton id={'btnAddNew'} style={{width: '100px', height: '36px'}}
                                                           className={"background-transaparent"} onClick={() => {
                                                setAddNewAudit(true);
                                                setRSADet([]);
                                                setIntLoad(false)
                                            }} color={'blue'} text={'Add New'}/>) : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        )}
                    {/*<Grid.Row columns={2}>
                        <Grid.Column width={4}>
                            <span style={{display: 'inline-flex'}}><b>Support Documents</b></span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <FileUpload
                                onChange={(e: any) => {
                                    if (resumeValidation(e)) {
                                        console.log('resume validation success')
                                    }
                                }}
                                color={'blue'}/>
                        </Grid.Column>
                    </Grid.Row>*/}
                    <Grid.Row>
                    </Grid.Row>
                </Grid>
            </Container>
            <Grid>
                <Modal
                        open={open2 ? open2 : false}
                        setOpen={setOpen2}
                        title={"Warning !"}
                        modalDescription={true}
                        descriptionHeader={'Are you sure you want to delete this Audit?'}
                        descriptionBody={'This action cannot be undone.'}
                        imageUrl={waringImage}
                        imageSize='small'
                        modalAction={true}
                        ActionButtons={() => (
                            <ControllButtonReg
                                NoClick={() => {
                                    setOpen2(false)
                                    setDelAuditId('')
                                }}
                                YesClick={() => DeleteAuditAdmin()}
                            />
                        )}
                    />
                <br/>
                <Modal
                        open={open3 ? open3 : false}
                        setOpen={setOpen3}
                        title={"Warning !"}
                        modalDescription={true}
                        descriptionHeader={'Are you sure you want to delete this Audit participant?'}
                        descriptionBody={'This action cannot be undone.'}
                        imageUrl={waringImage}
                        imageSize='small'
                        modalAction={true}
                        ActionButtons={() => (
                            <ControllButtonReg
                                NoClick={() => {
                                    setOpen3(false)
                                    setDelAuditAuditorId('')
                                    setDelAuditAuditorName('')
                                }}
                                YesClick={() => deleteAuditor(delAuditAuditorId, delAuditAuditorName)}
                            />
                        )}
                    />
                <br/>
            </Grid>
        </React.Fragment>
    );
};

export default AddNewAudit;

interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonReg = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
