import React, { useState } from "react";
import Header from "../../framework/layouts/Header";
import HomeSlider from "../home/HomeSlider";
import HomeSliderBottom from "../home/HomeSliderBottom";
import Footer from "../../framework/layouts/Footer";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import { Accordion, Container, Grid, Icon } from "semantic-ui-react";
import FaqAccordions from "./FaqAccordions";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";



//This is the FAQ page. 
//Modified by Shane W
//13-03-2023
//<Accordion> and <AccordionItem> is needed in this section. 
//However our senamtic-ui-react version doesn't have <AccordionItem>. Besides using npm install may have dependency issue. 
//To solve this, react-accessible-accordion is added using Yarn instead of npm.  






const Faqs = () => {


  return (

    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={
          process.env.REACT_APP_RECAPTCHA_KEY
            ? process.env.REACT_APP_RECAPTCHA_KEY
            : ""
        }
      >
        <Header>
          <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px' }}>
            <InnerTitleBar
              title={'FAQs'}
              usreName={''}
              userRole={""}
              lastLogin={''}
              status={''}
            />
            <FaqAccordions activeIndex={0} />
          </Container>
        </Header>

        <Footer />
      </GoogleReCaptchaProvider>
    </>
  )

}
export default Faqs;