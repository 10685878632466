import {Dropdown} from "semantic-ui-react";
import React from "react";

//eslint-disable-next-line
const SubMenu = (subMenu:any, menuName:string) => {
    return (
        <Dropdown text={menuName} pointing >
            <Dropdown.Menu>
                {//eslint-disable-next-line
                subMenu.map((item: any, index: number) => (
                    <Dropdown.Item key={index} {...item}  />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default SubMenu;