/*
 *################################################################################
 *Module Name                   	: Dropdowns.tsx             				 #
 *Purpose of the Module         	: To Show dropdown element         			 #
 *Description of the Module    	    : This element  act like dropdown      		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/08/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
import React, {useEffect} from "react";
import {Dropdown} from 'semantic-ui-react'
import parse from 'html-react-parser'

//Import DropDownProps
import DropDownProps from "../../types/DropDownProps";


/**
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * placeholder = If you need to show  text of the element should be string    eg:- placeholder={'Select Course'}
 *
 * options = Is the dropdown list of the element should be object array eg:- [{ key:'1', text:'Road Safety Audit', value:'rsa'}, { key:'2', text:'Audit Leadership', value:'al'}].
 *
 * search - If you need search option to the element should be boolean. eg: search={true}
 *
 * selection - If you need outline visible with selection effect to the element should be boolean. eg: selection={true}
 *
 * fluid - If you need element fill with the content width. Should be boolean. eg: fluid={true}
 *
 * multiple - If you need to enable multiple selection to the element should be boolean. eg: multiple={true}
 *
 * clearable - If you need to enable clearable option to the selected value of the element should be boolean. eg clearable={true}
 *
 */

const Dropdowns = ({
                       id,
                       placeholder,
                       options,
                       search,
                       selection,
                       fluid,
                       multiple,
                       clearable,
                       value,
                       onChange,
                       defaultValue,
                       disabled,
                       onLostFocus,
                       allowAdditions,
                       onAddItem,
                       onerror
                   }: DropDownProps) => {
    const [dd, setDd] = React.useState<string>('');
    useEffect(()=>{
        setDd(onerror? onerror : '');
    },[onerror]);
    useEffect(() => {
        const element = document.getElementById(id) as HTMLInputElement;
        if(element){
            if(dd.length > 0){
                element.style.borderColor = '#CD123D'
            }else{
                //element.style.borderColor = '#e0e0e0'
                element.style.borderColor = '#969696'
            }
        }
    }, [dd])
    return (
        <>
            <Dropdown
                id={id}
                // clearable={clearable ? clearable : false}
                clearable={false}
                placeholder={placeholder}
                search={search ? search : false}
                selection={selection ? selection : false}
                fluid={fluid ? fluid : false}
                multiple={multiple ? multiple : false}
                options={options}
                value={value}
                onChange={onChange}
                defaultValue={defaultValue}
                disabled={disabled}
                onBlur={onLostFocus}
                allowAdditions={allowAdditions}
                onAddItem={onAddItem}
            />
            <span className='email-validation'>{parse(dd)}</span>
        </>
    );
}

export default Dropdowns