import React, { useEffect, useState } from "react";
import Header from "../../framework/layouts/Header";
import { Oval } from "react-loader-spinner";
import Footer from "../../framework/layouts/Footer";
import PublicSearch from "./PublicSearch";
import {
    fetchStates,
} from "../auditor/profile/ProfileController";
import { AuditorRegionValueProps } from "../auditor/profile/DataModal";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Searh = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [states, setStates] = useState<any>();
    const [focusMode, setFocusMode] = React.useState('pending');
    const [stateRegion, setStateRegion] = React.useState<AuditorRegionValueProps>();

    useEffect(() => {
        setStates(fetchStates());
        // console.log("Fetch states called from src/pages/search/index");
    }, []);
    return (
        <React.Fragment>
            <GoogleReCaptchaProvider
                reCaptchaKey={
                    process.env.REACT_APP_RECAPTCHA_KEY
                        ? process.env.REACT_APP_RECAPTCHA_KEY
                        : ""
                }
            >
                <Header>
                    {!loading ? (
                        <PublicSearch
                            states={states}
                            focusMode={focusMode}
                            setFocusMode={setFocusMode}
                            setStateRegion={setStateRegion}
                        />
                    ) : (
                        <div style={{ height: '40vh', marginTop: '30vh', paddingLeft: '50vw' }}>
                            <Oval
                                height={80}
                                width={80}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}

                            /></div>
                    )}
                </Header>
                <Footer />
            </GoogleReCaptchaProvider>
        </React.Fragment>
    )
};

export default Searh;