import React from "react";
import {Grid, List} from "semantic-ui-react";
import {IMSGPanel} from "../../types/IMSGPanel";
import IconButton from "../../components/Buttons/IconButton";
import IconOutlineButton from "../../components/Buttons/IconOutlineButton";
import IconCircleOutlineButton from "../../components/Buttons/IconCircleOutlineButton";
import IconCircleButton from "../../components/Buttons/IconCircleButton";
import parse from 'html-react-parser'
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons/faTrashCan";


const MSGPanel = (props: IMSGPanel) => {
    return (
        <React.Fragment>
            <Grid style={{maxHeight:'250px', overflow:'auto'}}>
                {props.Lines.map((line, index) => (
                    <Grid.Row key={index} columns={3}>
                        {line.Title ? (<Grid.Column width={3} widescreen={3} tablet={10} computer={3} mobile={10}>
                            {moment(line.Title).format('DD/MM/YYYY hh:mm:ss A')}
                        </Grid.Column>):null}
                        <Grid.Column width={11} mobile={10}>
                            {parse(line.Description)}
                        </Grid.Column>
                        {line.Delete ? (<Grid.Column width={2} style={{display:'flex', justifyContent:'end'}}>
                            <FontAwesomeIcon title={'Remove'} icon={faTrashCan}  onClick={line.Delete} style={{cursor:'pointer', fontWeight:'lighter'}}/>
                            &nbsp;&nbsp;
                            {/*<IconCircleButton id={'btnDelete'} onClick={line.Delete} color={'red'} icon='delete'/> {/*icon={faTrashCan}*/}
                        </Grid.Column>) : null}
                    </Grid.Row>
                ))}
            </Grid>
        </React.Fragment>
    )
}

export default MSGPanel


{/*
 <List divided relaxed>
                            {props.Lines.map((line,index)=>(
                                <List.Item key={index}>
                                    <List.Content>
                                        <List.Header as='a'>{line.Title}</List.Header>
    <List.Description as='a'>{line.Description}</List.Description>
</List.Content>
</List.Item>
))}
</List>
*/
}

