
//Import url from config file
import ApiCallAxio from "../../framework/common/apiCallAxio";
import APICallMethods from "../../framework/types/APICallMethods";

import { TrainingCoursesRes } from "../../models/apiResponce/Courses/TrainingCourseRes";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the contact logs.
const fetchTrainingCourses= async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Courses/GetAllCoursesTrainingLinks`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objresult:TrainingCoursesRes[] = result.coursesTrainingLinkRess; //store the result in userTitles array
    return objresult;//return the array
}


export {fetchTrainingCourses};