import React, {useEffect, useState} from "react";
import Header from "../../../framework/layouts/Header";
import PersonalDetails from "../registration/PersonalDetails";
import Footer from "../../../framework/layouts/Footer";
import ProfileContainer from "./ProfileContainer";
import {
    fetchTitle,
    fetchCourseProviders,
    fetchCountries,
    fetchCourses,
    fetchStates,
    fetchAuditStages, fetchAuditor, fetchAuthRegi, fetchContactMethod, fetchCompanies
} from "./ProfileController";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import {AuditorProfile} from "./DataModal";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import Modals from "../../../framework/components/Modals";

const Profile = () => {
    const [titles, setTitles] = useState<any>();
    const [providers, setProviders] = useState<any>();
    const [countries, setCountries] = useState<any>();
    const [company, setCompany] = useState<any>()
    const [course, setCourse] = useState<any>();
    const [states, setStates] = useState<any>();
    const [contactMethod, setContactMethod] = useState<any>();
    const [auditStages, setAuditStages] = useState<any>();
    const [auditor, setAuditor] = useState<any>();
    let session = getSessionCookie();
    const [loading, setLoading] = useState(false);
    let getAuditor:any = null;
    const encid = new URLSearchParams(location.search).get('get')


    useEffect(() => {
        setLoading(true);
        if(encid != null){
            const id = atob(encid)
            getAuditor = fetchAuditor(parseInt(id),0);
        }else if(!sessionStorage.getItem('tempUser')){
            getAuditor = fetchAuditor(session.auditorId,0);
        }else{
            const tempUser = sessionStorage.getItem('tempUser');
            //alert(tempUser);
            if(tempUser && tempUser.includes('#')){
                const splitted = tempUser.split('#');
                getAuditor = fetchAuthRegi(parseInt(splitted[0]),splitted[1]);
                session = getSessionCookie();
                console.log('session',session);
            }
        }
        Promise.resolve(getAuditor).then((auditor:any) => {
            setAuditor(auditor);
            setLoading(false);
        });

        setTitles(fetchTitle());
        setProviders(fetchCourseProviders());
        setCountries(fetchCountries());
        setCompany(fetchCompanies());
        setCourse(fetchCourses());
        setStates(fetchStates());
        setContactMethod(fetchContactMethod());
        setAuditStages(fetchAuditStages());


    }, []);

  return (
      <React.Fragment>
          <Header>
              <ProfileContainer
                    title={titles}
                    provider={providers}
                    country={countries}
                    company={company}
                    course={course}
                    session={session}
                    states={states}
                    contactMethods={contactMethod}
                    auditStages={auditStages}
                    auditor={auditor}
                    setAuditor={setAuditor}
              />
              <Modals
                  open={loading}
                  basicModal={true}
                  modalSize={'small'}
                  imageSize={'tiny'}
                  imageUrl={'https://images.emojiterra.com/google/noto-emoji/v2.034/128px/23f3.png'}
                  setOpen={setLoading}
                  modalDescription={true}
                  descriptionHeader={'Please wait ...!'}
                  descriptionBody={'Your data is loading ...'}
                  modalDimmer={"blurring"}
                  modalAction={false}
              />
          </Header>
          <Footer/>
      </React.Fragment>
  );
};

export default Profile;