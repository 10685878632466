//Import url from config file
import ApiCall from "./apiCall";
import APICallMethods from "../types/APICallMethods";
import {loginRequest} from "../../models/common/login";
import xhrCall from "./xhrCall";
import Cookies from "js-cookie";
import ApiCallAxio from "./apiCallAxio";
import AuditorStatus from "./AuditorStatus";


const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const SignIn = async (data: loginRequest) => {
    //xhrCall();
    //return ;
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Users/signin`; //complete url
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: JSON.stringify(data),
    }); //call the api using ApiCall method

    //console.log(result)

    return result;//return the result
}

const ForgotPassword = async (data: loginRequest) => {
    //xhrCall();
    //return ;
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Users/forgetpassword`; //complete url
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: JSON.stringify(data),
    }); //call the api using ApiCall method

    //console.log(result)

    return result;//return the result
}

const SignOut = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Users/signoff`; //complete url
    JSON.parse(await ApiCall({url: `${url}`, method: APICallMethods.POST})); //call the api using ApiCall method
    return true;//return the result
}

interface AuditorL{
    status:number,
    statusName:string
    auditorNewId:string,
    auditorLevel:string,
    certExpDate:string
}

const LoadAuditor=async (result:any) =>{
    let returnRes: AuditorL = {} as AuditorL;
    if (result.user != undefined) {
        const url2 = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorProfile`; //complete url
        const AuditorData = {
            auditorId: result.user.auditorId,
            countryStateId: 1,
            pageSize: 1,
            pageNumStartingZero: 1
        }
        const result2 = await ApiCallAxio({
            url: `${url2}`,
            method: APICallMethods.POST,
            data: JSON.stringify(AuditorData),
        });
       // console.log('Auditor', result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes.auditorStatusId)
        returnRes = {
            status: result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes != null ? result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes.auditorStatusId : 0,
            statusName: AuditorStatus(result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes != null ? result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes.auditorStatusId : 0),
            auditorNewId:result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.newAuditorId,
            auditorLevel:result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes.auditorLevel,
            certExpDate:result2.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes.certExpiryDate
        }
    }
    //console.log(returnRes)
    return returnRes
}

export {
    SignIn
    , SignOut
    , LoadAuditor
    ,ForgotPassword
};