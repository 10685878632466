/*
 *################################################################################
 *Module Name                   	: Toast.tsx                 				 #
 *Purpose of the Module         	: To Show toast element         			 #
 *Description of the Module    	    : This element  act like toast      		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 12/01/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
import React from 'react';
import {toast, ToastContainer} from "react-toastify";
import ToastProps from "../../types/ToastProps";
import notify from "./index";
import POSITION = toast.POSITION;

const ErrorToast = (message: string) => {
    notify({
        type: 'error',
        color: 'colored',
        position: POSITION.TOP_RIGHT,
        message: message,
    })
}

export default ErrorToast;