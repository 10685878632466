import React, {useState} from 'react'
import {Grid} from "semantic-ui-react";
import TableWithBackground from "../../../framework/widgets/TableWithBackgrounds";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import profile from "./index";
import {getSessionCookie} from "../../../framework/common/SessionHandler";

interface Props{
    setChkAgree:any
}
const Disclaimer = (prop:Props) => {
    const [chkAgree, SetAgree] = useState(false)
    const session = getSessionCookie();
    const pathName = window.location.pathname;
    return (
        <React.Fragment>
            <div className="ui center aligned icon header red-title">
                <h1 className="ui center aligned icon header red-title" >Declaration</h1>
            </div>
            <div>
                <p>
                    You expressly warrant that the information provided above is true and correct to the best of your knowledge and belief at the time of submission.
                    { pathName == '/profile' && (session.status == 3 || session.status == 4 )? ' ' : (' You acknowledge that this information will be relied upon by assessors on this Register to assign your accreditation, by prospective project sponsors to select and/or confirm appropriate audit team members for a road safety audit, and by members of the public. ')}
                    You acknowledge that you are aware of the implications of providing false or incorrect information recklessly, negligently or knowingly. You acknowledge that the above has the effect of and can be relied upon as a legal representation.
                </p>
                <label className="checkbox-container">I agree that the information provided above is true and correct to the best of my knowledge and belief at the time of submission.
                 {/* otherwise click on Cancel. */}
                    <input id={'chkAgree'} type={'checkbox'} checked={chkAgree} onChange={() => {
                        SetAgree(!chkAgree)
                        prop.setChkAgree(!chkAgree)
                    }}/>
                    <span className="checkmark"></span>
                </label>
                {/*<NameWithCheckBox  id={'chkAgree'} name={'agreement'} label={'I agree that the information provided above is true and correct to the best of my knowledge and belief at the time of submission otherwise click on Cancel.'} value={chkAgree} onChange={()=>{*/}
                {/*    SetAgree(!chkAgree)*/}
                {/*    prop.setChkAgree(!chkAgree)*/}
                {/*}}/>*/}
            </div>

            <br/>

        </React.Fragment>
    )
}

export default Disclaimer