import React from "react";
import TextBoxes from "./index";
import DefaultTextBoxProps from "../../types/DefaultTextBoxProps";
import {decimalValidator} from "../../validators/textFeildValidators";

const DecimalTextBox = (props:DefaultTextBoxProps)=>{
    const [number, setNumber] = React.useState<string>('');
    const validator = ()=>{
        const enterValue = document.getElementById(props.id) as HTMLInputElement;
        if(!decimalValidator(enterValue?.value)){
            setNumber('Please enter a valid decimal number');
        }else{
            setNumber('');
        }
    }
    return(
        <>
            <TextBoxes
                id={props.id}
                type={'text'}
                placeholder={props.placeholder}
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                onChange={props.onChange}
                onClick={props.onClick}
                onLostFocus={()=>{
                    validator();
                    props.onLostFocus;
                }}
                onKeydown={props.onKeydown}
                value={props.value}
                fluid={props.fluid}
            />
            <span className='email-validation'>{number}</span>
        </>
    )
}

export default DecimalTextBox