import React from "react";
import {Container, Grid} from "semantic-ui-react";
import {toast, ToastContainer} from "react-toastify";
import ErrorToast from "../../framework/components/Toast/ErrorToast";
import WarningToast from "../../framework/components/Toast/WarningToast";
import SuccessToast from "../../framework/components/Toast/SuccessToast";
import InfoToast from "../../framework/components/Toast/InfoToast";
import PromiseToast from "../../framework/components/Toast/PromiseToast";


const SampleToast = () => {
    return (
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>
                        <ToastContainer
                            hideProgressBar={true}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>Toast Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <div style={{textAlign:"left", backgroundColor:'#464646'}}>
                                <code>{"import ErrorToast from \"../../framework/components/Toast/ErrorToast\";\n"}</code>
                                <br/>
                                <code>{"import WarningToast from \"../../framework/components/Toast/WarningToast\";\n"}</code>
                                <br/>
                                <code>{"import SuccessToast from \"../../framework/components/Toast/SuccessToast\";\n"}</code>
                                <br/>
                                <code>{"import InfoToast from \"../../framework/components/Toast/InfoToast\";\n"}</code>
                                <br/>
                                <code>{"import PromiseToast from \"../../framework/components/Toast/PromiseToast\";"}</code>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <button onClick={() => {
                                SuccessToast("Save Success !")
                            }}>Click me
                            </button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is success toast with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<button onClick={()=>{\n" +
                                    "                                SuccessToast(\"Save Success !\")\n" +
                                    "                            }} >Click me</button>"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <button onClick={() => {
                                InfoToast("Sample Toast Page !")
                            }}>Click me
                            </button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is info toast with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<button onClick={()=>{\n" +
                                    "                                InfoToast(\"Sample Toast Page !\")\n" +
                                    "                            }} >Click me</button>"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <button onClick={() => {
                                WarningToast("This will remove unsaved records !")
                            }}>Click me
                            </button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is warning toast with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<button onClick={()=>{\n" +
                                    "                                WarningToast(\"This will remove unsaved records !\")\n" +
                                    "                            }} >Click me</button>"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <button onClick={() => {
                                ErrorToast("This will delete unsaved records !")
                            }}>Click me
                            </button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is error toast with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<button onClick={()=>{\n" +
                                    "                                ErrorToast(\"This will delete unsaved records !\")\n" +
                                    "                            }} >Click me</button>"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <button onClick={() => {
                                PromiseToast("Saving...", "Save Success !", "Save Failed !")
                            }}>Click me
                            </button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is promise toast </p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<button onClick={()=>{\n" +
                                    "                                PromiseToast(\"Saving...\",\"Save Success !\",\"Save Failed !\")\n" +
                                    "                            }} >Click me</button>"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleToast