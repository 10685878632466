import React, { useCallback, useState } from 'react';
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import ContactUsText from './ContactUsText';
import ContactUsForm from './ContactUsForm';
import InnerTitleBar from '../../framework/layouts/InnerTitleBar';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Recaptcha from '../../framework/common/Recaptch';
import {Container} from "semantic-ui-react";

const Contactus = () => {
    

    return (
        <>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ""}>
                <Header>
                    <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
                        <InnerTitleBar
                            title={'Contact us'}
                            usreName={''}
                            userRole={""}
                            lastLogin={''}
                            status={''}
                        />
                        <ContactUsText>
                            <ContactUsForm />
                        </ContactUsText>
                    </Container>
                </Header>
                <Footer />
            </GoogleReCaptchaProvider>
        </>

    )

}

export default Contactus