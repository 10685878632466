import React from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import {Container, Grid, Image} from "semantic-ui-react";
import InternalBG from "../../assets/images/backgrounds/internal-pages.jpg";
import NameWithDropDown from "../../framework/widgets/NameWithDropDown";
import Position from "../../framework/types/Position";
import NameWithDefault from "../../framework/widgets/NameWithText/NameWithDefault";
import NameWithEmail from "../../framework/widgets/NameWithText/NameWithEmail";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import DefaultButton from "../../framework/components/Buttons/DefaultButton";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";

const RegiAuthThanks =()=>{
    return (
        <>
            <Header>
                <InnerTitleBar
                    title={'Thank you for Your Registration!'}
                    usreName={''}
                    userRole={""}
                    lastLogin={''}
                    status={''}
                />
                <Container className='reg-thank' fluid={true} style={{marginBottom: '50px'}}>
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={12}>
                                <h1></h1>
                                <p style={{fontSize: '18px'}}>Thank you for registering with the NSW Register of Road Safety Auditors. Your account has been created and a verification email has been sent to your email address. Please click on the verification link included in the email to activate your account to proceed with the registration to become a Road Safety Auditor.</p>
                                <p style={{fontSize: '18px'}}>Your account will not be activated until you verify your email address.</p>
                                <p style={{fontSize: '18px', color:'red'}}><em>Please note that it may take up to 30 minutes for the verification email to be received</em></p>
                                <p style={{fontSize: '18px'}}>Having trouble accessing your account? Please <a href={'/contactus'} target={'blank'} style={{color:'#29599e', fontWeight:'bold'}}>contact us</a></p>
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Container>
            </Header>
            <Footer/>

        </>
    )
}

export default RegiAuthThanks