import React, {useState} from "react";
import Modals from "../../framework/components/Modals";
import {Container, Grid, Modal} from "semantic-ui-react";
import Buttons from "../../framework/components/Buttons";
import SampleButtons from "./SampleButtons";


const SampleModals =()=>{
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    return(
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>Modals Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <code>{"import Buttons from \"../../framework/components/Modals\";"}</code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Buttons id={'btnShow'} onClick={()=>{setOpen(true)}} color='blue' text={'Show Modal'}/>
                            <Modals
                                open={open}
                                title={'Warning ! '}
                                modalSize='fullscreen'
                                imageSize={'tiny'}
                                imageUrl={'https://images.emojiterra.com/twitter/v14.0/512px/26a0.png'}
                                setOpen={setOpen}
                                modalDescription={true}
                                descriptionHeader={'Are you sure want to delete ?'}
                                descriptionBody={'This will delete selected document.'}
                                modalDimmer={'blurring'}
                                modalAction={true}
                                ActionButtons={ButtonSet}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard modal.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                                <code>
                                    {"<Modals\n" +
                                        "                                    open={open}\n" +
                                        "                                    title={'Warning !'}\n" +
                                        "                                    modalSize={'fullscreen'}\n" +
                                        "                                    imageSize={'tiny'}\n" +
                                        "                                    imageUrl={'https://images.emojiterra.com/twitter/v14.0/512px/26a0.png'}\n" +
                                        "                                    setOpen={setOpen}\n" +
                                        "                                    modalDescription={true}\n" +
                                        "                                    descriptionHeader={'Are you sure want to delete ?'}\n" +
                                        "                                    descriptionBody={'This will delete selected document.'}\n" +
                                        "                                    modalDimmer={\"blurring\"}\n" +
                                        "                                    modalAction={true}\n" +
                                        "                                    ActionButtons={ButtonSet} // this is a widget or  button module \n" +
                                        "                                />  "}
                                </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Buttons id={'btnShow'} onClick={()=>{setOpen1(true)}} color='blue' text={'Show Modal'}/>
                            <Modals
                                open={open1}
                                basicModal={true}
                                modalSize='small'
                                imageSize={'tiny'}
                                title={' '}
                                imageUrl={'https://images.emojiterra.com/twitter/v14.0/512px/26a0.png'}
                                setOpen={setOpen1}
                                modalDescription={true}
                                descriptionHeader={'Are you sure want to delete ?'}
                                descriptionBody={'This will delete selected document.'}
                                modalDimmer={'dimmer'}
                                modalAction={true}
                                ActionButtons={ButtonSet}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic modal.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<Modals\n" +
                                    "                                    open={open}\n" +
                                    "                                    title={' '}\n" +
                                    "                                    basicModal={true}\n" +
                                    "                                    modalSize={'small'}\n" +
                                    "                                    imageSize={'tiny'}\n" +
                                    "                                    imageUrl={'https://images.emojiterra.com/twitter/v14.0/512px/26a0.png'}\n" +
                                    "                                    setOpen={setOpen}\n" +
                                    "                                    modalDescription={true}\n" +
                                    "                                    descriptionHeader={'Are you sure want to delete ?'}\n" +
                                    "                                    descriptionBody={'This will delete selected document.'}\n" +
                                    "                                    modalDimmer={\"blurring\"}\n" +
                                    "                                    modalAction={true}\n" +
                                    "                                    ActionButtons={ButtonSet} // this is a widget or  button module \n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Buttons id={'btnShow'} onClick={()=>{setOpen2(true)}} color='blue' text={'Show Modal'}/>
                            <Modals
                                open={open2}
                                title={' '}
                                modalSize='fullscreen'
                                setOpen={setOpen2}
                                modalDescription={false}
                                ModalBody={SampleButtons}
                                modalDimmer={'blurring'}
                                modalAction={false}
                                ActionButtons={ButtonSet}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is a modal with external module.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<Modals\n" +
                                    "                                    open={open}\n" +
                                    "                                    title={' '}\n" +
                                    "                                    modalSize={'fullscreen'}\n" +
                                    "                                    setOpen={setOpen}\n" +
                                    "                                    modalDescription={false}\n" +
                                    "                                    ModalBody={SampleButtons} // this should be a component or a widget \n" +
                                    "                                    modalDimmer={\"blurring\"}\n" +
                                    "                                    modalAction={false}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleModals

const ButtonSet=()=>{
    return(
        <>
            <Buttons id={'btnDelete'} onClick={()=>{alert('Ok')}} color='red' text={'Cancel'}/>
            <Buttons id={'btnSave'} onClick={()=>{alert('Deleted')}} color='green' text={'Deleted'}/>
        </>
    )
}