import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV === "production") {
    //eslint-disable-next-line
    console.log = () => {};
    //eslint-disable-next-line
    console.debug = () => {};
    //eslint-disable-next-line
    console.info = () => {};
    //eslint-disable-next-line
    console.warn = () => {};
}

//Initialize GA4
ReactGA.initialize("G-8Q9KGWKC9T");

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

const SendAnalytics = ()=> {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
