import React, {useEffect} from "react";
import TextBoxes from "./index";
import DefaultTextBoxProps from "../../types/DefaultTextBoxProps";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

const DateRangeBox = (props:DefaultTextBoxProps)=>{
    const [text, setText] = React.useState<string>('');
    useEffect(() => {
        //console.log('EmailInputBox', props.onerror);
        setText(props.onerror ? props.onerror : '');
    }, [props.onerror]);
    useEffect(() => {
        const element = document.getElementById(props.id) as HTMLInputElement;
        if(element){
            if(props.value.length > 0){
                element.style.borderColor = '#969696'
            }else{
                element.style.borderColor = '#969696'
            }
        }
    }, [props.value])
    return(
        <>
            <DatesRangeInput
                id={props.id}
                dateFormat={'DD/MM/YYYY'}
                type={'text'}
                placeholder={props.placeholder}
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                onChange={props.onChange}
                onClick={props.onClick}
                onLostFocus={props.onLostFocus}
                onKeydown={props.onKeydown}
                value={props.value}
                fluid={props.fluid}
                OnBlur
            />
            <span className='email-validation'>{text}</span>
        </>

    )
}

export default DateRangeBox