import React from "react";
import {Container, Image} from "semantic-ui-react";

//Import images
import Content1 from '../../assets/images/tfnsw-1.webp'
import Content2 from '../../assets/images/tfnsw-2.webp'

//Import components
import Carousel from "../../framework/components/Carousel";
import CarouselItem from "../../framework/components/Carousel/CarouselItem";

const MiddleLeftTripo = () => {

    return (
        <>
            <br/>
            <Container fluid={true} className='carousel-image-container'>
                <div className='middle-left-trapezoid'>
                </div>
                <div className='middle-left-trapezoid-inner-image'>
                    <Image src={Content1} className='home-content-1'/>
                    <div className='home-content-text'>
                        <p className='home-content-title'>Benefits for Auditors </p>
                        <p className='home-content-subtitle'>Joining this register </p>
                        <Carousel>
                            <CarouselItem>Demonstrating their experience and capability to perform road safety
                                audits.</CarouselItem>
                            <CarouselItem>Indicating professional development status.</CarouselItem>
                            <CarouselItem>Providing them with recognition by Transport for New South Wales and its
                                agencies.</CarouselItem>
                            <CarouselItem>Elevating the status of road safety auditors on projects.</CarouselItem>
                        </Carousel>
                    </div>
                </div>
            </Container>
            <Container fluid={true} style={{height: '500px', paddingTop: '40px'}}>
                <div className='middle-right-trapezoid'>
                </div>
                {<div className='middle-right-trapezoid-inner-image'>
                    <Image src={Content2} className='home-content-2'/>
                    <div className='home-content-text-2'>
                        <p className='home-content-title'>Benefits for Businesses</p>
                        <p className='home-content-subtitle'>Using this register </p>
                        <Carousel>
                            <CarouselItem>Indicating a road safety auditor&apos;s experience, capability and
                                accreditation level.</CarouselItem>
                            <CarouselItem>Validating the status of a road safety auditor&apos;s
                                accreditation.</CarouselItem>
                            <CarouselItem>Enabling them to locate road safety auditors based on geographical
                                region.</CarouselItem>
                            <CarouselItem>Providing a reference tool on road safety auditing for Transport for New South
                                Wales and its agencies.</CarouselItem>
                        </Carousel>
                    </div>
                </div>}
            </Container>
            <br/>
        </>
    );

}

export default MiddleLeftTripo