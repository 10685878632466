import React, {Fragment, useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import RSATable from "../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../framework/types/TableHeader";
import {RoadSafetyAuditProps, RoadSafetyCoursesProps} from "../auditor/profile/DataModal";
import tags from "../../models/common/tag";
import {PaginationProps} from "antd";
import {fetchAuditorSearch} from "./SearchController";

interface Props {
    Audits?: RoadSafetyAuditProps[],
    Pages?: number
}

const RSAudits = ({Audits, Pages}: Props) => {
    const rsAuditHead: TableHeader[] = [
        {
            text: "Sign off Date"
        },
        {
            text: "Title"
        },
        {
            text: "Leader"
        },
        {
            text: "Stage"
        }
    ];
    const [rsAuditDet, setRSAuditDet] = useState<any>([])
    const [current, setCurrent] = useState(1);
    const [pages, setPages] = useState(1)
    useEffect(() => {
        SetValues(Audits)
        setPages(Pages ? Pages : 1)
    }, [])

    const SetValues = (Auditslist?: RoadSafetyAuditProps[]) => {
        //eslint-disable-next-line
        let tempAudit: any = []
        if (Auditslist) {
            Auditslist.map((r) => {
                if (r.auditStatusId == 3) {
                    tempAudit.push(
                        [
                            {
                                tag: tags.text,
                                text: r.finalSignoffDate,
                                icon: "",
                                onclick: null,
                                width: '150px'
                            },
                            {
                                tag: tags.text,
                                text: r.titleOfAudit,
                                icon: "",
                                onclick: null
                            },
                            {
                                tag: tags.text,
                                text: r.teamLeaderName,
                                icon: "",
                                onclick: null
                            },
                            {
                                tag: tags.text,
                                text: r.stageOfAudit,
                                icon: "",
                                onclick: null
                            }
                        ]
                    );
                }
            })
        }
        setRSAuditDet(tempAudit)
    }
    const onChange: PaginationProps['onChange'] = (page) => {
        const encid = new URLSearchParams(location.search).get('get')
        const id = encid ? atob(encid) : '0'
        const reload = fetchAuditorSearch(parseInt(id), page - 1);
        Promise.resolve(reload).then((value) => {
            //eslint-disable-next-line
            //debugger;
            SetValues(value.roadSafetyAudit);
        });
        setCurrent(page);
    };

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <h3 style={{display: 'inline-flex'}}>Road Safety Audits Completed</h3>
                    </Grid.Column>
                </Grid.Row>
                {rsAuditDet.length > 0 ? (<Grid.Row>
                    <div id={'rsaAudits'} style={{minWidth:'1000px', overflow:'auto', width:'100%'}}>
                    <RSATable head={rsAuditHead} body={rsAuditDet} tableHeadColored={'blue'} pageCount={pages}
                              onChange={onChange} current={current}/>
                    </div>
                </Grid.Row>) : (
                    <Grid.Row columns={3}>
                        <Grid.Column></Grid.Column>
                        <Grid.Column>No audits found for this
                            auditor</Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </React.Fragment>
    );
}

export default RSAudits