import React from "react";
import {Tab} from "semantic-ui-react";
import {ITabPaneRender} from "../../types/TabPaneInterface";

const TabPane = (props:ITabPaneRender) =>{
    return(
        <Tab.Pane attached={props.attached}>
            <props.body/>
        </Tab.Pane>
    )
}

export default TabPane