/*
 *################################################################################
 *Module Name                   	: Toast.tsx                 				 #
 *Purpose of the Module         	: To Show toast element         			 #
 *Description of the Module    	    : This element  act like toast      		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/10/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */

import React from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastProps from "../../types/ToastProps";
import parse from 'html-react-parser'


/**
 *     type:string, //success, error, warn,info,custom,promise,default
 *
 *     color?:Theme, //"light" | "dark" | "colored"
 *
 *     position?: ToastPosition, //top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left"
 *
 *     icon?:string, //Emoji like - ✅
 *
 *     className?:ToastClassName // css class name
 *
 *     promiseObject?:Promise<any>, //new Promise(resolve => setTimeout(resolve, 5000))
 *
 *     message?:string, //Default Notification !
 *
 *     pendingMessage?:string, //Promise is pending
 *
 *     successMessage?:string, //Promise is resolved
 *
 *     errorMessage?:string //Promise is rejected
 *
 *
 * Sample Codes
 *
 *         toast("Default Notification !");
 *
 *         toast.success("Success Notification !", {
 *             position: toast.POSITION.TOP_CENTER
 *         });
 *
 *         toast.success("Success Notification !", {
 *             position: toast.POSITION.TOP_CENTER,
 *             theme: "colored"
 *         });
 *
 *         toast.error("Error Notification !", {
 *             position: toast.POSITION.TOP_LEFT
 *         });
 *
 *         toast.error("Error Notification !", {
 *             position: toast.POSITION.TOP_LEFT,
 *             theme: "colored"
 *         });
 *
 *         toast.warn("Warning Notification !", {
 *             position: toast.POSITION.BOTTOM_LEFT
 *         });
 *
 *         toast.warn("Warning Notification !", {
 *             position: toast.POSITION.BOTTOM_LEFT,
 *             theme: "colored"
 *         });
 *
 *         toast.info("Info Notification !", {
 *             position: toast.POSITION.BOTTOM_CENTER,
 *             theme: "colored"
 *         });
 *         toast.info("Info Notification !", {
 *             position: toast.POSITION.BOTTOM_CENTER,
 *         });
 *
 *         toast("Custom Style Notification with css class!", {
 *             position: toast.POSITION.BOTTOM_RIGHT,
 *             className: 'foo-bar',
 *             icon:"✅",
 *             theme: "colored"
 *         });
 *         toast.promise(
 *             resolveAfter3Sec,
 *             {
 *                 pending: 'Promise is pending',
 *                 success: 'Promise resolved 👌',
 *                 error: 'Promise rejected 🤯'
 *             }
 *         )
 *
 */
    const notify = ({type,color,position,icon,className,promiseObject,message,pendingMessage,successMessage,errorMessage,autoClose}:ToastProps) => {
        switch (type){
            case 'success' :
                toast.success(message, {
                    position: position,
                    icon:icon,
                    theme: color,
                    className:className,
                    autoClose:autoClose ? autoClose :false
                });
                break;
            case 'error':
                toast.error(message, {
                    position: position,
                    icon:icon,
                    theme: color,
                    className:className,
                    autoClose:autoClose ? autoClose :false
                });
                break;
            case 'warn':
                toast.warn(message, {
                    position: position,
                    icon:icon,
                    theme: color,
                    className:className,
                    autoClose:autoClose ? autoClose :false
                });
                break;
            case 'info':
                toast.info(message, {
                    position: position,
                    icon:icon,
                    theme: color,
                    className:className,
                    autoClose:autoClose ? autoClose :false
                });
                break;
            case 'custom':
                toast(message, {
                    position: position,
                    icon:icon,
                    theme: color,
                    className:className,
                    autoClose:autoClose ? autoClose :false
                });
                break;
            case 'promise':
                toast.promise(
                    promiseObject ? promiseObject : resolveAfter3Sec ,
                    {
                        pending: pendingMessage,
                        success: successMessage,
                        error: errorMessage
                    }
                )
                break;
            default:
                toast(message);
                break;
        }
    };
    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));


export default notify;