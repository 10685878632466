import React, {useEffect} from "react";
import TextBoxes from "./index";
import DefaultTextBoxProps from "../../types/DefaultTextBoxProps";
import {numberValidator} from "../../validators/textFeildValidators";

const NumberTextBox = (props:DefaultTextBoxProps)=>{
    const [number, setNumber] = React.useState<string>('');
    useEffect(() => {
        console.log('Number', props.onerror);
        setNumber(props.onerror ? props.onerror : '');
    }, [props.onerror]);
    const validator = ()=>{
        const enterValue = document.getElementById(props.id) as HTMLInputElement;
        if(enterValue?.value.length > 0 && !numberValidator(enterValue?.value)){
            setNumber('Please enter a valid number');
        }else{
            setNumber('');
        }
    }

    useEffect(() => {
        const element = document.getElementById(props.id) as HTMLInputElement;
        if(element){
            if(number.length > 0){
                element.style.borderColor = '#CD123D'
            }else{
                element.style.borderColor = '#969696'
            }
        }
    }, [number])

    return(
        <>
            <TextBoxes
                id={props.id}
                type={'number'}
                placeholder={props.placeholder}
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                onChange={props.onChange}
                onClick={props.onClick}
                onLostFocus={()=>{
                    validator();
                    props.onLostFocus;
                }}
                onKeydown={props.onKeydown}
                value={props.value}
                fluid={props.fluid}
            />
            <span className='email-validation'>{number}</span>
        </>
    )
}

export default NumberTextBox