/*
 *################################################################################
 *Module Name                   	: CheckBox.tsx                				 #
 *Purpose of the Module         	: To Show Checker element         			 #
 *Description of the Module    	    : This element  act like CheckBox    		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/08/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
import React from "react";

import { Checkbox} from 'semantic-ui-react'

//Import CheckBoxProps
import CheckBoxProps from "../../types/CheckBoxProps";

/**
 * type = Is the type of the checkbox eg:- checkbox, slider, toggle, radio
 *
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * value = Is the value of the Checkbox
 *
 * label = If you need to show text along with the checkbox. eg;- label={'Accept newsletters'}
 *
 * onChange = If you need to trigger event according to checkbox value change.
 *
 * onClick = If you need to trigger event according to mouse click on the element.
 *
 * onMouseDown = If you need to trigger event according to mouse button down.
 *
 * onMouseUp = If you need to trigger event according to mouse button up.
 */

const Checker=({id,type,value,name,label,onChange,onClick,onMouseDown,onMouseUp,disabled,className,style}:CheckBoxProps)=>{
    if(type === 'checkbox'){
        return(
            <Checkbox
                id={id}
                checked={value}
                label={label}
                name={name}
                onChange={onChange}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                disabled={disabled}
                style={style}
                className={className}
            />
        );
    }else if(type === 'slider'){
        return(
            <Checkbox
                id={id}
                slider
                checked={value}
                label={label}
                name={name}
                onChange={onChange}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                disabled={disabled}
                style={style}
                className={className}
            />
        );
    }else if(type === 'toggle'){
        return(
            <Checkbox
                id={id}
                toggle
                checked={value}
                label={label}
                name={name}
                onChange={onChange}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                disabled={disabled}
                style={style}
                className={className}
            />
        );
    }else if(type === 'radio'){
        return(
            <Checkbox
                id={id}
                radio
                checked={value}
                label={label}
                name={name}
                onChange={onChange}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                disabled={disabled}
                style={style}
                className={className}
            />
        );
    }else{
        return(
            <Checkbox
                id={id}
                checked={value}
                label={label}
                name={name}
                onChange={onChange}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                disabled={disabled}
                style={style}
                className={className}
            />
        );
    }

}

export default Checker