import React from "react";
import Header from "../../../framework/layouts/Header";
import Footer from "../../../framework/layouts/Footer";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import WelcomePage from "./WelcomePage";
import NotFound from "../../Warring/404";

const AdminWelcome = () => {
    const session = getSessionCookie();

    return (
        <>
            {session.userRoleId != 4 && session.userRoleId != 1 ? (<NotFound/>) : (
                <>
                    <Header>
                        <WelcomePage
                            session={session}
                        />
                    </Header>
                    <Footer/>
                </>)}
        </>

    );
}

export default AdminWelcome;