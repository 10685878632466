import React, {useEffect, useState} from "react";
import {Button, Grid} from "semantic-ui-react";
import {HashLink} from "react-router-hash-link";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import {AuditorRegionValueProps} from "../auditor/profile/DataModal";


interface Props{
    myReagion?:AuditorRegionValueProps
}
const RegionAvaiability = ({myReagion}:Props) => {

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <h3 style={{display: 'inline-flex'}}>Regions available to perform Road Safety Audits</h3>
                    </Grid.Column>
                </Grid.Row>
                {myReagion?.greateSyndey == true ||
                myReagion?.west == true ||
                myReagion?.south == true ||
                myReagion?.north == true ||
                myReagion?.act == true ||
                myReagion?.qld == true ||
                myReagion?.sa == true  ||
                myReagion?.tas == true ||
                myReagion?.nt == true  ||
                myReagion?.overseas == true ||
                myReagion?.vic== true  ||
                myReagion?.wa == true ? (<Grid.Row>
                    <Grid.Column width={16}>
                        <Grid>
                            <Grid.Row columns={3}>
                                {myReagion?.greateSyndey == true || myReagion?.west == true || myReagion?.south == true || myReagion?.north == true ? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'New South Wales'} name={'state'} id={'nsw'}
                                                      value={myReagion?.greateSyndey == true || myReagion?.west == true || myReagion?.south == true || myReagion?.north == true ? true : false}
                                                      disabled={true}
                                    />
                                    <Grid>
                                        <Grid.Row columns={1}>
                                            {myReagion?.greateSyndey? (<Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <NameWithCheckBox label={'Greater Sydney'} name={'state'} id={'grate'}
                                                                  value={myReagion?.greateSyndey}
                                                                  disabled={true}
                                                />
                                            </Grid.Column>) : null}
                                            {myReagion?.north? (<Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <NameWithCheckBox label={'North'} name={'state'} id={'north'}
                                                                  value={myReagion?.north}
                                                                  disabled={true}
                                                />
                                            </Grid.Column>) : null}
                                            {myReagion?.south? ( <Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <NameWithCheckBox label={'South'} name={'state'} id={'south'}
                                                                  value={myReagion?.south}
                                                                  disabled={true}
                                                />
                                            </Grid.Column>) : null}
                                            {myReagion?.west? (<Grid.Column style={{paddingLeft: '50px'}} width={16} widescreen={16} computer={16} tablet={16} mobile={16}>
                                                <NameWithCheckBox label={'West'} name={'state'} id={'west'}
                                                                  value={myReagion?.west}
                                                                  disabled={true}
                                                />
                                            </Grid.Column>) : null}
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>) : null}
                                {myReagion?.act? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'Australia Capital Region'} name={'state'} id={'act'}
                                          value={myReagion?.act}
                                          disabled={true}
                                    />
                                </Grid.Column>) : null}
                                {myReagion?.qld? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'Queensland'} name={'state'} id={'qld'}
                                          value={myReagion?.qld}
                                          disabled={true}
                                    />
                                </Grid.Column>) : null}
                                {myReagion?.sa? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'South Australia'} name={'state'} id={'sa'}
                                          value={myReagion?.sa}
                                          disabled={true}
                                    />
                                </Grid.Column>) : null}
                                {myReagion?.tas? ( <Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'Tasmania'} name={'state'} id={'tas'}
                                          value={myReagion?.tas}
                                          disabled={true}
                                    />
                                </Grid.Column>) : null}
                                {myReagion?.nt? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'Northern Territory'} name={'state'} id={'nt'}
                                          value={myReagion?.nt}
                                          disabled={true}
                                    />
                                </Grid.Column>) : null}
                                {myReagion?.overseas? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'Overseas'} name={'state'} id={'os'}
                                          value={myReagion?.overseas}
                                          disabled={true}
                                    />
                                </Grid.Column>) : null}
                                {myReagion?.vic ? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'Victoria'} name={'state'} id={'victoria'}
                                                      value={myReagion.vic}
                                                      disabled={true}
                                    />
                                </Grid.Column>) : null }
                                {myReagion?.wa? (<Grid.Column width={5} widescreen={5} computer={5} tablet={8} mobile={8}>
                                    <NameWithCheckBox label={'Western Australia'} name={'state'} id={'wa'}
                                                      value={myReagion?.wa}
                                                      disabled={true}
                                    />
                                </Grid.Column>) : null }
                            </Grid.Row>

                        </Grid>
                    </Grid.Column>
                </Grid.Row>) :(
                <Grid.Row columns={3}>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>No available regions found for this
                        auditor</Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default RegionAvaiability