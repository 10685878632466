import React from "react";
import {Grid} from "semantic-ui-react";
import {QualificationValueProps} from "../auditor/profile/DataModal";

interface Props{
    Qualification?:QualificationValueProps
}
const RelevantProfessionalExperience =({Qualification}:Props) => {
    return (
       <React.Fragment>
           <Grid>
               <Grid.Row columns={1}>
                   <Grid.Column>
                       <h3 style={{display: 'inline-flex'}}>Years of relevant professional experience</h3>
                   </Grid.Column>
               </Grid.Row>
               {
                   Qualification?.roadDesign != undefined && parseInt(Qualification?.roadDesign) > 0 ||
                   Qualification?.trafficEngineering != undefined && parseInt(Qualification?.trafficEngineering) > 0 ||
                   Qualification?.trafficTransportManagement != undefined && parseInt(Qualification?.trafficTransportManagement) > 0  ||
                   Qualification?.roadSafetyEngineering != undefined && parseInt(Qualification?.roadSafetyEngineering) > 0  ||
                   Qualification?.behaviouralScience != undefined && parseInt(Qualification?.behaviouralScience) > 0  ?(<Grid.Row>
                   <table id={'tblrel'} className="ui very compact table background-transaparent ">
                       <thead>
                       {Qualification?.roadDesign != undefined && parseInt(Qualification?.roadDesign) > 0? (<tr>
                           <th style={{width:'20px'}}> </th>
                           <th style={{width:'300px',fontWeight:'lighter'}}> Road Design</th>
                           <th style={{fontWeight:'lighter'}}>{Qualification?.roadDesign}</th>
                       </tr>):null}
                       {Qualification?.trafficEngineering != undefined && parseInt(Qualification?.trafficEngineering) > 0? (<tr>
                           <th style={{width:'20px'}}> </th>
                           <th style={{width:'300px',fontWeight:'lighter'}}> Traffic Engineering</th>
                           <th style={{fontWeight:'lighter'}}>{Qualification?.trafficEngineering}</th>
                       </tr>):null}
                       {Qualification?.trafficTransportManagement != undefined && parseInt(Qualification?.trafficTransportManagement) > 0? (<tr>
                           <th style={{width:'20px'}}> </th>
                           <th style={{width:'300px',fontWeight:'lighter'}}> Traffic Transport Management</th>
                           <th style={{fontWeight:'lighter'}}>{Qualification?.trafficTransportManagement}</th>
                       </tr>):null}
                       {Qualification?.roadSafetyEngineering != undefined && parseInt(Qualification?.roadSafetyEngineering) > 0? (<tr>
                           <th style={{width:'20px'}}> </th>
                           <th style={{width:'300px',fontWeight:'lighter'}}> Road Safety Engineering</th>
                           <th style={{fontWeight:'lighter'}}>{Qualification?.roadSafetyEngineering}</th>
                       </tr>):null}
                       {Qualification?.behaviouralScience != undefined && parseInt(Qualification?.behaviouralScience) > 0? (<tr>
                           <th style={{width:'20px'}}> </th>
                           <th style={{width:'300px',fontWeight:'lighter'}}> Behavioural Science</th>
                           <th style={{fontWeight:'lighter'}}>{Qualification?.behaviouralScience}</th>
                       </tr>) : null}
                       </thead>
                   </table>
               </Grid.Row>):(
               <Grid.Row columns={3}>
                   <Grid.Column></Grid.Column>
                   <Grid.Column>No relevant professional experience found for this
                       auditor</Grid.Column>
                   <Grid.Column></Grid.Column>
               </Grid.Row>
               )}
           </Grid>
       </React.Fragment>
    )
}

export default  RelevantProfessionalExperience