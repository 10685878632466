// Post Data
import {PersonalDetailsValueProps} from "../profile/DataModal";
import ApiCallAxio from "../../../framework/common/apiCallAxio";
import APICallMethods from "../../../framework/types/APICallMethods";
import {
    IAdminNotifications,
    IGeneralNotifications,
    ISystemNotifications
} from "../../../models/apiResponce/Notification/GetNotifications";

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;
const AddOrUpdateAuditorDetails = async (data: PersonalDetailsValueProps) => {
    try {
        
        // const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/ApplyLevelReview`; //complete url
        // const contactDetails: object = {
        //     auditorId: data.txtAuditorId,
        //     titleId: parseInt(data.txtTitle ? data.txtTitle : "0"),
        //     firstName: data.txtFirstName ? data.txtFirstName : "",
        //     lastName: data.txtLastName ? data.txtLastName : "",
        //     email: data.txtEmail ? data.txtEmail : "",
        //     middleName: data.txtMiddleName ? data.txtMiddleName : "",
        //     applyingLevel: parseInt(data.txtApplyLevel ? data.txtApplyLevel : ""),
        //     password: data.txtPassword ? data.txtPassword : "",
        //     countryStateId: 1
        // }; //body data


        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/LodgeLevelUpgradeOrDowngradeLevelAtOnce`; //complete url
        const contactDetails: object = {
            auditorId: data.txtAuditorId,
            email: data.txtEmail ? data.txtEmail : "",            
            applyingLevel: parseInt(data.txtApplyLevel ? data.txtApplyLevel : ""),            
            countryStateId: 1
        }; //body data

        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: contactDetails ,
        }); //call the api using ApiCall method
        return result;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

const GetSystemNotifications = async (AuditorId:number) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Notifications/SystemNotificationsForAuditor`; //complete url
        const data: object = {
            auditorId: AuditorId,
            countryStateId: 1
        }; //body data

        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data ,
        }); //call the api using ApiCall method

        const SystemNotifications:ISystemNotifications[] = result.systemNotifications
        return SystemNotifications;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

const GetGeneralNotifications = async () => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Notifications/GeneralNotificationsForAuditor`; //complete url
        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.GET,
        }); //call the api using ApiCall method

        const GeneralNotifications:IGeneralNotifications[] = result.generalNotifications
        return GeneralNotifications;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

const GetAdminNotifications = async (AuditorId:number) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Notifications/AdminNotificationsForAuditor`; //complete url
        const data: object = {
            auditorId: AuditorId,
            countryStateId: 1
        }; //body data

        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data ,
        }); //call the api using ApiCall method

        const AdminNotifications:IAdminNotifications[] = result.adminNotifications
        return AdminNotifications;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

//Delete System Message
const DeleteSystemNotifications = async (AuditorId:number,NotificationId:number) => {
    try {
        const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Notifications/DeleteSysNotification`; //complete url
        const data: object = {
            auditorId: AuditorId,
            countryStateId: 1,
            sysNotificationId:NotificationId
        }; //body data

        //eslint-disable-next-line
        //debugger
        const result = await ApiCallAxio({
            url: `${url}`,
            method: APICallMethods.POST,
            data: data ,
        }); //call the api using ApiCall method

        const SystemNotifications:boolean = result.deleteSysNotification
        return SystemNotifications;//return the array
    } catch (e) {
        console.log(e);
        throw e;
    }

}

export {
    AddOrUpdateAuditorDetails,
    GetSystemNotifications,
    GetGeneralNotifications,
    GetAdminNotifications,
    DeleteSystemNotifications
}