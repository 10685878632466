import React, {useState} from "react";
import {Container, Grid} from "semantic-ui-react";
//Imported components
import DefaultCheckBox from "../../framework/components/CheckBox/DefaultCheckBox";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import Slider from "../../framework/components/CheckBox/Slider";
import NameWithSlider from "../../framework/widgets/NameWithCheckBox/NameWithSlider";
import Switch from "../../framework/components/CheckBox/Switch";
import NameWithSwitch from "../../framework/widgets/NameWithCheckBox/NameWithSwitch";
import RadioButton from "../../framework/components/CheckBox/RadioButton";
import NameWithRadioButton from "../../framework/widgets/NameWithCheckBox/NameWithRadioButton";


const SampleOptions = () => {
    const [toggle, setToggle] = useState(false)
    const [rbtVal, setRbtVal] = useState('Human')
    return (
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>CheckBox Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <div style={{textAlign:'left' , backgroundColor:'#464646'}}>
                                <code>{"import DefaultCheckBox from \"../../framework/components/CheckBox/DefaultCheckBox\";\n"}</code>
                                <br/>
                                <code>{"import NameWithCheckBox from \"../../framework/widgets/NameWithCheckBox/NameWithCheckBox\";\n"}</code>
                                <br/>
                                <code>{"import Slider from \"../../framework/components/CheckBox/Slider\";\n"}</code>
                                <br/>
                                <code>{"import NameWithSlider from \"../../framework/widgets/NameWithCheckBox/NameWithSlider\";\n"}</code>
                                <br/>
                                <code>{"import Switch from \"../../framework/components/CheckBox/Switch\";\n"}</code>
                                <br/>
                                <code>{"import NameWithSwitch from \"../../framework/widgets/NameWithCheckBox/NameWithSwitch\";\n"}</code>
                                <br/>
                                <code>{"import RadioButton from \"../../framework/components/CheckBox/RadioButton\";\n"}</code>
                                <br/>
                                <code>{"import NameWithRadioButton from \"../../framework/widgets/NameWithCheckBox/NameWithRadioButton\";"}</code>
                                <br/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <DefaultCheckBox
                                id={'chkAgree'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard checkbox</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<DefaultCheckBox\n" +
                                    "                                    id={\"chkAgree\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <NameWithCheckBox
                                id={'chkAgree1'}
                                label={'I Agree'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard checkbox with label</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<NameWithCheckBox\n" +
                                    "                                    id={\"chkAgree\"}\n" +
                                    "                                    label={\"I Agree\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <Slider
                                id={'chkUp'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is slider checkbox</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<Slider\n" +
                                    "                                    id={\"chkActive\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <NameWithSlider
                                id={'chkUp1'}
                                label={'Status'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is slider checkbox with label</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<NameWithSlider\n" +
                                    "                                    id={\"chkActive\"}\n" +
                                    "                                    label={\"Status\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <Switch
                                id={'chkON'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is toggle checkbox</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<Switch\n" +
                                    "                                    id={\"chkON\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <NameWithSwitch
                                id={'chkON1'}
                                label={`Light ${toggle ? 'ON' : 'OFF'}`}
                                onChange={() => {
                                    setToggle(!toggle)
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is toggle checkbox with label</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<NameWithSwitch\n" +
                                    "                                    id={\"chkON\"}\n" +
                                    "                                    label={\"Light\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <RadioButton
                                id={'rbtHuman'}
                                name={'Animal'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is radio checkbox with label</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<RadioButton\n" +
                                    "                                    id={\"rbtHuman\"}\n" +
                                    "                                    name={\"Animal\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <NameWithRadioButton
                                id={'rbtHuman1'}
                                label={`Human`}
                                name={'AnimalCat'}
                                value={rbtVal === 'Human'}
                                onChange={() => {
                                    setRbtVal('Human')
                                }}
                            />
                            <NameWithRadioButton
                                id={'rbtAnimal1'}
                                label={`Animal`}
                                name={'AnimalCat'}
                                value={rbtVal === 'Animal'}
                                onChange={() => {
                                    setRbtVal('Animal')
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is radio checkbox with label and grouping</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<NameWithRadioButton\n" +
                                    "                                    id={\"rbtHuman\"}\n" +
                                    "                                    label={\"Light\"}\n" +
                                    "                                    name={\"Category\"}\n" +
                                    "                                    value={rbtVal === 'Human'}\n" +
                                    "                                    />  " +
                                    "<NameWithRadioButton\n" +
                                    "                                    id={\"rbtAnimal\"}\n" +
                                    "                                    label={\"Light\"}\n" +
                                    "                                    name={\"Category\"}\n" +
                                    "                                    value={rbtVal === 'Animal'}\n" +
                                    "                                    " +
                                    "                                    />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleOptions