import { Accordion, Grid, Icon } from 'semantic-ui-react'
import React, {useEffect, useState} from 'react'
import FaqTable from './FaqTable';
import {fetchFAQS} from "./faqsController";
import {FaqsRes, FaqSub} from "../../models/apiResponce/Common/FAQs";
import parse from 'html-react-parser'

//this is the content(questions and answers) of the faqs page
//edited by Shane W
//22-03-2023

interface FaqProps {
  activeIndex: any;
}

const FaqAccordion = (props: FaqProps) => {

  const [activeIndex, setActiveIndex] = useState<number[]>([])
  const [state, setState] = useState<FaqsRes[]>()
  const encid = new URLSearchParams(location.search).get('go')

  useEffect(()=>{
    if(encid != null && encid == 'accept_courses'){
      setActiveIndex([5])
      setTimeout(()=>{
        const section = document.getElementById('5');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      },1000)
    }else if(encid != null && encid == 'levels'){
      setActiveIndex([2])
      setTimeout(()=>{
        const section = document.getElementById('2');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      },1000)
    }
  },[])

  useEffect(()=>{
    const arrFaqs  = fetchFAQS();
    Promise.resolve(arrFaqs).then((values)=>{
      if(values){
        //eslint-disable-next-line
        let arrRqaRess : FaqsRes[] = []
        //eslint-disable-next-line
        let arrSub: FaqSub[] = []
        let catId = -1
        let catDesc = ''
          values.map((r)=>{
            if(catId != r.categoryId){
              if(catId == -1){
                catId = r.categoryId
                catDesc = r.categoryDesc
              }
              if(arrSub.length > 0){
                  arrRqaRess.push({
                    categoryId:catId,
                    categoryDesc:catDesc,
                    SubList: arrSub
                  })
                arrSub = []
                catId = r.categoryId
                catDesc = r.categoryDesc
              }
            }
              arrSub.push({
                faqId : r.faqId,
                answer: r.answer,
                question: r.question,
                sequence: r.sequence
              })
          })
        arrRqaRess.push({
          categoryId:catId,
          categoryDesc:catDesc,
          SubList: arrSub
        })
        setState(arrRqaRess)
      }
    })
  },[])

  const handleClick = (index: number) => {
    const newIndex = activeIndex.slice();

    const currentIndexPosition = activeIndex.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }
    setActiveIndex(newIndex)
  };

  return (
    <Grid columns={3}>
      <Grid.Column width={2} />
      <Grid.Column width={12} >
        <Accordion fluid>
          {
            state?.map((r)=>(
                <>
                  <Accordion.Title className="faq-page"
                                   active={activeIndex.includes(r.categoryId)}
                                   index={r.categoryId}
                                   onClick={() => {
                                     handleClick(r.categoryId)
                                   }}
                                   id={r.categoryId.toString()}
                  >
                    <Icon name='angle down' style={{ float: 'right' }} />
                    {r.categoryDesc}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex.includes(r.categoryId)} className="faq-page">
                    <SubAccordian
                        SubArray={r.SubList}
                    />
                  </Accordion.Content>
                  <br /><br />
                </>
            ))
          }
        </Accordion>
      </Grid.Column>
      <Grid.Column width={2} />
    </Grid>
  )
}

interface Props{
  SubArray:FaqSub[]
}
const SubAccordian = ({SubArray}:Props) =>{
  const [activeIndex, setActiveIndex] = useState<number[]>([])
  const encid = new URLSearchParams(location.search).get('go')
  useEffect(()=>{
    if(encid != null && encid == 'accept_courses'){
     setActiveIndex([17])
    }else if(encid != null && encid == 'levels'){
      setActiveIndex([7])
    }
  },[])
  const handleClick = (index: number) => {
    const newIndex = activeIndex.slice();

    const currentIndexPosition = activeIndex.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }
    setActiveIndex(newIndex)
  };
 return (
     <Accordion fluid>
       {SubArray.map((r)=>(
         <>
           <Accordion.Title className="faq-subtitle"
                            active={activeIndex.includes(r.faqId)}
                            index={r.faqId}
                            onClick={() => {
                              handleClick(r.faqId)
                            }}
                            id={r.faqId}
           >
             <Icon name='angle down' style={{ float: 'right' }} />
             {r.question}
           </Accordion.Title>
           <Accordion.Content
               active={activeIndex.includes(r.faqId)}
           >
             {parse(r.answer)}
             </Accordion.Content>
           <hr/>
         </>
       ))}
     </Accordion>
 )
}

export default FaqAccordion;