/*
*#################################################################################
*Module Name                   	    : Slider.tsx                  				 #
*Purpose of the Module         	    : To Show CheckBox element         			 #
*Description of the Module    	    : This element  act like CheckBox     		 #
*Initial Author               	    : T A C Priyashad                            #
*Date                          	    : 11/29/2022                   		         #
*Copyright © 2022 ITworx Consulting.                                   	    	 #
*#################################################################################
*/
import React from "react";

//Import CheckBoxProps
import CheckBoxProps from "../../types/CheckBoxProps";
import CheckBox from "./index";



/**
 *
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * value = Is the value of the Checkbox
 *
 * name = group of the checkboxes widely use for radio buttons.
 *
 * onChange = If you need to trigger event according to checkbox value change.
 *
 * onClick = If you need to trigger event according to mouse click on the element.
 *
 * onMouseDown = If you need to trigger event according to mouse button down.
 *
 * onMouseUp = If you need to trigger event according to mouse button up.
 */

const Slider =(props:CheckBoxProps)=>{
    return(
        <CheckBox
            id={props.id}
            type={'slider'}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
            onMouseDown={props.onMouseDown}
            onMouseUp={props.onMouseUp}
            disabled={props.disabled}
            style={props.style}
            className={props.className}
        />
    );
}
export default Slider