/*
 *################################################################################
 *Module Name                   	: Modals.tsx                				 #
 *Purpose of the Module         	: To Show modal element         			 #
 *Description of the Module    	    : This element  act like modal popup   		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/09/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */

import React from "react";
import {Modal, Image, Header, Grid, Icon, Button} from 'semantic-ui-react'
import Buttons from "../Buttons";
import parse from 'html-react-parser'

//Import ModalsProps
import ModalsProps from "../../types/ModalsProps";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";


const Modals = ({open,basicModal,modalSize,imageSize,imageUrl,title,setOpen,modalDescription,ModalBody,descriptionHeader,descriptionBody,modalAction,ActionButtons,modalDimmer}:ModalsProps)=>{
    return(
        <Modal
            basic={basicModal}
            open={open}
            size={modalSize}
            dimmer={modalDimmer}
        >
            {title?(<Modal.Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={15} tablet={14} computer={15} widescreen={15}>
                            <Modal.Header>{parse(title)}</Modal.Header>
                        </Grid.Column>
                        <Grid.Column mobile={16} className={'modal-button'} style={{display:'flex', justifyContent:'end'}}>
                            {setOpen ? (
                                    <Button style={{padding:'0px', fontSize:'35px', color:'red'}} id={'btnClose'}  onClick={()=>{setOpen(false)}}  circular={true} outline={1} ><FontAwesomeIcon icon={regular("circle-xmark")} /></Button>
                                // <Buttons style={{padding:'5px'}} id={'btnClose'}  onClick={()=>{setOpen(false)}} color='red' circular={true} icon='close' outline={1}/>
                                ) : null}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Modal.Header>):null}

            <Modal.Content image>
                {imageSize ?(<Image size={imageSize} src={imageUrl} wrapped />):null}
                {modalDescription ? (<Modal.Description>
                    <Header>{descriptionHeader}</Header>
                    <p>
                        {descriptionBody}
                    </p>
                </Modal.Description>):<ModalBody/>}
            </Modal.Content>
            {modalAction ? (<Modal.Actions>
                <ActionButtons/>
            </Modal.Actions>):null}
        </Modal>
    );
}

export default Modals