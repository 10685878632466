import React from "react";


import {Container,Grid} from "semantic-ui-react";
import IconTextButton from "../../framework/components/Buttons/IconTextButton";
import IconTextInverseButton from "../../framework/components/Buttons/IconTextInverseButton";
import DefaultButton from "../../framework/components/Buttons/DefaultButton";
import OutlIneButton from "../../framework/components/Buttons/OutlIneButton";
import IconButton from "../../framework/components/Buttons/IconButton";
import IconCircleOutlineButton from "../../framework/components/Buttons/IconCircleOutlineButton";


const SampleButtons =()=>{
    return(
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>Button Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <div style={{textAlign:"left", backgroundColor:'#464646'}}>
                                <code>{"import IconTextButton from \"../../framework/components/Buttons/IconTextButton\";\n"}</code>
                                <br/>
                                <code>{"import IconTextInverseButton from \"../../framework/components/Buttons/IconTextInverseButton\";\n"}</code>
                                <br/>
                                <code>{"import DefaultButton from \"../../framework/components/Buttons/DefaultButton\";\n" }</code>
                                <br/>
                                <code>{"import OutlIneButton from \"../../framework/components/Buttons/OutlIneButton\";\n"}</code>
                                <br/>
                                <code>{"import IconButton from \"../../framework/components/Buttons/IconButton\";\n" }</code>
                                <br/>
                                <code>{"import IconCircleOutlineButton from \"../../framework/components/Buttons/IconCircleOutlineButton\";"}</code>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <DefaultButton
                                id={"fb"}
                                text={"Grey"}
                                color='grey'
                                onClick={()=>{alert('ok')}}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic button with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                                <code>
                                    {"<DefaultButton\n" +
    "                                    id={\"fb\"}\n" +
    "                                    text={\"Grey\"}\n" +
    "                                    color='grey'\n" +
    "                                    onClick={()=>{alert('ok')}}\n" +
    "                                />  "}
                                </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <OutlIneButton
                                id={"fb"}
                                text={"Violet"}
                                color='violet'
                                onClick={()=>{alert('ok')}}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic outline button with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<OutlIneButton\n" +
                                    "                                    id={\"fb\"}\n" +
                                    "                                    text={\"Violet\"}\n" +
                                    "                                    color='violet'\n" +
                                    "                                    onClick={()=>{alert('ok')}}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <IconTextButton
                                id={"fbwithIcon"}
                                text={"Facebook"}
                                color='blue'
                                icon='facebook'
                                onClick={()=>{alert('ok')}}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic button with background color and icon</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<IconTextButton\n" +
                                    "                                    id={\"btnButtonWithIcon\"}\n" +
                                    "                                    text={\"Facebook\"}\n" +
                                    "                                    color='blue'\n" +
                                    "                                    icon='facebook'\n" +
                                    "                                    onClick={()=>{alert('ok')}}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <IconTextInverseButton
                                id={"fbwithIcon"}
                                text={"WhatsApp"}
                                color='green'
                                icon='whatsapp'
                                onClick={()=>{alert('ok')}}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic inverted button with background color and icon</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<IconTextInverseButton\n" +
                                    "                                    id={\"btnButtonWithIcon\"}\n" +
                                    "                                    text={\"WhatsApp\"}\n" +
                                    "                                    color='green'\n" +
                                    "                                    icon='whatsapp'\n" +
                                    "                                    onClick={()=>{alert('ok')}}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <IconButton
                                id={"fb"}
                                color='red'
                                icon='youtube'
                                onClick={()=>{alert('ok')}}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is icon button with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<IconButton\n" +
                                    "                                    id={\"btnYoutubeIcon\"}\n" +
                                    "                                    icon='youtube'\n" +
                                    "                                    color='red'\n" +
                                    "                                    onClick={()=>{alert('ok')}}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>
                            <IconCircleOutlineButton
                                id={"fb"}
                                color='pink'
                                icon='instagram'
                                onClick={()=>{alert('ok')}}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is circular icon button with background color</p>
                        </Grid.Column>
                        <Grid.Column width={10} className={'columnBack'}>
                            <code>
                                {"<IconCircleOutlineButton\n" +
                                    "                                    id={\"btnYoutubeIcon\"}\n" +
                                    "                                    icon='instagram'\n" +
                                    "                                    color='pink'\n" +
                                    "                                    onClick={()=>{alert('ok')}}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleButtons