/*
 *################################################################################
 *Module Name                   	: IconButtons.tsx  		                	 #
 *Purpose of the Module         	: To Show icon button                     	 #
 *Description of the Module    	    : This element  act like button       		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/29/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */

import React from "react";
import Buttons from "./index";
import ButtonsDefaultProps from "../../types/ButtonDefaultProps";
import {SemanticICONS} from "semantic-ui-react";

interface Props extends ButtonsDefaultProps {
    icon?:SemanticICONS;
}

/**
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * color = Is the backgroud color of the element should be SemanticCOLORS eg:- color = 'red'.
 *
 * SemanticCOLORS list - red | orange | yellow | olive | green | teal | blue | violet | purple | pink | brown | grey | black
 *
 * onClick = Is the button click event should be a fucntion eg:- onClick = {()=>{ alert('This is a friendly alert')}}
 *
 * icon = If you need to show Icon along with text add value to this parameter. Should be SemanticICONS eg:- icon='facebook'
 *
 * style = Can override the style of the button eg:- style={{width:'100px',height:'100px'}}
 *
 * className = can override the class of the button eg:- className={'btn btn-primary'}
 *
 * disabled = If you need to disable the button just pass disabled parameter with true eg:- disabled={true}
 */

const IconButton = ({id,color,onClick,style,className,disabled,icon}:Props) => {
    return (
        <Buttons id={id} color={color} style={style} disabled={disabled} icon={icon} className={className} onClick={onClick} />
    )
}

export default IconButton