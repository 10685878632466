import React, {useEffect, useState} from "react";
import {Container, Grid, Table} from "semantic-ui-react";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import {userRoleSelector} from "../../common/roleSelector";
import {getSessionCookie} from "../../framework/common/SessionHandler";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import DateRangeBox from "../../framework/components/TextBoxes/DateRangeBox";
import { fetchTrainingCourses } from "./TrainingController";
import moment from "moment";
import { TrainingCoursesRes } from "../../models/apiResponce/Courses/TrainingCourseRes";
import {Oval} from "react-loader-spinner";

const TrainingContainer = () =>{
   const [state, setState] = useState<TrainingCoursesRes[]>([])
   const [loading,setLoading] = useState(false)
   useEffect(()=>{
    const fetchData = fetchTrainingCourses();
    setLoading(true)
    Promise.resolve(fetchData).then((value)=>{
        if(value){
            setState(value)
        }
        setLoading(false)
    });
   },[])

    return(
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Training"}
                usreName={''}
                userRole={""}
                lastLogin={""}
                status={""}
                level={""}
                certExpDate={""}
                newId={""}
            />
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p>Transport for NSW recognises the following training programs as the training pre-requisite for auditors applying for inclusion on the Register of Road Safety Auditors. Please refer to the Training courses section of the <a href="https://rsanationalregisterreactdev2.itworxconsulting.com/faqs" target="_blank" rel="noopener noreferrer">FAQs</a> page for more information on training requirements for auditors at different levels.</p>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                {loading ? (<>
                        <Grid.Row columns={3}>
                            <Grid.Column></Grid.Column>
                            <Grid.Column>
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#1559A3"
                                    wrapperStyle={{margin: 'auto', display: 'flex', justifyContent: 'center'}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#464646"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </Grid.Column>
                            <Grid.Column></Grid.Column>
                        </Grid.Row>
                    </>) :
                (<Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid>
                            <Grid.Row columns={2}>
                                {state.map((r,index) => (
                                    <LoadCourse
                                        countryStateId={r.countryStateId}
                                        countryStateName={r.countryStateName}
                                        courseId={r.courseId}
                                        courseName={r.courseName}
                                        courseProviderId={r.courseProviderId}
                                        courseProviderName={r.courseProviderName}
                                        courseReferenceId={r.courseReferenceId}
                                        linkUrl={r.linkUrl}
                                        shortDesc={r.shortDesc}
                                        key={index}
                                    />
                                ))}
                                
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>)}
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default TrainingContainer


const LoadCourse = (props:TrainingCoursesRes) => {

    return(
            <Grid.Column mobile={16} computer={8} widescreen={8} largeScreen={8} tablet={16}>
                <Grid style={{margin:'10px'}}>
                    <Grid.Row columns={1}>
                        <Grid.Column className="training-widget"> <b>{props.courseName}</b></Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column className="training-widget-details">{props.shortDesc}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} >
                        <Grid.Column className="training-button button-flex">
                            <OutLineButton id={'cmbApplyLevelReview'} onClick={ ()=>window.open(`${props.linkUrl}`, "_blank")}
                                       color='blue' className={"background-transaparent"} text={'Learn More'}
                        /></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
    )
}