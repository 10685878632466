import React, {useEffect, useState} from "react";
import {Button, Grid} from "semantic-ui-react";
import IconTextOutlineButton from "../../../framework/components/Buttons/IconTextOutlineButton";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../../framework/types/TableHeader";
import TableRow from "../../../framework/types/TableRow";
import tags from "../../../models/common/tag";
import {HashLink} from "react-router-hash-link";
import AddNewAudit from "../profile/AddNew Audit";
import Modal from "../../../framework/components/Modals";
import AddNewCourse from "../profile/AddNewCourse";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import {fetchCourseProviders, fetchCourses} from "../profile/ProfileController";
import {RoadSafetyCoursesProps} from "../profile/DataModal";

interface Props{
    courses?:RoadSafetyCoursesProps[]
}

const CourseSummery = ({courses}:Props) => {
    const session = getSessionCookie();
    const [open, setOpen] = useState(false);
    const [providers, setProviders] = useState<any>();
    const [course, setCourse] = useState<any>();

    useEffect(() => {
        setCourse(fetchCourses());
        setProviders(fetchCourseProviders());
    }, []);

    const rsaCourseHead: TableHeader[] = [
        {
            text: "Course Name"
        },
        {
            text: "Classification"
        }
    ];

    const rsaCourseDetRow: TableRow[] = [
        {
            tag: tags.text,
            text: "Road Safety for Lead Auditor",
            icon: "",
            onclick: null,
            width: '50vw'
        },
        {
            tag: tags.text,
            text: "Leader",
            icon: "",
            onclick: null
        }
    ];

    const [rsaCourseDet, setRSACourseDet] = useState<any>([])
    const [rsaCourseDet2, setRSACourseDet2] = useState<any>([])

    useEffect(()=>{
        if(courses != undefined && courses.length > 0){
            //eslint-disable-next-line
            let Verified = [];
            //eslint-disable-next-line
            let NonVerified = []
            for(let i = 0; i < courses.length; i++){
                const result = setVerifiedCourses(courses[i])
                const nonResult = setNonVerifiedCourses(courses[i])
                if(result != undefined && result != null){
                    Verified.push(result)
                }

                if(nonResult != undefined && nonResult != null ){
                    NonVerified.push(nonResult)
                }

            }
            setRSACourseDet(Verified.length > 0 ? Verified : [[{
                tag: tags.text,
                text: "None",
                icon: "",
                onclick: null,
                width: '50vw'
            },
                {
                    tag: tags.text,
                    text: "--",
                    icon: "",
                    onclick: null
                }]])
            setRSACourseDet2(NonVerified.length > 0 ? NonVerified : [[{
                tag: tags.text,
                text: "None",
                icon: "",
                onclick: null,
                width: '50vw'
            },
                {
                    tag: tags.text,
                    text: "--",
                    icon: "",
                    onclick: null
                }]])
        }else {
            setRSACourseDet([[{
                tag: tags.text,
                text: "None",
                icon: "",
                onclick: null,
                width: '50vw'
            },
                {
                    tag: tags.text,
                    text: "--",
                    icon: "",
                    onclick: null
                }]])
            setRSACourseDet2( [[{
                tag: tags.text,
                text: "None",
                icon: "",
                onclick: null,
                width: '50vw'
            },
                {
                    tag: tags.text,
                    text: "--",
                    icon: "",
                    onclick: null
                }]])
        }
    },[courses])
    const setVerifiedCourses = (singleCourse:RoadSafetyCoursesProps)=>{
        if(singleCourse != undefined || singleCourse != null){
            if(singleCourse.isVerified){
               return [{
                    tag: tags.text,
                        text: singleCourse.courseName,
                    icon: "",
                    onclick: null,
                    width: '50vw'
                },
                {
                    tag: tags.text,
                        text: singleCourse.courseTentativeClassification,
                    icon: "",
                    onclick: null
                }]
            }
        }
    }
    const setNonVerifiedCourses = (singleCourse:RoadSafetyCoursesProps)=>{
        if(singleCourse != undefined || singleCourse != null){
            if(!singleCourse.isVerified){
                return [{
                    tag: tags.text,
                    text: singleCourse.courseName,
                    icon: "",
                    onclick: null,
                    width: '50vw'
                },
                    {
                        tag: tags.text,
                        text: singleCourse.courseTentativeClassification,
                        icon: "",
                        onclick: null
                    }]
            }
        }
    }

    const toggleModel = (e: any, refresh = true) => {
        //eslint-disable-next-line
        //debugger;
        if(refresh){
            //alert('Hi')
            window.location.href = '/welcome'
        }
        setOpen(!open)
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column largeScreen={10} widescreen={12} computer={10} tablet={10} mobile={16}>
                        <h3 style={{display: 'inline-flex', color: "#1559A3"}}>Courses Summary</h3>
                    </Grid.Column>
                    <Grid.Column largeScreen={3} widescreen={2} computer={3} tablet={16} mobile={16} className={'button-flex'}>
                        <IconTextOutlineButton id={'cmbAddCourses'} style={{width:'150px'}} icon='add' onClick={(e: any) => {
                            toggleModel(e,false)
                        }} color='blue' className={"background-transaparent"} text={'Add Courses'} disabled={session.status == 4}/>
                    </Grid.Column>
                    <Grid.Column largeScreen={3} widescreen={2} computer={3} tablet={16} mobile={16} className={'button-flex'}>
                        <Button basic outline={1} content='blue' style={{width:'150px'}} id={'cmbmyCourse'} color='blue'
                                className={"background-transaparent"}><HashLink to={"/accreditation#courses"} style={{width:'100px', display:'inline-block'}}>My
                            Courses</HashLink></Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p style={{fontWeight:'bolder', fontSize:'18px'}}>Verified Courses</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <RSATable
                            head={rsaCourseHead}
                            body={rsaCourseDet}
                            tableHeadColored={true}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p style={{fontWeight:'bolder', fontSize:'18px'}}>Non-verified Courses</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <RSATable
                            head={rsaCourseHead}
                            body={rsaCourseDet2}
                            tableHeadColored={true}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal open={open ? open : false} modalAction={false} setOpen={setOpen} title={"Add Course Details"}
                   ModalBody={() => (
                       <AddNewCourse
                           provider={providers}
                           course={course}
                           courseId={0}
                           courseMId={0}
                           toggleModel={toggleModel}
                           isEdit={true}
                           providerEmail={""}
                           certificateNumber={""}
                           auditorId={session.auditorId}
                       />
                   )}/>
        </React.Fragment>
    )
}
export default CourseSummery