import React from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import AboutUsText from "./AboutUsText";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import { Container, Grid } from "semantic-ui-react";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
//This is the About Us page. 
//BackgroundImage is the image below the menu
//AboutUsTitle is the title
//AboutUsText is the text below title
//Modified by Shane W
//13-03-2023

const Aboutus = () => {
    return (
        <>
            <GoogleReCaptchaProvider
                reCaptchaKey={
                    process.env.REACT_APP_RECAPTCHA_KEY
                        ? process.env.REACT_APP_RECAPTCHA_KEY
                        : ""
                }
            >
                <Header>
                    <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px' }}>
                        <InnerTitleBar
                            title={'About Us'}
                            usreName={''}
                            userRole={""}
                            lastLogin={''}
                            status={''}
                        />
                        <Grid>
                            <Grid.Row columns={3} >
                                <Grid.Column width={2}>

                                </Grid.Column>
                                <Grid.Column width={12}>
                                    <AboutUsText />
                                </Grid.Column>
                                <Grid.Column width={2}>

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Header>

                <Footer />
            </GoogleReCaptchaProvider>
        </>
    )
}

export default Aboutus