import React, {Fragment, useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import RSATable from "../../framework/widgets/TableWithBackgrounds/RSATable";
import TableHeader from "../../framework/types/TableHeader";
import {RoadSafetyCoursesProps} from "../auditor/profile/DataModal";
import tags from "../../models/common/tag";

interface Props {
    Courses?: RoadSafetyCoursesProps[]
}

const RSACourses = ({Courses}: Props) => {
    const rsaCourseHead: TableHeader[] = [
        {
            text: "Date",
        },
        {
            text: "Course Provider"
        },
        {
            text: "Course Name"
        },
        {
            text: "Classification"
        }
    ];
    const [rsaCourseDet, setRSACourseDet] = useState<any>([])
    useEffect(() => {
        //eslint-disable-next-line
        let tempCourse:any = []
        if(Courses){
            Courses.map((r) => {
                tempCourse.push(
                    [
                        {
                            tag: tags.text,
                            text: r.courseDate,
                            icon: "",
                            onclick: null,
                            width: '150px'
                        },
                        {
                            tag: tags.text,
                            text: r.courseProvider,
                            icon: "",
                            onclick: null
                        },
                        {
                            tag: tags.text,
                            text: r.courseName,
                            icon: "",
                            onclick: null
                        },
                        {
                            tag: tags.text,
                            text: r.courseTentativeClassification,
                            icon: "",
                            onclick: null
                        }
                    ]
                );
            })
        }
        setRSACourseDet(tempCourse)
    }, [])

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <h3 style={{display: 'inline-flex'}}>Road Safety Courses Attended</h3>
                    </Grid.Column>
                </Grid.Row>
                {rsaCourseDet.length > 0 ?(<Grid.Row>
                    <div id={'rsaCourses'} style={{minWidth:'1000px', overflowX:'auto', width:'100%'}}>
                    <RSATable head={rsaCourseHead} body={rsaCourseDet} tableHeadColored={'blue'}/>
                    </div>
                </Grid.Row>) : (
                <Grid.Row columns={3}>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>No courses found for this
                        auditor</Grid.Column>
                    <Grid.Column></Grid.Column>
                </Grid.Row>
                )}
            </Grid>
        </React.Fragment>
    );
}

export default RSACourses