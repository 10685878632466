import React, {useEffect, useState} from "react";
import {AuditorProfile, AuditorRegionValueProps} from "../auditor/profile/DataModal";
import {fetchStates} from "../auditor/profile/ProfileController";
import Header from "../../framework/layouts/Header";
import {Oval} from "react-loader-spinner";
import Footer from "../../framework/layouts/Footer";
import {Container, Grid} from "semantic-ui-react";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import {fetchAuditorSearch} from "./SearchController";
import RegionAvaiability from "./ReagionAvaiability";
import RelevantProfessionalExperience from "./RelevantProfessionalExperience";
import RSACourses from "./RSACourses";
import RSAudits from "./RSAudits";
import SendMessageToAuditor from "./SendMessageToAuditor";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const AuditorDetails = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [states, setStates] = useState<any>();
    const [focusMode, setFocusMode] = React.useState('pending');
    const [stateRegion, setStateRegion] = React.useState<AuditorRegionValueProps>();

    useEffect(() => {
        setStates(fetchStates());
    }, []);
    return (
        <React.Fragment>
            <Header>
                {!loading ? (
                    <AuditorDetailsPage
                    />
                ) : (
                    <div style={{height: '40vh', marginTop: '30vh', paddingLeft: '50vw'}}>
                        <Oval
                            height={80}
                            width={80}
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /></div>
                )}
            </Header>
            <Footer/>
        </React.Fragment>
    )
};

export default AuditorDetails;

const AuditorDetailsPage = () => {
    const [state, setState] = useState<AuditorProfile>({} as AuditorProfile)
    const [loading, setLoading] = useState<boolean>(true);
    const encid = new URLSearchParams(location.search).get('get')
    const id = encid ? atob(encid) : '0'
    useEffect(() => {
        const auditor = fetchAuditorSearch(parseInt(id), 0);
        Promise.resolve(auditor).then((value: any) => {
            setLoading(false)
            setState(value ? value : {} as AuditorProfile)
        })
    }, [])
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ""}>
            <Container fluid={true} style={{marginTop: '14px', marginBottom: '20px'}}>
                <InnerTitleBar
                    title={"Auditor's Details"}
                    usreName={`${state.personalDetails?.txtFirstName ? state.personalDetails?.txtFirstName : ''} ${state.personalDetails?.txtLastName ? state.personalDetails?.txtLastName : ''}`}
                    userRole={''}
                    lastLogin={''}
                    status={''}
                    newId={''}
                    AED={state.AED}
                    level={state.personalDetails?.txtApplyLevel}
                />
                {!loading ? (
                    <Grid className={'search-auditor-top'}>
                        <Grid.Row>

                        </Grid.Row>
                        <Grid.Row>

                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <RelevantProfessionalExperience
                                    Qualification={state.qualification}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <RegionAvaiability
                                    myReagion={state.auditorRegion}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <RSACourses
                                    Courses={state.roadSafetyCourses}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <RSAudits
                                    Audits={state.roadSafetyAudit}
                                    Pages={state.AtotalPages}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={2}>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <SendMessageToAuditor
                                    Auditor={state}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>

                        </Grid.Row>
                        <Grid.Row>

                        </Grid.Row>
                    </Grid>
                ) : (
                    <div style={{height: '40vh', marginTop: '30vh', paddingLeft: '50vw'}}>
                        <Oval
                            height={80}
                            width={80}
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /></div>
                )}
            </Container>
        </GoogleReCaptchaProvider>
    )
}