import React, {useEffect, useState} from "react";
import {IMSGPanel, IMSGPanelLine} from "../../../framework/types/IMSGPanel";
import {GetGeneralNotifications} from "../../auditor/welcome/DashboardController";
import {IGeneralNotifications} from "../../../models/apiResponce/Notification/GetNotifications";
import MSGPanel from "../../../framework/widgets/Tabs/MSGPanel";
import {Grid} from "semantic-ui-react";
import moment from "moment";
import parse from "html-react-parser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons/faTrashCan";
import NotificationMenu from "../../../framework/widgets/MenuArray/NitificationMenu";

const GeneralMsg = () => {
    const [lines,SetLines] = useState<IMSGPanelLine[]>([])
    useEffect(()=>{
        const SystemNotify = GetGeneralNotifications()
        Promise.resolve(SystemNotify).then((value)=>{
            if(value){
                //eslint-disable-next-line
                let ArrNotify:IMSGPanelLine[] = []
                value.map((r)=>{
                    ArrNotify.push(SetValue(r))
                })
                SetLines(ArrNotify)
            }
        })
    },[])
    const SetValue = (msg:IGeneralNotifications) =>{
        return {
            Title:msg.lastupdate,
            Description:msg.notification
        }
    }
    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <p style={{fontWeight:'bolder', fontSize:'25px'}}>General notification</p>
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
            </Grid.Row>
                <MSGPanelBody
                    Lines={lines}
                    Icon='newspaper'
                />
        </Grid>

    )
}

export default GeneralMsg


const MSGPanelBody = (props: IMSGPanel) => {
    return (
        <React.Fragment>
                {props.Lines.map((line, index) => (
                    <Grid.Row key={index} columns={3}>
                        {line.Title ? (<Grid.Column width={3} widescreen={3} tablet={10} computer={3} mobile={10}>
                            {moment(line.Title).format('DD/MM/YYYY hh:mm:ss A')}
                        </Grid.Column>):null}
                        <Grid.Column width={11} mobile={10}>
                            {parse(line.Description)}
                        </Grid.Column>
                        {line.Delete ? (<Grid.Column width={2} style={{display:'flex', justifyContent:'end'}}>
                            <FontAwesomeIcon title={'Remove'} icon={faTrashCan}  onClick={line.Delete} style={{cursor:'pointer', fontWeight:'lighter'}}/>
                            &nbsp;&nbsp;
                            {/*<IconCircleButton id={'btnDelete'} onClick={line.Delete} color={'red'} icon='delete'/> {/*icon={faTrashCan}*/}
                        </Grid.Column>) : null}
                    </Grid.Row>
                ))}
        </React.Fragment>
    )
}