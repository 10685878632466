import React from "react";
import Dropdowns from "../../framework/components/DropDowns";
import {Container,Grid} from "semantic-ui-react";


const SampleDropDown =()=>{
    const options = [
        {
            key:'1',
            text:'Active',
            value:'1'
        },
        {
            key:'2',
            text:'Inactive',
            value:'0'
        }
    ]
    const countryOptions = [
        { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
        { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
        { key: 'al', value: 'al', flag: 'al', text: 'Albania' },
        { key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria' },
        { key: 'as', value: 'as', flag: 'as', text: 'American Samoa' },
        { key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra' },
        { key: 'ao', value: 'ao', flag: 'ao', text: 'Angola' },
        { key: 'ai', value: 'ai', flag: 'ai', text: 'Anguilla' },
        { key: 'ag', value: 'ag', flag: 'ag', text: 'Antigua' },
        { key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina' },
        { key: 'am', value: 'am', flag: 'am', text: 'Armenia' },
        { key: 'aw', value: 'aw', flag: 'aw', text: 'Aruba' },
        { key: 'au', value: 'au', flag: 'au', text: 'Australia' },
        { key: 'at', value: 'at', flag: 'at', text: 'Austria' },
        { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan' },
        { key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas' },
        { key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain' },
        { key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh' },
        { key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados' },
        { key: 'by', value: 'by', flag: 'by', text: 'Belarus' },
        { key: 'be', value: 'be', flag: 'be', text: 'Belgium' },
        { key: 'bz', value: 'bz', flag: 'bz', text: 'Belize' },
        { key: 'bj', value: 'bj', flag: 'bj', text: 'Benin' },
    ]
    const Selectionoptions = [
        { key: 1, text: 'Choice 1', value: 1 },
        { key: 2, text: 'Choice 2', value: 2 },
        { key: 3, text: 'Choice 3', value: 3 },
    ]
    return(
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>Dropdown Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <code>{"import Buttons from \"../../framework/components/DropDowns\";"}</code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Dropdowns
                                id={"ddStatus"}
                                placeholder={'Select Status'}
                                options={options}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard dropdown.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                                <code>
                                    {"<Dropdowns\n" +
    "                                    id={\"ddStatus\"}\n" +
    "                                    placeholder={\"Select Status\"}\n" +
    "                                    options={options} // this is an object array [{key:'1',text:'Active', value:'1'}]\n" +
    "                                />  "}
                                </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Dropdowns
                                id={"ddCountry"}
                                placeholder={'Select Country'}
                                options={countryOptions}
                                search={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard dropdown with search option.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<Dropdowns\n" +
                                    "                                    id={\"ddCountry\"}\n" +
                                    "                                    placeholder={\"Select Status\"}\n" +
                                    "                                    options={countryOptions} \n" +
                                    "                                    search={true} \n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Dropdowns
                                id={"ddSelect"}
                                placeholder={'Select Choice'}
                                options={Selectionoptions}
                                selection={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard dropdown with selection option.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<Dropdowns\n" +
                                    "                                    id={\"ddSelect\"}\n" +
                                    "                                    placeholder={\"Select Choice\"}\n" +
                                    "                                    options={Selectionoptions} \n" +
                                    "                                    selection={true} \n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Dropdowns
                                id={"ddSelect"}
                                placeholder={'Select Choice'}
                                options={Selectionoptions}
                                multiple={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard dropdown with multiple select option.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<Dropdowns\n" +
                                    "                                    id={\"ddSelect\"}\n" +
                                    "                                    placeholder={\"Select Choice\"}\n" +
                                    "                                    options={Selectionoptions} \n" +
                                    "                                    multiple={true} \n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Dropdowns
                                id={"ddSelect"}
                                placeholder={'Select Choice'}
                                options={Selectionoptions}
                                clearable={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard dropdown with clearable option.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<Dropdowns\n" +
                                    "                                    id={\"ddSelect\"}\n" +
                                    "                                    placeholder={\"Select Choice\"}\n" +
                                    "                                    options={Selectionoptions} \n" +
                                    "                                    clearable={true} \n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Dropdowns
                                id={"ddSelect"}
                                placeholder={'Select Choice'}
                                options={Selectionoptions}
                                search={true}
                                selection={true}
                                multiple={true}
                                fluid={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is standard dropdown with search, muliple select, selection and fluid option.</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<Dropdowns\n" +
                                    "                                    id={\"ddSelect\"}\n" +
                                    "                                    placeholder={\"Select Choice\"}\n" +
                                    "                                    options={Selectionoptions} \n" +
                                    "                                    search={true} \n" +
                                    "                                    selection={true} \n" +
                                    "                                    muliple={true} \n" +
                                    "                                    fluid={true} \n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleDropDown