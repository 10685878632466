import React, {useState} from "react";
import NameWithText from "../../framework/widgets/NameWithText";
import {Container, Grid} from "semantic-ui-react";
import Position from "../../framework/types/Position";


const SampleNameWithText =()=>{

    const [phone, setPhone] = useState('')
    return(
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>NameWithText Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <code>{"import NameWithText from \"../../framework/widgets/NameWithText\";"}</code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <NameWithText
                                id={'txtName'}
                                type={'text'}
                                placeholder={'Enter name ...'}
                                labelText={'Name'}
                                labelPosition={Position.Top}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic textbox with top label</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                                <code>
                                    {"<NameWithText\n" +
                                        "                                    id={\"txtName\"}\n" +
                                        "                                    type={\"text\"}\n" +
                                        "                                    placeholder={\"Enter name ...\"}\n" +
                                        "                                    labelText={\"Name\"}\n" +
                                        "                                    labelPosition={Position.Top}\n" +
                                        "                                />  "}
                                </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <NameWithText
                                id={'txtName'}
                                type={'text'}
                                placeholder={'Enter name ...'}
                                labelText={'Name'}
                                labelPosition={Position.Left}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic textbox with left label</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<NameWithText\n" +
                                    "                                    id={\"txtName\"}\n" +
                                    "                                    type={\"text\"}\n" +
                                    "                                    placeholder={\"Enter name ...\"}\n" +
                                    "                                    labelText={\"Name\"}\n" +
                                    "                                    labelPosition={Position.Left}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <NameWithText
                                id={'txtName'}
                                type={'text'}
                                placeholder={'Enter name ...'}
                                labelText={'Name'}
                                labelPosition={Position.Top}
                                multiLine={true}
                                wordCount={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is multiline textbox with top label </p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<NameWithText\n" +
                                    "                                    id={\"txtName\"}\n" +
                                    "                                    type={\"text\"}\n" +
                                    "                                    placeholder={\"Enter name ...\"}\n" +
                                    "                                    labelText={\"Name\"}\n" +
                                    "                                    labelPosition={Position.Left}\n" +
                                    "                                    multiLine={true}\n" +
                                    "                                    wordCount={true}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleNameWithText