
const AuditorStatus = (status:number) =>{
    let returnVal = ""
    switch (status) {
        case 1:
            returnVal = "New application"
            break;
        case 2:
            returnVal = "Application in review"
            break;
        case 3:
            returnVal = "Active"
            break;
        case 4:
            returnVal = "Level review"
            break;
        case 5:
            returnVal = "Elapsed"
            break;
        case 6:
            returnVal = "Inactive"
            break;
        case 7:
            returnVal = "Application assessment"
            break;
    }
    return returnVal;
}

export default AuditorStatus