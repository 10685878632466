import {SendMessageState} from "../../framework/types/SendMessageState";
import ApiCallAxio from "../../framework/common/apiCallAxio";
import APICallMethods from "../../framework/types/APICallMethods";


//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const SendEnquiryUs = async (props:SendMessageState) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/SendContactUsToAdmin`; //complete url
    const Data = {
        name: props.fullName,
        email: props.email,
        phone: props.phone,
        message: props.message
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:Data}); //call the api using ApiCall method
    const blSendStatus: boolean = result.enquiryToAuditorSent; //store the result in userTitles array

    return blSendStatus;//return the array
}

export {SendEnquiryUs}