import React, {useEffect} from "react";
import TextBoxes from "./index";
import DefaultTextBoxProps from "../../types/DefaultTextBoxProps";
import {emailValidator} from "../../validators/textFeildValidators";


const EmailInputBox = (props:DefaultTextBoxProps)=>{
    const [email, setEmail] = React.useState<string>('');
    useEffect(()=>{
        //console.log('EmailInputBox',props.onerror);
        setEmail(props.onerror? props.onerror : '');

    },[props.onerror]);

    useEffect(() => {
        const element = document.getElementById(props.id) as HTMLInputElement;
        if(element){
            if(email.length > 0){
                element.style.borderColor = '#CD123D'
            }else{
                //element.style.borderColor = '#e0e0e0'
                element.style.borderColor = '#969696'
            }
        }
    }, [email])

    const validator = ()=>{
        const enterValue = document.getElementById(props.id) as HTMLInputElement;
        if(!props.onerror){
            if(enterValue?.value.length > 0 &&!emailValidator(enterValue?.value)){
                setEmail('Please enter a valid email address');
            }else{
                setEmail('');
            }
        }

    }
    return(
        <>
            <TextBoxes
                id={props.id}
                type={'email'}
                placeholder={props.placeholder}
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                onChange={props.onChange}
                onClick={props.onClick}
                onLostFocus={()=>{
                    validator();
                    //eslint-disable-next-line
                    //debugger
                    props.onLostFocus;
                }}
                onKeydown={props.onKeydown}
                value={props.value}
                fluid={props.fluid}
                onPaste={props.onpaste}
            />
            <span className='email-validation'>{email}</span>
        </>
    )
}

export default EmailInputBox