import React, {useState} from "react";

import {Container, Grid} from "semantic-ui-react";
import DefaultTextBox from "../../framework/components/TextBoxes/DefaultTextBox";
import DatetimeBox from "../../framework/components/TextBoxes/DateTimeBox";
import TelephoneInputBox from "../../framework/components/TextBoxes/TelephoneInputBox";
import EmailInputBox from "../../framework/components/TextBoxes/EmailInputBox";
import PasswordBox from "../../framework/components/TextBoxes/PasswordBox";
import MultilineTextBox from "../../framework/components/TextBoxes/MultilineTextBox";
import NumberTextBox from "../../framework/components/TextBoxes/NumberTextBox";
import DecimalTextBox from "../../framework/components/TextBoxes/DecimalTextBox";


const SampleTextBoxes = () => {

    const [phone, setPhone] = useState('')
    return (
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>TextBox Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <div style={{textAlign: "left", backgroundColor: '#464646'}}>
                                <code>{"import DefaultTextBox from \"../../framework/components/TextBoxes/DefaultTextBox\";"}</code>
                                <br/>
                                <code>{"import DatetimeBox from \"../../framework/components/TextBoxes/DateTimeBox\";\n"}</code>
                                <br/>
                                <code>{"import TelephoneInputBox from \"../../framework/components/TextBoxes/TelephoneInputBox\";\n"}</code>
                                <br/>
                                <code>{"import EmailInputBox from \"../../framework/components/TextBoxes/EmailInputBox\";\n"}</code>
                                <br/>
                                <code>{"import PasswordBox from \"../../framework/components/TextBoxes/PasswordBox\";\n"}</code>
                                <br/>
                                <code>{"import MultilineTextBox from \"../../framework/components/TextBoxes/MultilineTextBox\";"}</code>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <DefaultTextBox
                                id={'txtName'}
                                placeholder={'Enter name ...'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic textbox with placeholder</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<DefaultTextBox\n" +
                                    "                                    id={\"txtName\"}\n" +
                                    "                                    placeholder={\"Enter name ...\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <NumberTextBox
                                id={'txtAge'}
                                placeholder={'Enter age ...'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is number textbox with placeholder</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<NumberTextBox\n" +
                                    "                                    id={\"txtAge\"}\n" +
                                    "                                    placeholder={\"Enter age ...\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <DecimalTextBox
                                id={'txtPrice'}
                                placeholder={'Enter price ...'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is decimal textbox with placeholder</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<DecimalTextBox\n" +
                                    "                                    id={\"txtPrice\"}\n" +
                                    "                                    placeholder={\"Enter price ...\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <DatetimeBox
                                id={'txtDOB'}
                                placeholder={'Enter DOB ...'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic textbox with date type</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<DatetimeBox\n" +
                                    "                                    id={\"txtName\"}\n" +
                                    "                                    placeholder={\"Enter name ...\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <TelephoneInputBox
                                id={'txtPhone'}
                                value={phone}
                                placeholder={'Enter Mobile Number ...'}
                                onChange={(e: any) => {
                                    if (/^\d+$/.test(e.target.value)) {
                                        setPhone(e.target.value)
                                    } else {
                                        if (phone.length == 1 && !(/^\d+$/.test(e.target.value))) {
                                            setPhone('')
                                        }
                                    }
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic textbox with phone type</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<TelephoneInputBox\n" +
                                    "                                    id={\"txtPhone\"}\n" +
                                    "                                    value={phone}\n" +
                                    "                                    placeholder={\"Enter Mobile Number ...\"}\n" +
                                    "                                    onChange={(e:any)=>{if(/^\\d+$/.test(e.target.value)){setPhone(e.target.value)}}" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <EmailInputBox
                                id={'txtEmail'}
                                placeholder={'Enter Email ...'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic textbox with email type</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<EmailInputBox\n" +
                                    "                                    id={\"txtEmail\"}\n" +
                                    "                                    placeholder={\"Enter Email ...\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <PasswordBox
                                id={'txtPassword'}
                                placeholder={'Enter Password ...'}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is basic textbox with password type</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<PasswordBox\n" +
                                    "                                    id={\"txtPassword\"}\n" +
                                    "                                    placeholder={\"Enter Password ...\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <MultilineTextBox
                                id={'txtRemarks'}
                                placeholder={'Enter Remarks ...'}
                                wordCount={true}

                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is multi line textbox type only text</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<MultilineTextBox\n" +
                                    "                                    id={\"txtRemarks\"}\n" +
                                    "                                    wordCount={true}\n" +
                                    "                                    placeholder={\"Enter Remarks ...\"}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleTextBoxes