import React, {useState} from "react";
import NameWithDropDown from "../../framework/widgets/NameWithDropDown";
import {Container, Grid} from "semantic-ui-react";
import Position from "../../framework/types/Position";


const SampleNameWithDropDown =()=>{

    const countryOptions = [
        { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
        { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
        { key: 'al', value: 'al', flag: 'al', text: 'Albania' },
        { key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria' },
        { key: 'as', value: 'as', flag: 'as', text: 'American Samoa' },
        { key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra' },
        { key: 'ao', value: 'ao', flag: 'ao', text: 'Angola' },
        { key: 'ai', value: 'ai', flag: 'ai', text: 'Anguilla' },
        { key: 'ag', value: 'ag', flag: 'ag', text: 'Antigua' },
        { key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina' },
        { key: 'am', value: 'am', flag: 'am', text: 'Armenia' },
        { key: 'aw', value: 'aw', flag: 'aw', text: 'Aruba' },
        { key: 'au', value: 'au', flag: 'au', text: 'Australia' },
        { key: 'at', value: 'at', flag: 'at', text: 'Austria' },
        { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan' },
        { key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas' },
        { key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain' },
        { key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh' },
        { key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados' },
        { key: 'by', value: 'by', flag: 'by', text: 'Belarus' },
        { key: 'be', value: 'be', flag: 'be', text: 'Belgium' },
        { key: 'bz', value: 'bz', flag: 'bz', text: 'Belize' },
        { key: 'bj', value: 'bj', flag: 'bj', text: 'Benin' },
    ]
    return(
        <React.Fragment>
            <Container>
                <Grid>
                    <Grid.Row>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} textAlign={"center"}>
                            <h3>NameWithDropDown Element with Sample codes</h3>
                            <p>before use this element need to import the element to the file.</p>
                            <code>{"import NameWithDropDown from \"../../framework/widgets/NameWithDropDown\";"}</code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <NameWithDropDown
                                id={'txtName'}
                                placeholder={'Enter name ...'}
                                labelText={'Name'}
                                labelPosition={Position.Top}
                                options={countryOptions}
                                search={true}
                                selection={true}
                                clearable={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is NameWithDropDown with top label</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                                <code>
                                    {"<NameWithDropDown\n" +
                                        "                                    id={\"txtName\"}\n" +
                                        "                                    placeholder={\"Enter name ...\"}\n" +
                                        "                                    labelText={\"Name\"}\n" +
                                        "                                    labelPosition={Position.Top}\n" +
                                        "                                    options={countryOptions}\n" +
                                        "                                    search={true}\n" +
                                        "                                    selection={true}\n" +
                                        "                                    clearable={true}\n" +
                                        "                                />  "}
                                </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <NameWithDropDown
                                id={'txtName'}
                                placeholder={'Enter name ...'}
                                labelText={'Name'}
                                labelPosition={Position.Left}
                                options={countryOptions}
                                search={true}
                                selection={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is NameWithDropDown with left label</p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<NameWithDropDown\n" +
                                    "                                    id={\"txtName\"}\n" +
                                    "                                    placeholder={\"Enter name ...\"}\n" +
                                    "                                    labelText={\"Name\"}\n" +
                                    "                                    labelPosition={Position.Left}\n" +
                                    "                                    options={countryOptions}\n" +
                                    "                                    search={true}\n" +
                                    "                                    selection={true}\n" +
                                    "                                />  "}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <NameWithDropDown
                                id={'txtName'}
                                placeholder={'Enter name ...'}
                                labelText={'Name'}
                                labelPosition={Position.Top}
                                options={countryOptions}
                                search={true}
                                selection={true}
                                multiple={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p>This is multiple select NameWithDropDown with top label </p>
                        </Grid.Column>
                        <Grid.Column width={8} className={'columnBack'}>
                            <code>
                                {"<NameWithDropDown\n" +
                                    "                                    id={\"txtName\"}\n" +
                                    "                                    placeholder={\"Enter name ...\"}\n" +
                                    "                                    labelText={\"Name\"}\n" +
                                    "                                    labelPosition={Position.Top}\n" +
                                    "                                    options={countryOptions}\n" +
                                    "                                    search={true}\n" +
                                    "                                    selection={true}\n" +
                                    "                                    multiple='' {true}\n" +
                                    "                                />"}
                            </code>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default SampleNameWithDropDown