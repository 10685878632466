import React, {useState} from 'react';
import IconTextButton from "../../components/Buttons/IconTextButton";
import Modal from "../../components/Modals";
import {Container, Grid} from 'semantic-ui-react'
import IconTextOutlineButton from "../../components/Buttons/IconTextOutlineButton";

interface Props {
    Component?: any,
    title?:string,
    subTitle?:string,
    modalTitle:string,
    modalBody:any,
    buttonText:string,
    modalAction?:boolean,
    ActionButtons?:any,
    open?:boolean,
    setOpen?:any,
    toggleModel?:any,
    disabled?:boolean

    // any props that come into the component
}
const TableWithBackground =({Component,title, subTitle,modalTitle,modalBody,buttonText,modalAction,ActionButtons,open, setOpen, toggleModel,disabled}:Props)=>{

    return (
        <Container fluid={true} className="dark-container">
            <Grid>
                <Grid.Column width={12} mobile={16}  tablet={10} computer={12}>
                    <h5 className="ui header">{title}</h5>
                    <p>{subTitle}</p>
                </Grid.Column>
                <Grid.Column width={4} mobile={16} tablet={6} computer={4} className='button-flex'>
                    <IconTextOutlineButton
                        onClick={toggleModel}
                        id={"AddNewRecord"}
                        text={buttonText}
                        icon={"plus"}
                        color={"blue"}
                        className={"background-transaparent"}
                        disabled={disabled}
                        style={{height:'36px'}}
                    />
                </Grid.Column>
                <Container fluid={true} >
                    {Component ? (<Component/>) :null}
                </Container>
            </Grid>
            <Modal open={open ? open : false} modalAction={modalAction} ActionButtons={ActionButtons} setOpen={setOpen}  title={modalTitle} ModalBody={modalBody} />
        </Container>
    )
}

export default TableWithBackground