import React, {useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import NotificationMenu from "../../../framework/widgets/MenuArray/NitificationMenu";
import {AdminNotificationCount} from "../../../framework/types/AdminNotificationCount";

interface AdminMessageProps{
    AuditorCounts:AdminNotificationCount
}

const AdminMessage = (props:AdminMessageProps) => {
    const [Message, setMessage] = useState([
        {
            text:"List of Auditors with messages",
            value: "0",
            onClick: ()=> {window.location.href = '/search/auditors?status=100'}
        },

    ])

    useEffect(()=>{
        if(props.AuditorCounts){
            const NewValuesForAuditor = [
                {
                    text:"List of Auditors with messages",
                    value: props.AuditorCounts.auditorUnreadComments.toString(),
                    onClick: ()=> window.location.href = '/search/auditors?status=100'
                },

            ]
            setMessage(NewValuesForAuditor)
        }

    },[props.AuditorCounts])


    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <p style={{fontWeight:'bolder', fontSize:'25px'}}>Message Centre</p>
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <NotificationMenu
                        Data={Message}
                    />
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default AdminMessage





