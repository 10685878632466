/*
 *################################################################################
 *Module Name                   	: CheckBox.tsx                				 #
 *Purpose of the Module         	: To Show CheckBox element         			 #
 *Description of the Module    	    : This element  act like CheckBox     		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/08/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
import React from "react";

//Import of the custom elements which relevent to the checkbox
import Checker from "./Checker";

//Import CheckBoxProps
import CheckBoxProps from "../../types/CheckBoxProps";



/**
 * type = Is the type of the checkbox eg:- checkbox, slider, toggle, radio
 *
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * value = Is the value of the Checkbox
 *
 * name = group of the checkboxes widely use for radio buttons.
 *
 * label = If you need to show text along with the checkbox. eg;- label={'Accept newsletters'}
 *
 * onChange = If you need to trigger event according to checkbox value change.
 *
 * onClick = If you need to trigger event according to mouse click on the element.
 *
 * onMouseDown = If you need to trigger event according to mouse button down.
 *
 * onMouseUp = If you need to trigger event according to mouse button up.
 */

const CheckBox =({id,type,value,name,label,onChange,onClick,onMouseDown,onMouseUp,disabled,style,className}:CheckBoxProps)=>{
    return(
        <Checker
            id={id}
            type={type}
            name={name}
            value={value}
            label={label}
            onChange={onChange}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            style={style}
            className={className}
            disabled={disabled}
        />
    );
}
export default CheckBox