import ApiCallAxio from "../../framework/common/apiCallAxio";
import {SendMessageState} from "../../framework/types/SendMessageState";
import APICallMethods from "../../framework/types/APICallMethods";
import {FAQs} from "../../models/apiResponce/Common/FAQs";
//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const fetchFAQS = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/Faqs`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objSendStatus: FAQs[] = result.faQs; //store the result in userTitles array

    return objSendStatus;//return the array
}

export {fetchFAQS}