import React from "react";
import {Container, Grid} from "semantic-ui-react";
import Buttons from "../../framework/components/Buttons";
import {useNavigate} from "react-router-dom";

const HomeSliderBottom = () => {
    const navigate = useNavigate();
    return (
        <Container fluid={true} >
            <div className='home-slider-bottom'>
                <Grid centered={true}>
                    <Grid.Row columns={2} centered>
                        <Grid.Column  mobile={16} widescreen={10} computer={10} textAlign={'center'}>
                            <h3 className='home-slider-bottom-text'>Joining this Register of road safety auditors is FREE</h3>
                        </Grid.Column>
                        <Grid.Column  mobile={16} computer={6} widescreen={6} textAlign={'center'}>
                            <Buttons id={'btnLogin'} onClick={()=>{
                                //navigate('/registration');
                                window.location.href = 'registration';
                            }} color='red' text={'Register as an Auditor'} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    )
}

export default HomeSliderBottom