/*
 *################################################################################
 *Module Name                   	: Buttons.tsx                				 #
 *Purpose of the Module         	: To Show button element         			 #
 *Description of the Module    	    : This element  act like button       		 #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/04/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */

import React from 'react'
import {Button, Icon} from 'semantic-ui-react'

//Import Button Props
import ButtonsProps from "../../types/ButtonProps";

/**
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * text = If you need to show  text of the button should be string    eg:- text={'Add Course'}
 *
 * color = Is the backgroud color of the element should be SemanticCOLORS eg:- color = 'red'.
 *
 * SemanticCOLORS list - red | orange | yellow | olive | green | teal | blue | violet | purple | pink | brown | grey | black
 *
 * onClick = Is the button click event should be a fucntion eg:- onClick = {()=>{ alert('This is a friendly alert')}}
 *
 * icon = If you need to show Icon along with text add value to this parameter. Should be SemanticICONS eg:- icon='facebook'
 *
 * outline = If you need outline button just pass outline parameter with 1 If you pass 2 then inverse eg:- outline={1}
 */

const Buttons=({id,text,color,onClick,icon,outline,circular,className,style,disabled}:ButtonsProps)=>{
    const sty:object = {whiteSpace:'nowrap',...style}

    if(outline == 1){
        if(text){
            return(
                <Button id={id} basic color={color} className={className} disabled={disabled} content={color}  style={sty} onClick={onClick} >{icon ? (<Icon name={`${icon}`} />) :null}{text}</Button>
            );
        }else {
            return(
                <Button id={id} basic color={color} className={className} disabled={disabled} circular={circular} icon  style={sty} onClick={onClick} >{icon ? (<Icon name={`${icon}`} />) :null}</Button>
            );
        }
    }else if(outline == 2){
        if(text){
            return(
                <Button id={id} inverted color={color} className={className} disabled={disabled}  style={sty} onClick={onClick} >{icon ? (<Icon name={`${icon}`} />) :null}{text}</Button>
            );
        }else {
            return(
                <Button id={id} inverted color={color} className={className} disabled={disabled} circular={circular} icon  style={sty} onClick={onClick} >{icon ? (<Icon name={`${icon}`} />) :null}</Button>
            );
        }
    }else{
        if(text){
            return(
                <Button id={id} color={color} className={className} disabled={disabled}  style={sty} onClick={onClick} >{icon ? (<Icon name={`${icon}`} />) :null}{text}</Button>
            );
        }else {
            return(
                <Button id={id} color={color} className={className} disabled={disabled} circular={circular} icon  style={sty} onClick={onClick} >{icon ? (<Icon name={`${icon}`} />) :null}</Button>
            );
        }
    }
}

export default Buttons