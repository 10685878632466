import React from "react";
import {RadialBar} from "@ant-design/charts";

interface porps{
    data:object[],
    colors:any
    innerRadius:number
}
const RadialBarChart = ({data, colors, innerRadius} :porps) => {

    const config = {
        data,
        xField: 'name',
        yField: 'audits',
        maxAngle: 270,
        //最大旋转角度,
        radius: 0.8,
        innerRadius: innerRadius,
        tooltip: {
            formatter: (datum:any) => {
                return {
                    name: 'audits',
                    value: datum.audits,
                };
            },
        },
        colorField: 'name',
        color: colors,
    };
    return <RadialBar {...config} />;
};

export default RadialBarChart