import * as jose from 'jose'
import FileProps from "../types/FileProps";
/**
 * This module make single digit number to dual digit. eg:- 5 -> 05
 */

const padTo2Digits = (num: number) => {
    if(num > 0 ){
        return num.toString().padStart(2, '0');
    }else{
        return num.toString().padStart(1, '0');
    }
   
}

/**
 * This module convert milliseconds to Time. eg:- 168922789 -> 1,3,42,07
 */

const convertMsToTime = (milliseconds: number)=>{
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days =  Math.floor(hours / 24);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    /*return `${padTo2Digits(days)} ${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds,
    )}`;*/
    return {days,hours,minutes,seconds}
}

/**
 * This module use to validate file upload.
 */
const FileUploadValidator =(e:any):FileProps=>{
    return {
        picturePreview: URL.createObjectURL(e.target.files[0]),
        pictureAsFile: e.target.files[0]
    }
}

/**
 * This module use to calculate storage
 */

const calculateStorage = (storage: number) => {
    if (storage < 1024) {
        return storage + " Bytes";
    } else if (storage < 1048576) {
        return (storage / 1024).toFixed(2) + " KB";
    } else if (storage < 1073741824) {
        return (storage / 1048576).toFixed(2) + " MB";
    } else {
        return (storage / 1073741824).toFixed(2) + " GB";
    }
}

/**
 * This module validate JWT
 **/
const jwtValidator =async(jwtToken:string):Promise<boolean>=>{
    try {
        const jwtValidToken: string[] = jwtToken.split('.')
        if (jwtValidToken.length !== 3) return false
        const algorithm = 'ES256';
        const spki ="-----BEGIN PUBLIC KEY----- MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEEVs/o5+uQbTjL3chynL4wXgUg2R9 q9UU8I5mEovUf86QZ7kOBIjJwqnzD1omageEHWwHdBO6B+dFabmdT9POxg== -----END PUBLIC KEY-----";
        const ecPublicKey = await jose.importSPKI(spki, algorithm)
        const { payload, protectedHeader } = await jose.jwtVerify(jwtToken,ecPublicKey)

        console.log(protectedHeader)
        console.log(payload)
        return protectedHeader.typ == 'JWT';
        //JSON.parse(Buffer.from(jwtValidToken[0], 'base64').toString())
        //JSON.parse(Buffer.from(jwtValidToken[1], 'base64').toString())

    } catch (e) {
        //console.log(e)
        return false
    }
}




export {padTo2Digits,convertMsToTime,jwtValidator,FileUploadValidator,calculateStorage}