import React from "react";
import Buttons from "./index";
import ButtonsProps from "../../types/ButtonProps";
import ButtonsDefaultProps from "../../types/ButtonDefaultProps";

interface Props extends ButtonsDefaultProps {
    text?:string;
}

/**
 * id = Is the identification of the element should be string   eg:- id={'btnAddCourse'}
 *
 * text = If you need to show  text of the button should be string    eg:- text={'Add Course'}
 *
 * color = Is the backgroud color of the element should be SemanticCOLORS eg:- color = 'red'.
 *
 * SemanticCOLORS list - red | orange | yellow | olive | green | teal | blue | violet | purple | pink | brown | grey | black
 *
 * onClick = Is the button click event should be a fucntion eg:- onClick = {()=>{ alert('This is a friendly alert')}}
 *
 * style = Can override the style of the button eg:- style={{width:'100px',height:'100px'}}
 *
 * className = can override the class of the button eg:- className={'btn btn-primary'}
 *
 * disabled = If you need to disable the button just pass disabled parameter with true eg:- disabled={true}
 */

const DefaultButton = ({id,text,color,onClick,style,className,disabled}:Props) => {
    return (
        <Buttons id={id} text={text} color={color} style={style} disabled={disabled} className={className} onClick={onClick} />
    )
}

export default DefaultButton