import React, {useEffect} from "react";

import "../../../assets/scss/Carousel.scss";
import Buttons from "../Buttons";

const Carousel = ({children}:any) => {
    const [activeIndex, setActiveIndex] = React.useState(0);

    const updateActiveIndex = (index:number) => {
        if(index < 0){
            index = React.Children.count(children) - 1;
        }else if(index >= React.Children.count(children)){
            index = 0;
        }
        setActiveIndex(index);
    }

    useEffect(()=>{
        const interval = setInterval(()=>{
            updateActiveIndex(activeIndex + 1);
        },6000);
        return () => {if(interval)clearInterval(interval)};
    })
    return (
        <div className='carousel'>
            <div className='indicator-group'>
                {React.Children.map(children, (child, index) => {
                    return (
                        <button id={'btnPreview'} className={`indicator ${index == activeIndex ? 'indicator-active' : ''}`} onClick={()=>{updateActiveIndex(index);}} color={'grey'}>{''}</button>
                    ); // pass the width prop
                })}
            </div>
            <div className='carousel-inner' style={{transform:`translateX(-${activeIndex *100}%)`}}>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child,{width:"100%"}); // pass the width prop
                })}
            </div>
            <span className='button-group'>
                <button id={'btnPrevious'} className='navigate-buttons' onClick={()=>{updateActiveIndex(activeIndex - 1);}} color={'grey'}>{'<'}</button>
                <button id={'btnNext'} className='navigate-buttons' onClick={()=>{updateActiveIndex(activeIndex + 1);}} color={'grey'}>{'>'}</button>
            </span>
        </div>
    );
}
export default Carousel