import React from "react";
import {InputFile} from "semantic-ui-react-input-file/src/InputFile";
import {SemanticCOLORS} from "semantic-ui-react";

interface FileUploadProps {
    color: SemanticCOLORS,
    onChange:any
    disabled?:boolean
}

const FileUpload = (props:FileUploadProps) => {
    return (
        <InputFile
            button={{color:props.color}}
            input={{
                id: 'input-control-id',
                onChange: props.onChange
            }}

        />
    );
}

export default FileUpload;