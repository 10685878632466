import {
  fetchAuditor,
  fetchAuditorAuditAll,
} from "../pages/auditor/profile/ProfileController";
import { AuditorProfile } from "../pages/auditor/profile/DataModal";
import { AuditorAuditRess } from "../models/apiResponce/Auditor/AuditorAuditRess";
import { getSessionCookie } from "../framework/common/SessionHandler";
interface IEligibilityCheck {
  CourseSuccess: boolean;
  AuditSuccess: boolean;
  experianceSuccess: boolean;
}

// Get the current date
const today = new Date();
const session = getSessionCookie();

// Subtract three years from the current date
const threeYearsAgo = new Date(
  today.getFullYear() - 3,
  today.getMonth(),
  today.getDate()
);

const eligibilityCheck = async (applyLevel: any, auditorId: any) => {
  let success: IEligibilityCheck = {
    CourseSuccess: false,
    AuditSuccess: false,
    experianceSuccess: false,
  };
  const auditorPromise = await fetchAuditor(auditorId, 0);
  const auditorAudits = await fetchAuditorAuditAll(auditorId, 0);
  switch (parseInt(applyLevel)) {
    case 1:
      success = Level1(auditorPromise, auditorAudits);
      break;
    case 2:
      success = Level2(auditorPromise, auditorAudits);
      break;
    case 3:
      success = Level3(auditorPromise, auditorAudits);
      console.log(success);
      break;
  }

  return success;
};

const Level1 = (
  auditor: AuditorProfile,
  audits?: AuditorAuditRess[]
): IEligibilityCheck => {
  let CourseSuccess = false;
  let AuditSuccess = false;
  const courses = auditor.roadSafetyCourses;
  courses?.map((r) => {
    if (
      (r.courseTentativeClassification == "Auditor" ||
        r.courseTentativeClassification == "Update") &&
      r.isVerified == true
    ) {
      CourseSuccess = true;
    }
  });

  if (audits != undefined && audits.length >= 1) {
    if (session.status == 1) {
      AuditSuccess = true;
    } else {
      //&& audits[0].isConfirmedByAdmin
      for (let i = 0; i < audits.length; i++) {
        if (audits[i].isConfirmedByAdmin) {
          AuditSuccess = true;
        }
      }
    }
  }

  return { CourseSuccess, AuditSuccess, experianceSuccess: true };
};

const Level2 = (
  auditor: AuditorProfile,
  audits: AuditorAuditRess[]
): IEligibilityCheck => {
  let CourseSuccess = false;
  let AuditSuccess = false;
  let experianceSuccess = false;

  const courses = auditor.roadSafetyCourses;
  courses?.map((r) => {
    if (
      (r.courseTentativeClassification == "Auditor" ||
        r.courseTentativeClassification == "Update") &&
      r.isVerified == true
    ) {
      CourseSuccess = true;
    }
  });

  let auditCount = 0;
  audits.map((r) => {
    if(r.isConfirmedByAdmin && r.auditStatusId == 3)
    {
      if (new Date(r.auditFinalSignoffDate) >= threeYearsAgo) {
        if (r.isConfirmedByAdmin) {
          auditCount++;
        }
      }
    }    
  });

  if (auditCount >= 3) {
    AuditSuccess = true;
  }

  if (
    auditor?.qualification?.eligibilityCriteria != undefined &&
    auditor?.qualification?.totalExperience != undefined &&
    parseInt(auditor?.qualification?.eligibilityCriteria) >= 2 &&
    parseInt(auditor?.qualification?.totalExperience) >= 5
  ) {
    experianceSuccess = true;
  }

  return { CourseSuccess, AuditSuccess, experianceSuccess };
};

const Level3 = (
  auditor: AuditorProfile,
  audits: AuditorAuditRess[]
): IEligibilityCheck => {
  let CourseSuccess = false;
  let AuditSuccess = false;
  let experianceSuccess = false;

  const courses = auditor.roadSafetyCourses;
  courses?.map((r) => {
    if (
      (r.courseTentativeClassification == "Leader" ||
        r.courseTentativeClassification == "Lead") &&
      r.isVerified == true
    ) {
      CourseSuccess = true;
    }
  });

  let auditCountPre = 0;
  let auditCountPost = 0;
  audits.map((r) => {
    if (r.isConfirmedByAdmin && r.auditStatusId == 3) {
      if (new Date(r.auditFinalSignoffDate) >= threeYearsAgo) {
        if (r.auditPhaseId == 1) {
          auditCountPre++;
        } else if (r.auditPhaseId == 2 || r.auditPhaseId == 3) {
          auditCountPost++;
        }
      }
    }
  });

  if (auditCountPre >= 3 && auditCountPost >= 3) {
    AuditSuccess = true;
  }

  if (
    auditor?.qualification?.eligibilityCriteria != undefined &&
    auditor?.qualification?.totalExperience != undefined &&
    parseInt(auditor?.qualification?.eligibilityCriteria) == 3 &&
    parseInt(auditor?.qualification?.totalExperience) >= 7
  ) {
    experianceSuccess = true;
  }

  return { CourseSuccess, AuditSuccess, experianceSuccess };
};

export default eligibilityCheck;

export type { IEligibilityCheck };
