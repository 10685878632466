import React from "react";
import {ITab} from "../../types/TabInterface";
import {Tab} from "semantic-ui-react";

const TabWithIcon = (props: ITab) =>
    <Tab
        menu={props.menu}
        panes={props.panes}
    />


export default TabWithIcon