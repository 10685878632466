import React from "react";

interface ContactUsTextProps {
  children?: React.ReactNode;
}

const ContactUsText: React.FC<ContactUsTextProps> = ({ children }) => (
  <div className="about-us-text" style={{zIndex:'100', position:'relative'}}>
    Complete the form below to let us know what you think:
    <br />
    <br />
    {children}
  </div>
);

export default ContactUsText;