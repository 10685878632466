import React, {useEffect, useState} from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import {Container, Grid, Image, Label, Menu} from "semantic-ui-react";
import InternalBG from "../../assets/images/backgrounds/internal-pages.jpg";
import NameWithDropDown from "../../framework/widgets/NameWithDropDown";
import Position from "../../framework/types/Position";
import NameWithDefault from "../../framework/widgets/NameWithText/NameWithDefault";
import NameWithEmail from "../../framework/widgets/NameWithText/NameWithEmail";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import DefaultButton from "../../framework/components/Buttons/DefaultButton";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import {
    adminMenu,
    assessorChairMenu,
    assessorMenu,
    auditorActiveMenu,
    auditorMenu, publicMenu,
    superAdminMenu
} from "../../common/MenuArrays";
import {getSessionCookie} from "../../framework/common/SessionHandler";

const SiteMap =()=>{
    const session = getSessionCookie();
    const [menu, setMenu] = useState<any>([])
    const menuListSelection = () => {
        //console.log('menu selection', session)
        if (session != null) {
            switch (session.userRoleId.toString()) {
                case '3':
                    //console.log('menu selection', session.userRoleId)
                    return session.status == 3 || session.status == 4 ? auditorActiveMenu : auditorMenu;
                    break;
                case '1':
                    return adminMenu;
                    break;
                case '2':
                    return assessorMenu;
                    break;
                case '5':
                    return assessorChairMenu;
                    break;
                case '4':
                    return superAdminMenu;
                    break;
                default:
                    return publicMenu;
                    break;
            }
        } else {
            return publicMenu;
        }
    }

    useEffect(() => {
        setMenu(menuListSelection())
    },[])
    return (
        <>
            <Header>
                <InnerTitleBar
                    title={'Site Map'}
                    usreName={''}
                    userRole={""}
                    lastLogin={''}
                    status={''}
                />
                <Container  fluid={true} style={{ marginTop: '14px', marginBottom: '20px'}}>
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={12}>
                                <h1></h1>
                                <ul color={'blue'} >
                                    {menu.map((item: any, index: number) => (
                                        <li key={index} style={{fontSize: '24px'}}><a style={{fontSize: '22px'}} key={index} id={index.toString()}
                                                   href={item.href}
                                                   className='item-width'>
                                            {item.content}
                                        </a></li>
                                    ))}
                                </ul>
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Container>
            </Header>
            <Footer/>

        </>
    )
}

export default SiteMap