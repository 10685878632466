import React from "react";
import OutLineButton from "../../components/Buttons/OutlIneButton";
import DefaultButton from "../../components/Buttons/DefaultButton";

interface Props {
    cancelOnclick: any;
    saveOnclick: any;
}
const ControllButton=(props:Props)=>{
    return(
        <div className='button-flex'>
            <OutLineButton id={'btnCancel'} style={{width:'100px'}} onClick={props.cancelOnclick} color={'red'} className={"background-transaparent-red"} text={'Cancel'}/>
            <OutLineButton id={'btnSave'} style={{width:'100px'}} onClick={props.saveOnclick} color={'blue'} className={"background-transaparent"} text={'Save'}/>
        </div>
    )
}
export default ControllButton