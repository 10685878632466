import React, {useEffect, useState} from 'react';
import Header from "../../../framework/layouts/Header";
import Footer from "../../../framework/layouts/Footer";
import PersonalDetails from "./PersonalDetails";
import {fetchTitle} from "./RegistrationContoller";
import {Oval} from "react-loader-spinner";
import {removeSessionCookie, setSessionCookie} from "../../../framework/common/SessionHandler";
import {fetchAuthRegi} from "../profile/ProfileController";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const Registration = () => {
    removeSessionCookie();
    const [titles, setTitles] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const cUrl = window.location.href
        if (cUrl.includes('?code')) {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const vcd = urlParams.get('vcd');
            const users = fetchAuthRegi(parseInt(code ? code : '0'), vcd ? vcd : '');
            Promise.resolve(users).then((value) => {
                //const person = value.personalDetails;
                //eslint-disable-next-line
                //debugger;
                sessionStorage.setItem('tempUser', `${code}#${vcd}`);
                window.location.href = 'profile';
            });
        }
        setTitles(fetchTitle());
    }, []);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ""}>
            <React.Fragment>
                <Header>
                    {!loading ? (<PersonalDetails
                        title={titles}
                    />) : (
                        <div style={{height: '40vh', marginTop: '30vh', paddingLeft: '50vw'}}>
                            <Oval
                                height={80}
                                width={80}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}

                            /></div>
                    )}
                </Header>
                <Footer/>
            </React.Fragment>
        </GoogleReCaptchaProvider>
    );
}

export default Registration;